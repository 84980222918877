import React from 'react';
import moment from 'moment';
import ReferralModal from '../modals/ReferralModal';


const ReferralData = (props) => {
  return (
    <React.Fragment>
      {
        props.showReferralModal &&
        <ReferralModal 
          onClose={props.toggleReferralModal} 
          userToken={props.userToken}
          consultationId={props.consultationId}
          referralId={props.attachments[0].content.id}
        />
      }
      <div style={{display: "flex"}}>
        <div 
          data-tooltip="Click here to view Pharmacy details" 
          style={{margin: "0 1rem 1rem 0", padding: "1rem"}}
        >
          <i 
            className="fas fa-clinic-medical" 
            style={{ cursor: "pointer", fontSize: "20px"}} 
            onClick={() => props.toggleReferralModal(props.referralModalName)}
          >
          </i>
        </div>

        <div style={{fontSize: "1.3rem"}}>
          <p> {props.message}</p>
          <p>{moment(props.created_at).format('HH:mm')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReferralData;