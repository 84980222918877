import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import PageHeader from './boxes/PageHeader';  
import LoadingScreen from './LoadingScreen';
import numberFormatter from 'number-formatter';

export default function ReferOverviewPage({overview, getOverview}) {

  useEffect(() => {
    getOverview();
  }, [getOverview]);

  return (
    <MainContent>
      <PageHeader title="Referral overview" />
      <PageBody>
        {
          overview ?
            <div className="dashboard-card">
              <div className="refer-dashboard-section refer-dashboard-intro">
                <h2 className="dashboard-card__title-text">Your earnings</h2>
                <p className="dashboard-card__body-text">
                  Here is what you have earned so far. You can withdraw cash to your bank account 
                  or keep accumulating your earnings.
                </p>
              </div>
              <div className="refer-dashboard-section refer-dashboard-overview__holder">
                <div className="dashboard-card refer-dashboard-overview-card">
                  <h2 className="dashboard-card__title-text--light">Sign ups</h2>
                  <h1 className="dashboard-card__focus-text">{overview.total_signups}</h1>
                </div>
                <div className="dashboard-card refer-dashboard-overview-card">
                  <h2 className="dashboard-card__title-text--light">Total earnings</h2>
                  <h1 className="dashboard-card__focus-text">&#8358;{numberFormatter("#,###.00", overview.total_earnings)}</h1>
                </div>
                <div className="dashboard-card refer-dashboard-overview-card">
                  <h2 className="dashboard-card__title-text--light">Current balance</h2>
                  <h1 className="dashboard-card__focus-text">&#8358;{numberFormatter("#,###.00", overview.total_balance)}</h1>
                </div>
                <div className="dashboard-card refer-dashboard-overview-card">
                  <h2 className="dashboard-card__title-text--light">Total withdrawn</h2>
                  <h1 className="dashboard-card__focus-text">&#8358;{numberFormatter("#,###.00", overview.total_withdrawn)}</h1>
                </div>
              </div>
              <div className="refer-dashboard-section">
                <Link to="/refer" className="dashboard-refer__primary-btn">Earn more cash</Link>
                <Link to="/refer/withdraw-cash" className="dashboard-refer__secondary-btn">Withdraw cash earned</Link>
              </div>
            </div>
          :
            <LoadingScreen />
        }
      </PageBody>
    </MainContent>
  );
}