import { USER_ACTION_TYPES } from './actionTypes';
import userService from '../services/userService';
import {
    getAccountsDashboardUrl,
    DAARA_TOKEN_PREFIX,
    DAARA_TOKEN_NAME
} from '../services/utilityService';
import { sessionService } from 'redux-react-session';
import { features } from '../services/daara-config.json';

export const login = () => {
    return {
        type: USER_ACTION_TYPES.LOGIN
    };
};

export const loginSuccessful = (user, ref) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_SUCCESS,
        data: { user: user, ref: ref }
    };
};

export const loginFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_FAIL,
        data: {
            code: error.code,
            message: error.message
        }
    };
};

export const daaraLogout = () => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT
    };
};

export const daaraLogoutSuccessful = (data) => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT_SUCCESS,
        data: { data }
    };
};

export const daaraLogoutFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.DAARA_LOGOUT_FAIL,
        data: {
            code: error.code,
            message: error.message
        }
    };
};

export const forgotPassword = () => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD
    };
};

export const recoverPasswordSuccessful = () => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS
    };
};

export const recoverPasswordFailed = (message) => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_FAIL,
        data: message
    };
};

export const loadUser = (user) => {
    return {
        type: USER_ACTION_TYPES.GET_USER_SUCCESS,
        data: user
    };
};

export const getProfile = () => {
    return {
        type: USER_ACTION_TYPES.GET_PROFILE
    };
};

export const getProfileSuccessful = (profile) => {
    return {
        type: USER_ACTION_TYPES.GET_PROFILE_SUCCESS,
        data: profile
    };
};

export const getProfileFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.GET_PROFILE_FAIL,
        date: error
    };
};

export const getBenefits = () => {
    return {
        type: USER_ACTION_TYPES.GET_BENEFITS
    };
};

export const getBenefitsSuccessful = (benefits) => {
    return {
        type: USER_ACTION_TYPES.GET_BENEFITS_SUCCESS,
        data: benefits
    };
};

export const getBenefitsFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.GET_BENEFITS_FAIL,
        data: error
    };
};

export const changePassword = () => {
    return {
        type: USER_ACTION_TYPES.CHANGE_PASSWORD
    };
};

export const changePasswordSuccessful = () => {
    return {
        type: USER_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS
    };
};

export const changePasswordFailed = (message) => {
    return {
        type: USER_ACTION_TYPES.CHANGE_PASSWORD_FAIL,
        data: message
    };
};

//////////////////// THUNKS  //////////////////////////////

export const authUser = (to) => {
    return (dispatch) => {
        dispatch(login());
        userService
            .auth()
            .then((user) => {
                saveSession(dispatch, user, to);
            })
            .catch(() => {
                window.location.href = `${getAccountsDashboardUrl()}/login${to ? '?r=' + to : ''}`;
            });
    };
};

export const loginUser = (values, to) => {
    //try getting fcmToken if permission already granted
    return (dispatch) => {
        dispatch(login());
        userService.login(values).then(
            (user) => {
                saveSession(dispatch, user, to);
            },
            (err) => {
                const { status, data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(loginFailed({ code: status, message: data.message || statusText }));
            }
        );
    };
};

export const getProfileIfNeeded = (accessToken, enrolleeId) => {
    return async (dispatch, getState) => {
        const state = getState();
        if (!state.user.meta.profile) {
            try {
                dispatch(getProfile());
                const json = await userService.getProfile(accessToken, enrolleeId);
                dispatch(getProfileSuccessful(json));
            } catch (err) {
                const { status, data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(getProfileFailed({ code: status, message: data.message || statusText }));
            }
        } else {
            dispatch(getProfileSuccessful(state.user.meta.profile));
        }
    };
};

export const fetchBenefits = (accessToken) => {
    return async (dispatch) => {
        try {
            dispatch(getBenefits());
            const benefits = await userService.getBenefits(accessToken);
            dispatch(getBenefitsSuccessful(benefits));
        } catch (err) {
            const { status, data, statusText } = err.response || {
                status: -1,
                statusText: 'Unknown Error',
                data: {}
            };
            dispatch(getBenefitsFailed({ code: status, message: data.message || statusText }));
        }
    };
};

export const resetPassword = (values) => {
    //try getting fcmToken if permission already granted
    return (dispatch) => {
        dispatch(forgotPassword());
        userService.resetPassword(values).then(
            () => {
                dispatch(recoverPasswordSuccessful());
            },
            (err) => {
                const { data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(recoverPasswordFailed({ message: data.message || statusText }));
            }
        );
    };
};

export const setNewPassword = (values) => {
    return (dispatch) => {
        dispatch(changePassword());
        userService.changePassword(values).then(
            () => {
                dispatch(changePasswordSuccessful());
            },
            (err) => {
                const { data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(changePasswordFailed({ message: features.accountService.changePassword ? data.detail : data.message || statusText }));
            }
        );
    };
};

const saveSession = (dispatch, user, referrer) => {
    sessionService.saveSession(user.credentials.accessToken).then(() => {
        sessionService.saveUser(user).then(() => {
            dispatch(loginSuccessful(user, referrer));
        });
    });
};

export const logoutUserFromDaaraService = () => {
    return async (dispatch) => {
        dispatch(daaraLogout());
        await sessionService
            .loadUser()
            .then((user) => {
                fetch(`${process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL}/logOut`, {
                    method: 'GET',
                    headers: new Headers({
                        Authorization: `Bearer ${user.credentials.daaraToken}`,
                        'Content-Type': 'application/json'
                    })
                })
                    .then((response) => dispatch(daaraLogoutSuccessful(response)))
                    .catch((error) => dispatch(daaraLogoutFailed(error)));
            })
            .catch((error) => dispatch(daaraLogoutFailed(error)));
    };
};

export const logoutUser = (from) => {
    return () => {
        sessionService
            .deleteUser()
            .then(() => {
                sessionService.deleteSession().then(() => {
                    localStorage.removeItem(`${DAARA_TOKEN_NAME}`);
                    let destUrl = `${getAccountsDashboardUrl()}/logout`; //auth is dummy route, it actually goes to /dashboard/
                    window.location.replace(destUrl + (from ? `?r=${from}` : ``));
                });
            })
            .catch((err) => {
                throw err;
            });
    };
};

export const clearUserProfile = () => {
    return {
        type: USER_ACTION_TYPES.CLEAR_PROFILE
    };
};

export const setHasCyclePlanner = (isSetUp = true, cb) => {
    return sessionService.loadUser().then((user) => {
        user.preferences.hasSetUpPlanner = isSetUp;
        updateLocalUser(user).then(() => {
            cb();
        });
    });
};

export const updateLocalUser = (user) => {
    return sessionService.saveUser(user);
};
