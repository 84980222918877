import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import Modal from '../boxes/Modal';
import LoadingScreen from '../LoadingScreen';
import { ApiService, ENDPOINTS } from "../../services/ApiService";

class DoctorOfflineSelectModal extends Component {
  constructor(props){
    super(props);

    this.service = new ApiService(props.accessToken);
    
    this.state = {
      error: null
    };
  }

  createNewConsultation = (values) => {
    const { consultation } = this.props;
    
    const data = { 
      medium_id: consultation.medium_id,
      patient_id: consultation.patient.id, 
      doctor_id: values.select_wait_option === "yes" ? consultation.doctor.id : null,
      previous_consultation_id: consultation.id,
      reason: values.content
    };

    this.service.post(ENDPOINTS.consultations.consultation, data)
      .then((response) => {
        window.location.href = `/consultations/${response.data.consultation_id}/messages`;
      })
      .catch((error) => {
        this.setState({
          error
        })
        console.log(error)
      });
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;
    const { consultation } = this.props;

    return (
      <Modal onClose={this.props.onClose} title="Referred Pharmacy Details">
        {
          !consultation ?
            <LoadingScreen/>
            :
            <div className="dashboard-card">
              <form className="dashboard-form" 
                onSubmit={handleSubmit(this.createNewConsultation)}
              >
                <div className="dashboard-form-item-wrapper">
                  <div className="dashboard-input-wrap profile-input-wrap">
                    <p>Doctor {consultation.doctor.first_name} {consultation.doctor.last_name} is currently offline</p>
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Do you wish to wait till Doctor {consultation.doctor.first_name} {consultation.doctor.last_name} is Online
                      </label>
                      <Field component="select" name="select_wait_option" className="dashboard-form__input" placeholder="Enter Complaint here" type="text">
                        <option>Select an option</option>
                        <option value="yes" >Yes, I can wait till he is online</option>
                        <option value="no" >No, assign another Doctor to me</option>
                      </Field>
                    </span>
                  </div>
                </div>
                <button disabled={pristine || submitting}className="dashboard__primary-btn dashboard-form__btn">Submit</button>
              </form>
            </div>
        }
      </Modal>
    );
  }
}

DoctorOfflineSelectModal = reduxForm({
  form:"doctorOfflineSelectModal"
})(DoctorOfflineSelectModal);

export default withRouter(DoctorOfflineSelectModal);