import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import NotificationBanner from '../components/boxes/NotificationBanner';
import {ApiService, ENDPOINTS} from '../services/ApiService';

class ConsultationRatingContainer extends Component {
  constructor(props) {
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      consultationId: props.match.params.id,
      error: null
    }
  }

  submitRating = (rating) => {
    const data = {
      rating
    }

    this.service.post(ENDPOINTS.consultations.rating(this.state.consultationId), data)
      .then(() => {
        this.setState({
          error: null
        })
        this.props.history.push(`/rate-consultation-success`);
      })
      .catch((error) => {
        this.setState({
          error
        })
      });
  }

  closeErrorBanner = () => {
    this.setState({
      error: null
    })
  }

  render() {
    const {error} = this.state;

    return (
      <React.Fragment>
        <MainContent>
          <PageHeader title="Rate Consultation"/>
          <PageBody>
            {
              error && 
              <NotificationBanner
                type="error"
                title="Error!"
                message={error.message}
                onDismiss={this.closeErrorBanner}
              />
            }
            <div className="dashboard-card">
              <h1>Hello! How did you find the consultation you had today?</h1>
              <br/>
              <h1>Do let us know.</h1>
            </div>
            <div className="dashboard-card" style={{ display: "flex"}} > 

              <div onClick={() => this.submitRating(1)}>
                <img 
                  style={{ padding: "3rem", height: "16rem"}}
                  alt="bad-review"
                  src="https://s3.us-west-2.amazonaws.com/cdn.reliancehmo.com/images/bad-review.png"
                />
                <p className="dashboard-card__title-text" style={{ textAlign: "center"}}>Bad</p>
              </div>

              <div onClick={() => this.submitRating(2)}>
                <img  
                  style={{ padding: "3rem", height: "16rem"}}
                  alt="good-review"
                  src="https://s3.us-west-2.amazonaws.com/cdn.reliancehmo.com/images/good-review.png"
                />
                <p className="dashboard-card__title-text" style={{ textAlign: "center"}}>Good</p>
              </div>

              <div onClick={() => this.submitRating(3)}>
                <img 
                  style={{ padding: "3rem", height: "16rem"}}
                  alt="excellent-review"
                  src="https://s3.us-west-2.amazonaws.com/cdn.reliancehmo.com/images/excellent-review.png"
                />
                <p className="dashboard-card__title-text" style={{ textAlign: "center"}}>Excellent</p>               
              </div>

            </div>
          </PageBody>
        </MainContent>
      </React.Fragment>
    );
  }
}

export default withRouter(ConsultationRatingContainer);