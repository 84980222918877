import React from 'react';

export default function ButtonBar({main, other}) {
	return (
		<div className="dashboard-btn-section">
			{
				other &&  
					<button className="dashboard__primary-btn" onClick={other.func}>
						{other.name}
					</button>
			}
			<button className="dashboard__secondary-btn" onClick={main.func}>
				{main.name}
			</button>
		</div>
	)
}
