import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../boxes/Modal';

const AttachmentModal = ({attachments, canDelete, onDelete, onClose}) => {
  console.log("attachments", attachments)

  return (
    <Modal title="View Attachments" onClose={onClose}>
      <div className="attachment-grid-wrapper">
        <ul className="attachment-grid">
          {attachments.map((attachment,i)=>(
            <li key={i}>
              <div className="attachment">
                <img src={attachment instanceof Blob ? URL.createObjectURL(attachment) : attachment.content.url} alt={`Attachment_${i}`} className="thumbnail"/>
                {canDelete &&
                <span className="delete" onClick={()=>onDelete(attachment)}>
                    <i className="fa fa-trash"/>
                  </span>
                }
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  )

};

AttachmentModal.propTypes = {
  attachments: PropTypes.array,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onClose: PropTypes.func
};

export default AttachmentModal;


