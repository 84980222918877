import React from 'react';

class Tab extends React.Component {

  handleClick = () => {
      this.props.onClick(this.props.index);
  };

  render(){
    return (
      <button 
        className={`dashboard-tab__button ${this.props.isActive ? "dashboard-tab__button-active" : ""}`}
        onClick={this.handleClick}
      >
        {this.props.title}
      </button>
    )
  }
}

export default Tab;