/**
 * Created by the.last.mayaki on 25/10/2017.
 */
import moment from 'moment';
import * as _ from 'lodash';
import {PLANNER_ACTION_TYPES} from './actionTypes';
import kangpeService from '../services/kangpeService';
import {setHasCyclePlanner} from './user';
//////////////////////// ACTIONS ///////////////////////////////////

export const setPlanner = () => {
  return {
    type: PLANNER_ACTION_TYPES.SET_UP_PLANNER,
  }
};

export const plannerSetUpSuccessful = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.SET_UP_PLANNER_SUCCESS,
    data: data
  }
};

export const plannerSetUpFailed = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.SET_UP_PLANNER_FAIL,
    data: data
  }
};

export const plannerUpdateSuccessful = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.UPDATE_PLANNER_SUCCESS,
    data: data
  }
};

export const plannerUpdateFailed = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.UPDATE_PLANNER_FAIL,
    data: data
  }
};

export const plannerResetSuccessful = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.RESET_PLANNER_SUCCESS,
    data: data
  }
};

export const plannerResetFailed = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.RESET_PLANNER_FAIL,
    data: data
  }
};


export const getPlanner = () => {
  return {
    type: PLANNER_ACTION_TYPES.GET_PLANNER,
  }
};


export const getPlannerSuccess = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.GET_PLANNER_SUCCESS,
    data: data
  }
};

export const getPlannerFailed = (data) => {
  return {
    type: PLANNER_ACTION_TYPES.GET_PLANNER_FAIL,
    data: data
  }
};

export const clearError = () => {
  return {
    type: PLANNER_ACTION_TYPES.CLEAR_ERROR
  }
};


//////////////////////// THUNKS ///////////////////////////////////


export const getCyclePlanner = (accessToken, month = moment().month() + 1, year = moment().year(), lookAhead = 2) => {
  return (dispatch) => {
    dispatch(getPlanner());
    kangpeService.getPlanner(month, year, lookAhead, accessToken)
      .then((response) => {
        if(response){
          const {cycle_planner_preferences, periods} = response;
          const eventList = [];
          _.forEach(periods, (range) => {
            _.forEach(range, (period) => {
              eventList.push(createEventFromLength(period.last_period, period.period_length, "Period Days"));
              eventList.push(createEventFromRange(period.safe_period_2_start, period.safe_period_2_end, "Safe Days"));
              eventList.push(createEventFromRange(period.fertile_period_start, period.fertile_period_end, "Fertile Days"));
              eventList.push(createEventFromRange(period.fertile_period_end, period.fertile_period_end, "Ovulation Day"));
              return eventList;
            });
          });
          dispatch(getPlannerSuccess({
            preferences: cycle_planner_preferences,
            eventList: eventList
          }));
        }else{
          dispatch(getPlannerFailed({message: "No planner data", code: 404}));
        }
      })
      .catch((err) => {
        dispatch(getPlannerFailed(err.response.message));
      })
  }
};


export const setupPlanner = (values) => {
  return (dispatch) => {
    dispatch(setPlanner());
    kangpeService.setUpPlanner(values)
      .then((response) => {
        setHasCyclePlanner(true, function () {
          dispatch(plannerSetUpSuccessful(response))
        });
      })
      .catch((err) => {
        dispatch(plannerSetUpFailed(err.response.data));
      })
  }
};

export const updatePlanner = (values) => {
  return (dispatch) => {
    dispatch(setPlanner());
    kangpeService.updatePlanner(values)
      .then((response) => {
        dispatch(plannerUpdateSuccessful(response))
      })
      .catch((err) => {
        dispatch(plannerUpdateFailed(err.response.data));
      })
  }
};

export const resetPlanner = (accessToken) => {
  return (dispatch) => {
    dispatch(setPlanner());
    kangpeService.resetPlanner(accessToken)
      .then((response) => {
        setHasCyclePlanner(false, function () {
          dispatch(plannerResetSuccessful(response))
        });
      })
      .catch((err) => {
        dispatch(plannerResetFailed(err.response.data));
      })
  }
};


const createEventFromLength = (start, length, title) => {
  return {
    title: title,
    allDay: true,
    start: moment(start).format("YYYY-MM-DD"),
    end: moment(start).add(length, 'days').format("YYYY-MM-DD")
  }
};

const createEventFromRange = (start, end, title) => {
  return {
    title: title,
    allDay: true,
    start: moment(start).format("YYYY-MM-DD"),
    end: moment(end).format("YYYY-MM-DD")
  }
};
