import React from 'react';

export default function InitialConsultDoctorCard({history}) {
  return (
    <div className="dashboard-card">
      <div className="empty-state-wrap">
        <i className="fas fa-user-md" style={{fontSize: "4rem"}}/>
        <br/><br/><br/>
        <p className="dashboard-card__title-text" style={{textAlign: "center"}}>
          You have not yet consulted a Doctor. 
          <br/>
        </p>
        <div>
          <button 
            onClick={() => ( history.push('/consultations/new') )}
            className="dashboard__primary-btn dashboard-control__button" 
            style={{width: "auto"}}>
            Consult a Doctor
          </button>
        </div>
      </div>
    </div>
  )
}
