import React from 'react';

class PreCheckNewConsultationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: props.phoneNumber,
      address: props.address,
      initialView: true
    }
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  onUpdateContact = (event) => {
    event.preventDefault();
    const { phone, address } = this.state;

    this.props.onUpdateContact(phone, address);
  }

  render() {
    const { phone, initialView, address } = this.state;
    const { onContinueConsultation } = this.props;

    return (
      <form className="dashboard-form" onSubmit={this.onUpdateContact}>
        <label htmlFor="phoneNumber" className="profile-item__label">
          Phone number
        </label>
        <input
          id="phoneNumber"
          className="dashboard-form__input"
          name="phone"
          value={phone}
          disabled={initialView ? true : false}
          onChange={this.onInputChange}/>
        <label htmlFor="address" className="profile-item__label" style={{marginTop: "1rem"}}>
          Address
        </label>
        <input
          id="address"
          className="dashboard-form__input"
          name="address"
          value={address}
          disabled={initialView ? true : false}
          onChange={this.onInputChange}/>
        {
          initialView ? (
            <>
              <button
                className="dashboard__primary-btn dashboard-form__btn"
                style={{width: "100%", marginTop: "2rem"}}
                onClick={onContinueConsultation}>
                  Continue to Consultation
              </button>
              <button
                className="dashboard__primary-btn dashboard-form__btn"
                style={{width: "100%", marginTop: "1rem"}}
                onClick={() => this.setState({initialView: false})}>
                  Change Details
              </button>
            </>
          ) : (
            <button
              className="dashboard__primary-btn dashboard-form__btn"
              style={{width: "100%", marginTop: "2rem"}}>
                Save & Continue Consultation
            </button>
          )
        }
      </form>
    )
  }
}

export default PreCheckNewConsultationForm;
