import React from 'react';
import {connect} from 'react-redux';
import {sessionService} from 'redux-react-session';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import * as _ from 'lodash';
import queryString from 'querystring';
import userService from '../services/userService';
import FreshChat from 'react-freshchat';
import Sidebar from './Sidebar';
import PrivateRoute from '../components/utils/PrivateRoute';
import TipContainer from "./TipContainer";
import DashboardHome from "../components/DashboardHome";
import LoadingScreen from "../components/LoadingScreen";
import TipPage from "./TipPage";
import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';
import {displayNewConsultationMessage,
        activateEndConsultation,
        updateCurrentlyOpenedConsultationStatus} from '../actions/consultation';
import ProfileContainer from "./ProfileContainer";
import PlannerContainer from "./PlannerContainer";
import ConsultationRatingContainer from "../containers/ConsultationRatingContainer";
import RatingSuccessfulPage from "../components/RatingSuccessfulPage";
import ProviderContainer from "./ProviderContainer";
import ChangePasswordContainer from "./ChangePasswordContainer";
import BenefitsContainer from "./BenefitsContainer";
import SecondaryCareContainer from "./SecondaryCareContainer";
import FeedbackContainer from "./FeedbackContainer";
import FeedbackChatContainer from './FeedbackChatContainer';
import ConsultDoctorContainer from './ConsultDoctorContainer';
import PreConsultationForm from '../components/forms/PreConsultationForm';
import DoctorProfile from '../components/DoctorProfile';
import SettingsPage from "../components/SettingsPage";
import PageNotFound from "../components/PageNotFound";
import ConsultationDetailContainer from "./ConsultationDetailContainer";
import { isProduction } from '../services/utilityService';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    const {dispatch, location} = props;

    props.history.listen(() => {
      dispatch({type: DISPLAY_ACTION_TYPES.HIDE_SIDEBAR});
    });

    this.state = {
      isLoading: false,
      referrer: queryString.parse(location.search.substr(1)).r || window.location.href
    };
  }

  componentDidMount() {
    const {user, setUser} = this.props;
    userService.getPlanDetails()
    .then(plan => {
      const newUser = {
        ...user,
        plan: plan.product.name,
        planType: plan.product.plan_type,
        planInfo: {...plan}
      }
      sessionService.saveUser(newUser)
        .then(() => {
          //check if plan has expired...and exit
          setUser(newUser);

          if ((newUser.planInfo.active_status_id === 4 /*Suspended*/
              || newUser.planInfo.active_status_id=== 5 /*Expired*/
              || newUser.planInfo.active_status_id === 7 /*Delayed*/)
            && window.location.href.indexOf("plan-expired") === -1) {

            window.location = "/plan-expired";
          }
        });
    });
    // this.initializeWebSocketChannel();
  }

  initializeWebSocketChannel = () => {
    const {dispatch, user} = this.props;
    const url = `${process.env.REACT_APP_WEB_SOCKET_URL}`;

    this.ws = new WebSocket(url);

    this.ws.onopen = (evt) => {
      this.ws.send(JSON.stringify({
        channel: "consultations",
        event: {
          category: "subscription",
          action: "create"
        },
        data:{
          user_id: this.props.user.id
        }
      }));
      console.log('connection open', evt);
    };

    this.ws.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages
      const data = JSON.parse(evt.data);
      if(data.event.category === "message"){
        const newMessage = JSON.parse(data.data.message)
        if (newMessage.sender_id !== user.id) {
          dispatch(displayNewConsultationMessage(newMessage))
        }
      }else if(data.event.category === "consultation"){
        if (data.data.status_id === 8) {
          dispatch(activateEndConsultation(data.data))
        } else {
          dispatch(updateCurrentlyOpenedConsultationStatus(data.data))
        }
      }else {
        console.log(data);
      }
    };

    this.ws.onclose = () => {
      console.log('disconnected');
      if(this) {
        this.initializeWebSocketChannel()
      }
    };
  };

  componentWillUnmount() {
    if(this.ws){
      this.ws.close();
    }
  }

  render() {
    const {user} = this.props;

    let authenticated = true;

    if (_.isEmpty(user)) return (<LoadingScreen/>);
    if (!_.isEmpty(user)) {
      if (user.planInfo && (user.planInfo.active_status_id === 4 /*Suspended*/
          || user.planInfo.active_status_id === 5 /*Expired*/
          || user.planInfo.active_status_id === 7 /*Delayed*/)) {
        return (<Redirect to="/plan-expired"/>)
      } else {
        return (
          <Route>
            <React.Fragment>
              <Sidebar user={user}/>
              <Switch>
                <PrivateRoute component={TipContainer} path="/tips" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={TipPage} path="/tips/:id/:title" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={ConsultDoctorContainer} path="/consultations" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={ConsultationDetailContainer} path="/consultations/:id/messages" authenticated={authenticated} user={user}/>
                <PrivateRoute component={ConsultationRatingContainer} path="/consultations/:id/rate-consultation" authenticated={authenticated} exact user={user}/>
                <PrivateRoute component={RatingSuccessfulPage} path="/rate-consultation-success" authenticated={authenticated} exact user={user}/>
                <PrivateRoute component={PreConsultationForm} path="/consultations/new" authenticated={authenticated} user={user}/>
                <PrivateRoute component={DoctorProfile} path="/chat/doctors-profile" authenticated={authenticated} user={user}/>
                <PrivateRoute component={ProfileContainer} path="/profile" authenticated={authenticated} user={user}/>
                <PrivateRoute component={PlannerContainer} path="/planner" authenticated={authenticated} user={user}/>
                <PrivateRoute component={ProviderContainer} path="/hospitals" authenticated={authenticated} user={user}/>
                <PrivateRoute component={ChangePasswordContainer} path="/change-password" authenticated={authenticated} user={user}/>
                <PrivateRoute component={SecondaryCareContainer} path="/benefits/secondary-care" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={BenefitsContainer} path="/benefits" authenticated={authenticated} user={user}/>
                <PrivateRoute component={SettingsPage} path="/settings" authenticated={authenticated} user={user}/>
                <PrivateRoute component={DashboardHome} path="/" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={FeedbackContainer} path="/feedback" exact authenticated={authenticated} user={user}/>
                <PrivateRoute component={FeedbackChatContainer} path="/feedback/thread/:id" exact authenticated={authenticated} user={user}/>
                <Route component={PageNotFound}/>
              </Switch>
              {isProduction() && 
                <FreshChat
                    token={process.env.REACT_APP_FRESHCHAT_TOKEN || ""}
                    email={user.emailAddress}
                    first
                    onInit={widget => {                    
                        widget.user.setProperties({
                          email: user.emailAddress,
                          first_name: user.firstName,
                          last_name: user.lastName,
                          hmo_id: user.hmoId,
                          source: "Reliance Care (WEB)"
                        })                    
                    }}
                  />  
              }
          
            </React.Fragment>
          </Route>
        );
      }
    }

    return (<LoadingScreen/>)
  }
}

export default withRouter(connect()(Dashboard))