import axios from 'axios';
import querystring from 'querystring';
import {BASE_URL_RELIANCE, getCookie,DOMAIN_COOKIE_NAME} from './utilityService';

const BASE_URL = BASE_URL_RELIANCE;

export const ENDPOINTS = {
  base:`${BASE_URL}/enrollees`,
  doctor: {
    register: "doctors/profile",
    retrieveStatus: (doctorId) => `/doctors/${doctorId}/status`,
    utilities: "/utility/specialties"
  },
  feedbackThread: {
    postFeedback: "/enrollees/feedback",
    getFeedbackThreads: "/enrollees/feedback/thread",
    getFeedbackThreadById: (id) => `/enrollees/feedback/thread/${id}`,
    postFeedbackToThread: (id) => `/enrollees/feedback/thread/${id}`
  },
  consultations: {
    consultation: "/consultations",
    consultationDetails: (id) => `/consultations/${id}`,
    status: (id) => `/consultations/${id}/status`,
    messages: (id) => `/consultations/${id}/messages`,
    prescriptions: (id, prescriptionId) => `/consultations/${id}/prescriptions/${prescriptionId}`,
    prescriptionsProvider: (id, prescriptionId) => `/consultations/${id}/prescriptions/${prescriptionId}/provider`,
    complaints: "/consultations/complaints",
    referrals: (id, referralId) => `consultations/${id}/referrals/${referralId}`,
    rating: (id) => `consultations/${id}/ratings`
  },
  profile: {
    profileDetails: "/enrollees/profile"
  },
  pharmacies: {
    allPharmacies: "consultations/pharmacies"
  }
};

export class ApiService {
  constructor(accessToken=getCookie(DOMAIN_COOKIE_NAME), BASE) {

    this.ax = axios.create({
      baseURL: BASE || BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${accessToken}`
      }
    });
    this.data = {};
  }

  //overview,profile,transactions,products


  post = (url, data) => {
    return this.ax.post(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };

  put = (url, data) => {
    return this.ax.put(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };

  patch = (url, data) => {
    return this.ax.patch(url, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };

  get = (url, data) => {
    return this.ax.get(`${url}?${querystring.stringify(data)}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw new ApiServiceException(e);
      });
  };
}

function ApiServiceException(e, _meta) {
  if (!e.response)
    e = {response: {status: 0, statusText: "Unknown Error", data: {message: "An Error Occurred"}}};
  this.code = e.response.status;
  this.message = e.response.data.message || e.response.statusText;
  this.meta = _meta;
}

export default new ApiService();