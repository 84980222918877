import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { reset } from 'redux-form';
import { clearUserProfile } from '../actions/user';
import queryString from 'querystring';
import LOGO from '../img/logo.svg';
import SignUpForm from '../components/forms/SignUpForm';
import UtilityService, { DAARA_TOKEN_NAME, getCookie } from '../services/utilityService';
import LoadingScreen from '../components/LoadingScreen';
import userService from '../services/userService';
import providerService from '../services/providerService';
import { features } from '../services/daara-config.json';

class SignUpContainer extends React.Component {
    constructor(props) {
        super(props);
        //check match for params, if exists get enrollee type and/or hash
        //check props for params, if exists get enrollee type and accessToken
        const { location } = props;
        const hash = queryString.parse(location.search.substr(1)).hash;

        this.state = {
            isLoading: true,
            formData: {
                user: {
                    hash: hash || null,
                    accessToken: this.props.location.state ? this.props.location.state.at : null
                }
            },
            message: null,
            error: null,
            successful: false
        };
    }

    componentDidMount() {
        const { user } = this.state.formData;

        Promise.all([
            UtilityService.getUtilities(),
            userService.unhashParams(user.hash),
            providerService.getProviders(null, user.hash)
        ]).then(
            (response) => {
                this.setState({
                    isLoading: false,
                    formData: {
                        user: Object.assign({}, user, { ...response[1], enrolleeType: 1 }),
                        utils: Object.assign({}, response[0], {
                            providers: response[2]
                        })
                    },
                    error: null
                });
            },
            (e) => {
                if (features.enrolleeService.unhash) {
                    console.log(e);
                    const { data } = e.response;
                    if (e.response && data.error === 403) {
                        window.location = '/login';
                    } else {
                        this.setError({
                            message: e.response.data.message || 'An Error Occurred',
                            status: e.response.status,
                            code: e.response.status
                        });
                    }
                } else {
                    if (e.response && e.response.status === 403) {
                        window.location = '/login';
                    } else {
                        this.setError({
                            message: e.response.data.message || 'An Error Occurred',
                            status: e.response.status,
                            code: e.response.status
                        });
                    }
                }
            }
        );
    }

    setError = (error, abort) => {
        this.setState({
            isLoading: false,
            abort,
            error
        });
    };

    scrollToError = () => {
        this.errorHolder.scrollIntoView({ behaviour: 'smooth' });
    };

    onSubmitForm = (values, dispatch) => {
        const {
            location: { state }
        } = this.props;
        const { user } = this.state.formData;
        this.setState({
            isLoading: true
        });

        if (state && state.subscriptionId) {
            values.subscription_id = state.subscriptionId;
            values.product_id = state.productId;
            values.enrollee_type = 2;
        }
        const { formData } = this.state;
        const _values = features.enrolleeService.completeProfile
            ? { ...values, id: formData.user.id }
            : values;
        userService
            .register(
                {
                    ..._values
                },
                user.hash
            )
            .then(
                (message) => {
                    //reset sign up form on successful submission
                    dispatch(reset('signUpForm'));
                    dispatch(clearUserProfile());
                    this.setState({
                        error: null,
                        message: message,
                        successful: true,
                        abort: false,
                        isLoading: false
                    });
                },
                (e) => {
                    this.setError({
                        message: e.response.data.message,
                        code: e.status,
                        isLoading: false
                    });
                    this.scrollToError();
                }
            );
    };

    render() {
        const { isLoading, formData, error, abort, successful } = this.state;

        if (isLoading)
            return (
                <SignUpFormHolder>
                    <LoadingScreen />
                </SignUpFormHolder>
            );

        if (!isLoading && abort)
            return (
                <SignUpFormHolder>
                    <div
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: 'red',
                            color: 'white'
                        }}
                    >
                        <h3>
                            An error seems to have occurred. Please try again. If issues persist,
                            kindly send a mail to{' '}
                            <a
                                style={{ color: 'white' }}
                                href="mailto:hello@reliancehmo.com?Subject=Enrollee Sign Up Help [from web app]"
                            >
                                hello@reliancehmo.com
                            </a>
                            .
                        </h3>
                    </div>
                </SignUpFormHolder>
            );

        if (!isLoading && successful)
            return (
                <SignUpFormHolder>
                    <div
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: '#fffffa',
                            color: '#111'
                        }}
                    >
                        <div>
                            <h3>Congratulations!</h3>
                            <p>
                                {`${
                                    formData.user.accessToken
                                        ? 'Your Family member has'
                                        : 'You have'
                                }`}{' '}
                                successfully signed up for a RelianceHMO insurance plan and can
                                start using the plan immediately.
                                <br />
                                <br />
                                We just sent an email and SMS to the email address and phone number
                                you provided us. Kindly read the very important information
                                carefully as they contain all you need to start using your
                                insurance.
                                <br />
                                <br />
                                If you have any issues our Reliance Help Centre agents are always
                                happy to help via:
                                <br />
                                Email:{' '}
                                <a href="mailto:hello@reliancehmo.com?Subject=Enrollee Enquiry [from web app]">
                                    hello@reliancehmo.com
                                </a>
                                <br />
                                Telephone: <a href="tel:070073542623">0700-7354-2623</a>
                                <br />
                                Whatsapp: <a href="tel:017001580">01-700-1580</a>
                                <br />
                                Facebook:{' '}
                                <a href="https://www.facebook.com/pg/reliancehmo">@reliancehmo</a>
                                <br />
                                <br />
                                Thanks for choosing RelianceHMO.
                            </p>
                            <br />
                            <br />
                            {formData.user.accessToken ? (
                                <div style={{ fontSize: '22px' }}>
                                    <div style={{ float: 'left' }}>
                                        <Link to="/profile">Go back</Link>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className="button_link"
                                            onClick={() => window.location.reload()}
                                        >
                                            Add More Dependants
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ fontSize: '22px' }}>
                                    <div style={{ float: 'left' }}>
                                        <a href="https://rel.hm/app">Download App</a>
                                    </div>
                                    <div style={{ float: 'right' }}>
                                        <a href="/login">Login</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </SignUpFormHolder>
            );

        return (
            <SignUpFormHolder user={formData.user}>
                {!isLoading && error && (
                    <div
                        ref={(el) => (this.errorHolder = el)}
                        style={{
                            padding: '10px',
                            margin: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !isLoading && error ? 'visible' : 'hidden'
                        }}
                    >
                        <h3>{error.message}</h3>
                    </div>
                )}

                <SignUpForm
                    data={formData.utils}
                    user={formData.user}
                    onError={this.setError}
                    onSubmit={this.onSubmitForm}
                />
            </SignUpFormHolder>
        );
    }
}

export default withRouter(SignUpContainer);

export const SignUpFormHolder = (props) => (
    <div className="container">
        <div className="signup">
            <div className="signup__header">
                <img src={LOGO} alt="logo" />
                <p>Enrollee Sign-Up Form</p>
            </div>
            <div className="dashboard-card signup__form">{props.children}</div>
        </div>
    </div>
);
