import {TIP_ACTION_TYPES} from '../actions/actionTypes'
import Immutable from 'seamless-immutable';

const initialState = Immutable({isFetching:false,selectedTip:null,didInvalidate:true,itemsById:{}});  // = states.tips

export default function tipReducer(state=initialState, action){
    switch (action.type){
        case TIP_ACTION_TYPES.GET_TIPS:
            //perform get request
            return Immutable(state).merge({isFetching:true});

        case TIP_ACTION_TYPES.GET_TIPS_SUCCESS:
            //dispatch request success, hide loading page, pass response
            return Immutable(state).merge({
                isFetching: false,
                didInvalidate: false,
                itemsById: Immutable(state.itemsById).merge({
                    ...action.data
                })
            });

        case TIP_ACTION_TYPES.GET_TIPS_ERROR:
            //dispatch request fail, hide loading page
            return Immutable(state).merge({isFetching:false,selectedTip:null,didInvalidate:false});

        case TIP_ACTION_TYPES.INVALIDATE:
            //invalidate_tips
            return Immutable(state).merge({didInvalidate:action.data});

        case TIP_ACTION_TYPES.GET_TIP:
            return Immutable(state).merge({selectedTip:null});
        case TIP_ACTION_TYPES.GET_TIP_SUCCESS:
            //select tip
            return Immutable(state).merge({
                itemsById:Immutable(state.itemsById).merge({
                   [action.data.id]:action.data
                }),
                selectedTip:action.data
            });
        default:
            return state;
    }
}


