import React from 'react';
import {withRouter} from 'react-router-dom';
import {reset} from 'redux-form';
import {clearUserProfile} from '../actions/user';
import queryString from 'querystring';
import LOGO from '../img/logo.svg'
import DoctorSignUpForm from '../components/forms/DoctorSignUpForm';
import LoadingScreen from '../components/LoadingScreen';
import { ApiService, ENDPOINTS } from '../services/ApiService';

class DoctorSignUpContainer extends React.Component {

  constructor(props) {
    super(props);
    //check match for params, if exists get enrollee type and/or hash
    //check props for params, if exists get enrollee type and accessToken

    this.state = {
      isLoading: true,
      formData: {
        user:{}
      },
      message: null,
      error: null,
      successful: false,
      doctorSpecialties: []
    };
  }

  componentDidMount() {
    const {location} = this.props;
    const accessToken = queryString.parse(location.search.substr(1)).at;
    if(!accessToken) {
      this.setError({
        message: "No Access Token found",
        code: 403,
        isLoading: false
      });
    }else{
      this.service = new ApiService(accessToken);
      this.setState({
        isLoading: false,
        formData:{
          user:{
            accessToken: accessToken
          }
        }
      })
      this.getDoctorSpecialty();
    }    
  }

  getDoctorSpecialty = () => {
    this.service.get(ENDPOINTS.doctor.utilities).then((response) => {
      this.setState({
        doctorSpecialties: response.data
      })
    })
    .catch((error) => {
      console.log(error)
    })
  }

  setError = (error,abort) => {

    this.setState({
      isLoading: false,
      abort,
      error
    })
  };

  scrollToError = () => {
    this.errorHolder.scrollIntoView({behaviour: 'smooth'});
  };


  onSubmitForm = (values, dispatch) => {
    this.setState({
      isLoading: true
    });

    this.service.post(ENDPOINTS.doctor.register, values)
      .then(message => {
        //reset sign up form on successful submission
        dispatch(reset('doctorSignUpForm'));
        dispatch(clearUserProfile());
        this.setState({
          error: null,
          message: message,
          successful: true,
          abort: false,
          isLoading: false
        })
      }, (e) => {
        console.log(e);
        this.setError({
          message: e.message,
          code: e.code,
          isLoading: false
        });
        this.scrollToError();
      });
  };


  render() {
    const {isLoading, formData, error, abort, successful, doctorSpecialties} = this.state;

    if (isLoading)
      return (
        <SignUpFormHolder>
          <LoadingScreen/>
        </SignUpFormHolder>
      );

    if (!isLoading && abort)
      return (
        <SignUpFormHolder>
          <div style={{padding: "10px", margin: "10px", backgroundColor: "red", color: "white"}}>
            <h3>
              An error seems to have occurred.
              Please try again. If issues persist,
              kindly send a mail to <a style={{color: "white"}}
                                       href="mailto:doctors@reliancehmo.com?Subject=Doctor profile completion [from web app]">doctors@reliancehmo.com</a>.
            </h3>
          </div>
        </SignUpFormHolder>
      );

    if (!isLoading && successful)
      return (
        <SignUpFormHolder>
          <div style={{padding: "10px", margin: "10px", backgroundColor: "#fffffa", color: "#111"}}>
            <div>
              <h3>Congratulations!</h3>
              <p>
                You have successfully signed up as
                a doctor on RelianceHMO.
                <br/>
                <br/>
                Once we're done verifying your details, We will send an email and SMS to the email address and phone number you provided us.
                Kindly read the very important information carefully as they contain all you need
                to start using managing consultations.
                <br/>
                <br/>
                If you have any issues our Reliance Help Centre agents are always happy to help via:
                <br/>
                Email: <a href="mailto:doctors@reliancehmo.com?Subject=Doctor Enquiry [from web app]">doctors@reliancehmo.com</a>
                <br/>
                Telephone: <a href="tel:070073542623">0700-7354-2623</a>
                <br/>
                Whatsapp: <a href="tel:07035369587">070-353-69587</a>
                <br/>
                Facebook: <a href="https://www.facebook.com/pg/reliancehmo">@reliancehmo</a>
                <br/>
                <br/>
                Thanks for choosing RelianceHMO.
              </p>
            </div>
          </div>
        </SignUpFormHolder>
      );

    return (
      <SignUpFormHolder user={formData.user}>
        {!isLoading && error &&
        <div ref={(el) => this.errorHolder = el} style={{
          padding: "10px",
          margin: "10px",
          backgroundColor: "red",
          color: "white",
          visibility: (!isLoading && error) ? "visible" : "hidden"
        }}>
          <h3>{error.message}</h3>
        </div>}

        <DoctorSignUpForm
          data={formData.utils}
          user={formData.user}
          onError={this.setError}
          onSubmit={this.onSubmitForm}
          doctorSpecialties={doctorSpecialties}
        />
      </SignUpFormHolder>
    )
  }
}

export default withRouter(DoctorSignUpContainer)


export const SignUpFormHolder = (props) => (
  <div className="container">
    <div className="signup">
      <div className="signup__header">
        <img src={LOGO} alt="logo"/>
        <p>Doctor Registration Form</p>
      </div>
      <div className="dashboard-card signup__form">
        {props.children}
      </div>
    </div>
  </div>
);