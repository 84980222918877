import React from 'react';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const SideBarMenuItem = ({to, icon, title, badge, newMessage}) => {
  const content = (
    <>
      <i className={icon} />
      {title}
      {badge &&
        <span className="item__badge">{badge}</span>
      }
      {newMessage && newMessage.count > 0 && 
        <i 
          className={newMessage.icon} 
          style={{ paddingLeft: "0.6em", margin: "0", color: "#01263e" }}>
            <span style={{ paddingLeft: "0.2em", fontWeight: "bolder" }}>{newMessage.count}</span>
        </i>
      }
    </>
  );
  
  return (
    <Route path={to} exact children={({ match }) => (
      <li>
        {to.includes("http") ?
          <a className={`dashboard__menu ${match ? "menu-active" : ""}`} href={to}>
            {content}
          </a>
          :
          <Link className={`dashboard__menu ${match ? "menu-active" : ""}`} to={to}>
            {content}
          </Link>
        }
      </li>
    )} />
  )
};

export default withRouter(connect()(SideBarMenuItem));
