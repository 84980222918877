import React, { Component} from 'react';
import Modal from "../boxes/Modal";

class ProductsModal extends Component {
  render() {
    const { onClose, unassignedProducts, selectUnassignedProduct } = this.props;

    return (
      <Modal title="Products List" onClose={onClose}>
        <div className="unassigned-prod-modal-wrap">
          {unassignedProducts.map((product, index)=>{
            return (
              <div key={product.id} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                <div style={{margin:"1rem 2rem"}}>
                  <h3 className="dashboard-card__title-text">{product.name}</h3>
                  <p className="unassigned-prod-modal-wrap__small-text">{product.available_slots} slots available</p>
                </div>                
                <button style={{marginLeft:"2rem"}} onClick={()=>selectUnassignedProduct(product)} className="dashboard__primary-btn">
                  Select
                </button>
              </div>
            )
          })}
        </div>
      </Modal>
    );
  }
}

export default ProductsModal;