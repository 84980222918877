import React from 'react';
import moment from 'moment';
import SelectPharmacyModal from '../modals/SelectPharmacyModal';

const PrescriptionData = (props) => {
  return (
    <React.Fragment>
      {
        props.showPharmacyModal &&
        <SelectPharmacyModal 
          onClose={props.togglePharmacyModal} 
          userToken={props.userToken} 
          pharmacies={props.pharmacies}
          consultationId={props.consultationId}
          prescriptionId={props.attachments[0].content.id}
        />
      }

      <div style={{display: "flex"}}>
        <div 
          data-tooltip="Click here to select a preferred Pharmacy" 
          style={{margin: "0 1rem 1rem 0", padding: "1rem"}}
        >
          <i 
            className="fas fa-prescription-bottle-alt" 
            style={{ cursor: "pointer", fontSize: "20px"}} 
            onClick={() => props.togglePharmacyModal(props.pharmacyModalName)}
          >
          </i>
        </div>

        <div style={{fontSize: "1.3rem"}}>
          <p> {props.message}</p>
          <p>{moment(props.created_at).format('HH:mm')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PrescriptionData;