import {USER_ACTION_TYPES} from "../actions/actionTypes";
import Immutable from 'seamless-immutable';

const initialState = Immutable({isLoading:false,isLoggedIn:false,meta:{},error:{}});
export default function userReducer(state=initialState,action){
    switch (action.type){
        case USER_ACTION_TYPES.LOGIN:
        case USER_ACTION_TYPES.REGISTER:
        case USER_ACTION_TYPES.RESET_PASSWORD:
        case USER_ACTION_TYPES.CHANGE_PASSWORD:
        case USER_ACTION_TYPES.GET_PROFILE:
        case USER_ACTION_TYPES.GET_BENEFITS:
            return state.merge({isLoading:true});
        case USER_ACTION_TYPES.LOGIN_SUCCESS:
            return state.merge({isLoading:false,isLoggedIn:true,error:{},meta:{referrer:action.data.ref}});
        case USER_ACTION_TYPES.LOGIN_FAIL:
            return state.merge({
                isLoading:false,
                isLoggedIn:false,
                error:Immutable(state.error).merge({
                    message:action.data.message,
                    code:action.data.code
            })});
        case USER_ACTION_TYPES.REGISTER_SUCCESS:
            return state.merge({isLoading:false,isLoggedIn:true,error:{}});
        case USER_ACTION_TYPES.REGISTER_FAIL:
            return state.merge({
                error:Immutable(state.error).merge({
                    message:action.data.message,
                    code:action.data.code
                })});
        case USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS:
            return state.merge({isLoading:false,recoverySuccessful:true,error:{}});
        case USER_ACTION_TYPES.RESET_PASSWORD_FAIL:
            return state.merge({
                isLoading:false,
                isLoggedIn:false,
                recoverySuccessful:false,
                error:Immutable(state.error).merge({
                    message:action.data.message,
                })});
        case USER_ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
            return state.merge({isLoading:false,passwordUpdated:true,error:{}});
        case USER_ACTION_TYPES.CHANGE_PASSWORD_FAIL:
            return state.merge({
                isLoading:false,
                passwordUpdated:false,
                error:{
                    message:action.data.message
                }});
        case USER_ACTION_TYPES.GET_PROFILE_SUCCESS:
            return state.merge({
                isLoading:false,
                meta:Immutable(state.meta).merge({profile:action.data}),
                error:{}
            });
        case USER_ACTION_TYPES.GET_PROFILE_FAIL:
            return state.merge({
                error:Immutable(state.error).merge({
                    message:action.data.message
                })
            });
        case USER_ACTION_TYPES.CLEAR_PROFILE:
            return state.merge({
                meta:{}
            });
        case USER_ACTION_TYPES.GET_BENEFITS_SUCCESS:
            return state.merge({
                isLoading:false,
                meta:Immutable(state.meta).merge({benefits: action.data.sort((a,b) => {
                    return a.benefit_name > b.benefit_name ? 1 : -1
                })
            }),
                error:{}
            });
        case USER_ACTION_TYPES.GET_BENEFITS_FAIL:
            return state.merge({
                error:Immutable(state.error).merge({
                    message:action.data.message
                })
            });
        default:
            return state;
    }
}