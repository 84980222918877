/**
 * Created by the.last.mayaki on 23/09/2017.
 */

export const emptyStore = {
    tips:{
        isFetching:false,
        didInvalidate:false,
        selectedTip:null,
        itemsById:{},
        itemsIdArray:[]
    },
    display:{
        showSideBar:false
    },
    planner:{
        isFetching:false,
        preferences:{},
        periodData:[],
        error:{},
        message:null
    },
    provider: {
        isFetching: false,
        providers:[],
        error:null
    }

};


export default emptyStore
