import axios from 'axios';
import Cookies from 'js-cookie';
import { sendHttpGetRequest, sendHttpPostRequest } from './axiosInterceptor';
import { states, occupations, preMeds, countries, titles, maritalStatuses } from './constants';
import { features } from './daara-config.json';

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getAccountsDashboardUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:3000';
        case 'v3test':
        case 'development':
            return 'https://accounts.develop.reliancehmo.com';
        case 'staging':
            return 'https://accounts.staging.reliancehmo.com';
        case 'daara':
            return 'https://accounts.daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://accounts.daara.staging.reliancehmo.com';
        default:
            return 'https://accounts.reliancehmo.com';
    }
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;
export const DAARA_TOKEN_NAME = process.env.REACT_APP_DAARA_TOKEN_NAME;
export const DAARA_REFRESH_TOKEN = process.env.REACT_APP_REFRESH_TOKEN || 'refresh_token';

export const DAARA_ENROLLEE_SERVICE_URL = process.env.REACT_APP_DAARA_ENROLLEE_SERVICE_URL;
export const DAARA_ACCOUNT_SERVICE_URL = process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL;
export const DAARA_FILE_SERVICE_URL = process.env.REACT_APP_DAARA_FILE_SERVICE_URL;

export const MESSAGE_TYPE = {
    text: 1,
    call: 2,
    action: 3
};

export const ATTACHMENT_TYPE = {
    media: 1,
    prescription: 2,
    test: 3,
    referral: 4
};

export const CONSULTATION_STATUS = {
    pending: 1,
    active: 2,
    disputePending: 3,
    disputeApproved: 4,
    disputeDeclined: 5,
    closed: 6,
    reopened: 7,
    closePending: 8
};

export const CONSULTATION_LIMIT = 1000;

export const getCookie = (_name = DOMAIN_COOKIE_NAME) => {
    return Cookies.get(_name);
};

export default class UtilityService {
    static getCountries() {
        return Promise.resolve(countries);
    }

    static getTitles() {
        return Promise.resolve(titles);
    }

    static getOccupations() {
        return Promise.resolve(occupations);
    }

    static getPreMedicalConditions() {
        return Promise.resolve(preMeds);
    }

    static getStates() {
        return Promise.resolve(states);
    }

    static async getMaritalStatuses() {
        if (features.enrolleeService.unhash) {
            const response = await axios.get(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/marital-status`
            );
            if (response) {
                const { data } = response;
                let result = data.map((status, index) => {
                    return { id: index, name: status };
                });
                return result;
            }
        }
        return Promise.resolve(maritalStatuses);
    }

    static getUtilities() {
        return Promise.all([
            UtilityService.getOccupations(),
            UtilityService.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                mStatuses: responses[1]
            };
        });
    }

    static async uploadFile(file, uploadType, accessToken) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('file_use', uploadType);

        if (features.fileService.upload) {
            return sendHttpPostRequest(`${DAARA_FILE_SERVICE_URL}/upload-multiple/image`, formData)
                .then((res) => {
                    const { key } = res.data[0];
                    return { filename: key.substr(key.lastIndexOf('/') + 1) };
                })
                .catch((e) => {
                    return {
                        message: e.response.data.message || 'An Error Occurred',
                        code: e.response.status
                    };
                });
        } else {
            return axios
                .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((response) => {
                    return response.data.data;
                })
                .catch((e) => {
                    return {
                        message: e.response.data.message || 'An Error Occurred',
                        code: e.response.status
                    };
                });
        }
    }
}
