import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoadingScreeen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import ChatThreadList from '../components/ChatThreadList';
import {ApiService, ENDPOINTS} from '../services/ApiService';

class ConsultDoctorContainer extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      isLoading: false,
      consultations: []
    }
  }

  componentDidMount(){
    this.setLoading(true);
    this.service.get(ENDPOINTS.consultations.consultation)
      .then(response => {
        this.setState({
          isLoading: false,
          consultations: response.data
        });
      })
  }

  setLoading(isLoading){
    this.setState({
      isLoading
    })
  }

  render () {
    const { isLoading, consultations } = this.state;
    return (
      <MainContent>
        <PageHeader title={isLoading ? 'Consultations' : 'Consult a Doctor'}/>
        <PageBody>
          { isLoading ? 
            <LoadingScreeen/> 
            : 
            <ChatThreadList chatThreadList={consultations} /> 
          }
        </PageBody>
      </MainContent>
    );
  }
}

export default withRouter(ConsultDoctorContainer);