import React from 'react';
import * as _ from 'lodash';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import {SyncLoader} from 'react-spinners';


class TipList extends React.Component {

  constructor() {
    super()
    this.state = {
      searchInput: "",
      canLoadMore: true
    }
  }

  componentDidMount() {
    //perform initial load
    this.props.onLoadMore();
  }

  handleSearch = (event) => {
    this.setState({
      searchInput: event.target.value.substr(0, 10)
    });
  }

  render() {
    const {isLoadingMore, tipsIdArray, renderTipItem, onLoadMore} = this.props;
    return (
      <div id="right" className="column content">
        <PageHeader
          title="Health Tips"
        />
        <PageBody>
          <div
            className="tips-wrapper">
            {tipsIdArray.reverse().map((id) => (
              renderTipItem(_.get(this.props.tipsById, id))
            ))}
          </div>
          <br/>
          <br/>
          {isLoadingMore &&
          <div style={{textAlign: "center", marginTop: "30px"}}>
            <SyncLoader color={'#107BC0'}/>
          </div>
          }
          <br/>
          <div style={{display: `${isLoadingMore ? 'none' : 'flex'}`, justifyContent: "center"}}>
            <button
              onClick={onLoadMore}
              className="infinite-scroll-btn">
              LOAD MORE
            </button>
          </div>
        </PageBody>
      </div>
    )
  }
}

export default TipList