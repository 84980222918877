import React, { Component } from 'react';
import {ApiService, ENDPOINTS} from '../services/ApiService';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import FeedbackThreadList from '../components/FeedbackThreadList';
import LoadingScreen from '../components/LoadingScreen';
import Modal from "../components/boxes/Modal";
import CenteredContentBlock from "../components/boxes/CenteredContentBlock";

class FeedbackContainer extends Component {
  constructor (props) {
    super(props)
    this.service = new ApiService(props.user.credentials.accessToken);

    this.state = {
      showModal: false,
      feedbackThreadList: [],
      title: "",
      content: "",
      searchInput: "",
      isLoading: false,
      error: {}  
    }
  }

  componentDidMount(){
    this.service.get(ENDPOINTS.feedbackThread.getFeedbackThreads).then((response) => {
      this.setState({
        feedbackThreadList: response.data.feedback_threads
      });
    })
    .catch((e) => {
      this.setState({
        error: { code: e.code, message: e.message },
        showModal: !this.state.showModal
      })
    });
  }

  componentWillUnmount(){
    this.setState({
      isLoading: !this.state.isLoading
    });
  }

  toggleNewChatModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  handleSearch = (event) => {
    this.setState({
        searchInput: event.target.value.substr(0, 10)
    });
  }

  createNewFeedbackThread = (event) => {
    event.preventDefault();

    const data = { title: this.state.title, content: this.state.content };

    this.setState({
      isLoading: !this.state.isLoading
    });

    this.service.post(ENDPOINTS.feedbackThread.postFeedback, data).then((response) => {
      window.location.href = `/feedback/thread/${response.data.feedback_thread_id}`
    });

    this.setState({
      title: "",
      content: ""  
    })
  }

  render() {
    const { showModal, feedbackThreadList, searchInput } = this.state;

    let filteredFeedbackThreadList = feedbackThreadList;

    if (searchInput) {
      filteredFeedbackThreadList = filteredFeedbackThreadList.filter((feedbackThread) => {
        return feedbackThread.title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
      });
    }

    const feedbackThreadListCheck = () => {
      return this.state.feedbackThreadList.length === 0 ?
        <LoadingScreen/>
        :
        <FeedbackThreadList feedbackThreadList={filteredFeedbackThreadList} />
    } 

    return (
      <MainContent>
        <PageHeader 
          title="Feedback"
          searchBarPlaceholder="Search Feedback..."
          showSearchBar
          handleSearch={this.handleSearch}
        />
        <PageBody>
          <div className="dashboard-btn-section">
            <div className="dashboard__primary-btn">
              <p onClick={this.toggleNewChatModal}>New Chat</p>
            </div>
          </div>
          {
            showModal &&
            <Modal onClose={this.toggleNewChatModal} title="New feedback">
              {
                this.state.isLoading
                ?
                <LoadingScreen />
                :
              <form 
                className="form new_feedback_form"
                style={{display: "flex", flexDirection: "column"}}
                onSubmit={this.createNewFeedbackThread} 
              >
                <input
                  name="title"
                  type="text" 
                  placeholder="Enter a title..." 
                  onChange={this.handleInputChange}
                  value={this.state.title}
                  required>
                </input>
                <input
                  name="content"
                  type="text" 
                  placeholder="Enter a message..." 
                  onChange={this.handleInputChange}
                  value={this.state.content}
                  required>
                </input>
                <button className="primary_button">
                  Create New Feedback
                </button>
              </form>
              }
            </Modal>
          }
          {
            this.state.error.message && this.state.error.code === 404 ?
              <CenteredContentBlock>
                  <div style={{fontSize: "40px", color: "grey", textAlign: "center"}}>
                    No Conversation started. Click on the "New Chat" button to start a conversation.
                  </div>
              </CenteredContentBlock>
              :
              feedbackThreadListCheck()
          }
        </PageBody>
      </MainContent>
    )
  }
}

export default FeedbackContainer;