import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';

class SendFeedbackForm extends Component {
  constructor() {
    super()
    this.state = {
      chatInput: ""
    }
  }

  handleChatInput = (event) => {
    this.setState({
      chatInput: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.chatInput);
    this.setState({ 
      chatInput: '' 
    });
  }

  render() {
    const { isSending } = this.props;

    return(
      <form 
        onSubmit={this.handleSubmit} 
        className="feedback-chat-input"
      >
        <input type="text"
          onChange={this.handleChatInput}
          value={this.state.chatInput}
          placeholder="Write a message..."
          required />
        <button 
        className="dashboard__primary-btn"
        >
          { 
            isSending 
              ? 
              <PulseLoader color="#ffffff" size={7}/> 
              : 
              "Send"
          }
        </button>
      </form>
    )
  }
}

export default SendFeedbackForm;