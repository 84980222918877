import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {Link} from 'react-router-dom';
import LOGO from '../../img/logo.svg';

let NewPasswordForm = (props) => {

    const {handleSubmit,pristine,submitting} = props;

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="form_logo">
                    <Link to={'/'}><img src={LOGO} alt="Reliance HMO's Logo"/></Link>
                </div>
                <Field component="input" type="password" name="password" placeholder="New Password" required/>
                <Field component="input" type="password" name="confirm_password" placeholder="Confirm Password" required/>
                <Field component="input" type="hidden" name="token" />
                <Field component="input" type="hidden" name="expire" />
                <button type="submit" disabled={pristine||submitting}>SUBMIT</button>
            </form>
        </div>
    )
};


NewPasswordForm = reduxForm({
    form:'newPasswordForm'
})(NewPasswordForm);

export default NewPasswordForm