import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import userService from '../services/userService';
import MainContent from './boxes/MainContent';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import LoadingScreen from './LoadingScreen';
import ButtonBar from './boxes/ButtonBar';
import StatsDataCard from './boxes/StatsDataCard';
import DashboardProfileCard from './boxes/DashboardProfileCard';
import { features } from '../services/daara-config.json';

class DashboardHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usageData: {
        tipsRead: 0,
        questionsAsked: 0,
        hospitalVisits: 0,
      },
      hasDependants: this.props.user.planInfo && this.props.user.planInfo.limits.dependants > 0,
      profilePicture: this.props.user.imageUrl
    };
  }

  componentDidMount() {
    let enrolleeId;
    if (features.enrolleeService.usage) {
        const {user : {id} } = this.props;
        enrolleeId = id;
    }
    userService.getUsageData(enrolleeId).then(data => {
      this.setState({
        usageData: data,
      });
    });

    if (features.enrolleeService.profile) {
      const { user } = this.props;
      userService.downloadImage(user.imageUrl).then(data => {
        this.setState({
          profilePicture: data
        });
      });
    }
  }

  addDependants = () => this.setState({ addDependants: true });

  render() {
    const { usageData, addDependants, hasDependants, profilePicture } = this.state;
    const { user } = this.props;

    if (_.isEmpty(user)) return <LoadingScreen />;

    if (addDependants) {
      return (
        <Redirect
          push
          to={{
            pathname: `/profile`,
            state: {
              addDependants: true,
            },
          }}
        />
      );
    }

    return (
      <MainContent>
        <PageHeader title="Dashboard" />
        <PageBody>
          <ButtonBar
            main={{ name: 'Consult a Doctor', func: () => this.props.history.push('/consultations') }}
            other={hasDependants && { name: 'Add Family Members', func: this.addDependants }}
          />

          <div className="dashboard-refer-banner">
            <button className="dashboard-refer-banner__button" onClick={() => this.props.history.push('/refer')}>
              <i className="fa fa-info-circle"></i> Invite friends, earn money! Tap here
            </button>
          </div>

          <DashboardProfileCard image={profilePicture} details={getUserProfileDetails(user)} />

          <div className="dashboard-card contact-us">
            <h2>
              If you have any problems at the hospital, please contact us via{' '}
              <a href="mailto:hello@reliancehmo.com">hello@reliancehmo.com</a> or <a href="tel:070073542623">0700-7354-2623</a>, and we will
              fix it immediately.
            </h2>
          </div>

          <div className="stats-data-wrap">
            <StatsDataCard name="Tips Read" data={usageData.tipsRead} iconClassName="fa-info-circle" />
            <StatsDataCard name="Questions Asked" data={usageData.questionsAsked} iconClassName="fa-question-circle" />
            <StatsDataCard name="Hospital Visits" data={usageData.hospitalVisits} iconClassName="fa-hospital" />
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

function getUserProfileDetails(user) {
  const groupTwo = features.enrolleeService.view
    ? [
        {
          name: 'Plan Info',
          value: user.product ? user.product.name : 'Loading...',
        },
        {
          name: 'Expires On',
          value: user.subscriptionEndDate ? moment(user.subscriptionEndDate).format('ddd, MMMM Do YYYY') : 'Loading...',
        },
      ]
    : [
        {
          name: 'Plan Info',
          value: user.planInfo ? `${user.planInfo.product.name}` : `${user.plan} ${user.planType}`,
        },
        {
          name: 'Expires On',
          value: user.planInfo ? moment(user.planInfo.cover_end_date).format('ddd, MMMM Do YYYY') : 'Loading...',
        },
      ];
  return {
    groupOne: [
      {
        name: 'Enrollee Name',
        value: `${user.firstName} ${user.lastName}`,
      },
      {
        name: 'HMO ID',
        value: user.hmoId,
      },
    ],
    groupTwo,
  };
}

export default withRouter(DashboardHome);
