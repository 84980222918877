/**
 * Created by the.last.mayaki on 26/10/2017.
 */
import {PLANNER_ACTION_TYPES} from '../actions/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({isFetching: false, preferences: {}, periodData: [], error: {}, message: null, meta:{}});
export default function plannerReducer(state = initialState, action) {
  switch (action.type) {
    case PLANNER_ACTION_TYPES.SET_UP_PLANNER:
    case PLANNER_ACTION_TYPES.GET_PLANNER:
      return Immutable(state).merge({
        isFetching: true,
        error: {},
        message: null,
        meta:{}
      });
    case PLANNER_ACTION_TYPES.SET_UP_PLANNER_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        message: action.data.extra_message || "Congratulations! Your cycle planner has been set up successfully. Please refresh the page to continue.",
        error: {},
        meta: {refresh: true}
      });
    case PLANNER_ACTION_TYPES.GET_PLANNER_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        preferences: action.data.preferences,
        periodData: action.data.eventList,
        error: {},
        message: null,
        meta:{}
      });
    case PLANNER_ACTION_TYPES.UPDATE_PLANNER_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        message: "Your cycle planner has been updated successfully. Please refresh the page to continue.",
        error: {},
        meta: {refresh: true}
      });
    case PLANNER_ACTION_TYPES.RESET_PLANNER_SUCCESS:
      return Immutable(state).merge({
        isFetching: false,
        message: "Your cycle planner has been reset. Please refresh the page to continue.",
        periodData:[],
        error: {}
      });
    case PLANNER_ACTION_TYPES.SET_UP_PLANNER_FAIL:
    case PLANNER_ACTION_TYPES.GET_PLANNER_FAIL:
    case PLANNER_ACTION_TYPES.UPDATE_PLANNER_FAIL:
    case PLANNER_ACTION_TYPES.RESET_PLANNER_FAIL:
      return Immutable(state).merge({
        isFetching: false,
        message: null,
        error: action.data,
        meta:{}
      });
    case PLANNER_ACTION_TYPES.CLEAR_ERROR:
      return Immutable(state).merge({
        isFetching: false,
        error: {},
        meta:{}
      });
    default:
      return state;
  }
}