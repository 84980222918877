import firebase from 'firebase/app';
import 'firebase/messaging';
import apiService from './services/ApiService';

// p.s this config object is non-secret and safe for public view
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID
}

const initFirebase = () => {
  if (firebase.messaging.isSupported()) {
    firebase.initializeApp(firebaseConfig);

    const messaging = firebase.messaging();

    const requestNotificationPermission = () =>
      new Promise((resolve, reject) => {
        messaging
          .requestPermission()
          .then(() => messaging.getToken())
          .then((firebaseToken) => {
            resolve(firebaseToken);
          })
          .catch((err) => {
            reject(err);
          });
      });

    requestNotificationPermission()
      .then((firebaseToken) => {
        return apiService.post('/accounts/fcm-token', {new_fcm_token: firebaseToken})
      })
      .then(response => console.log(response.data.message))
      .catch((error) => {
        console.log(error);
      });
  }
}

export default initFirebase;
