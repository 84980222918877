import React from 'react';
import TestForm from '../forms/TestForm';

class TestComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null
    }
  };


  onSubmit = (values) => {
    console.log(values);
  };


  render() {
    const {status} = this.state;

    if (status)
      return (
        <div className="container">
          <h1>{status.message}</h1>
        </div>
      );
    return (
      <div>
        <TestForm onSubmit={this.onSubmit}/>
      </div>
    )

  }

}

export default TestComponent
