import React from 'react';
import ButtonBar from '../components/boxes/ButtonBar';
import MainContent from '../components/boxes/MainContent';
import PageBody from '../components/boxes/PageBody';
import PageHeader from '../components/boxes/PageHeader';
import copyIcon from '../img/link.svg';
import whatsappIcon from '../img/whatsapp.svg';
import facebookIcon from '../img/facebook.svg';
import twitterIcon from '../img/twitter.svg';
import CopyToClipboardButton from '../components/utils/CopyToClipboardButton';
import ReferPhoneNumbersForm from './forms/ReferPhoneNumbersForm';
import LoadingScreen from './LoadingScreen';

const SOCIAL_MEDIA_MESSAGES = {
  whatsapp: "I have a gift for you: 1 month of free health insurance! I’ve been enjoying Reliance HMO's health insurance for some time now so I got it for you too. If you sign up with my referral link, you get 1 month free. I am sure you will like it. Click the link to get your gift:",
  facebook: "I stopped paying hospital bills when I started using Reliance HMO health insurance. I am giving away 1 month of free health insurance. Sign up with my link to get free healthcare",
  twitter: "I'm giving out 1 month of free health insurance with Reliance HMO. I like my insurance plan with them and I want you to try Reliance HMO. You'll love them. Sign up with my link"
}

export default function ReferSharePage({shareUrl, viewEarnings, viewReferrals, sendToPhoneNumbers, textMessageStatus}) {
  return (
    <MainContent>
      <PageHeader title="Refer and earn" />
      <PageBody>
        {
          shareUrl ?
            <>
            <ButtonBar
              main={{ name: 'View earnings', func: viewEarnings }}
              other={{ name: 'View referrals', func: viewReferrals }} />
            
            <div className="dashboard-card">
              <div className="refer-dashboard-section refer-dashboard-intro">
                <h2 className="dashboard-card__title-text">Gift free heath insurance, get &#8358;500</h2>
                <p className="dashboard-card__body-text">
                  Give 1 month of free health insurance to your friends and family &amp; get &#8358;500 back when they become paying subscribers
                </p>
              </div>
              <div className="refer-dashboard-section refer-dashboard-phone-numbers">
                <h3 className="dashboard-card__body-text--bold">Phone number</h3>
                <p className="dashboard-card__body-text">You can add up to 100 phone numbers. Separate each phone number with a comma.</p>
                <p className="dashboard-card__body-text">Use the format <b>080xxxxxxxx, 070xxxxxxxx, 081xxxxxxxx</b></p>
                <ReferPhoneNumbersForm onSubmit={sendToPhoneNumbers} status={textMessageStatus} />
              </div>
              <div className="refer-dashboard-section refer-dashboard-share-links">
                <h3 className="dashboard-card__body-text--bold">Share link</h3>
                
                <CopyToClipboardButton value={decodeURIComponent(shareUrl)  ?? "https://rel.hm"} className="dashboard-refer__secondary-btn">
                  <img src={copyIcon} alt="" /> Click to copy link
                </CopyToClipboardButton>
                <a 
                  href={`whatsapp://send?text=${SOCIAL_MEDIA_MESSAGES.whatsapp} ${shareUrl ?? "https://rel.hm"}`} 
                  target="_blank" rel="noreferrer noopener" className="dashboard-refer__secondary-btn">
                    <img src={whatsappIcon} alt="" /> Share to WhatsApp</a>
                <a 
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl ?? "https://rel.hm"}&quote=${SOCIAL_MEDIA_MESSAGES.facebook}`} 
                  target="_blank" rel="noreferrer noopener" className="dashboard-refer__secondary-btn">
                    <img src={facebookIcon} alt="" /> Share to Facebook</a>
                <a href={`https://twitter.com/intent/tweet?text=${SOCIAL_MEDIA_MESSAGES.twitter}&url=${shareUrl ?? "https://rel.hm"}`}
                  target="_blank" rel="noreferrer noopener" className="dashboard-refer__secondary-btn">
                    <img src={twitterIcon} alt="" /> Share to Twitter</a>
              </div>
            </div>
            </>
          :
            <LoadingScreen />
        }
      </PageBody>
    </MainContent>
  );
}