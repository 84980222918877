import React from 'react';
import moment from 'moment';

const MediaData = (props) => {
  return (
    <React.Fragment>
      <div style={{display: "flex"}}>
        <div data-tooltip="Click here to select a Pharmacy" 
        >
          <i 
            className="fas fa-prescription-bottle-alt" 
            style={{ cursor: "pointer", fontSize: "20px"}} 
            onClick={props.togglePharmacyModal(props.pharmacyModalName)}
          >
          </i>
        </div>
        
        <div 
          style={{fontSize: "1.3rem"}}
        >
          <p  
          > {props.message}</p>
          <p>{moment(props.created_at).format('HH:mm')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MediaData;