import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import {ApiService, ENDPOINTS} from "../../services/ApiService";
import MainContent from '../boxes/MainContent';
import PageHeader from '../boxes/PageHeader';
import PageBody from '../boxes/PageBody';
import FilterableMultiSelect from '../inputs/FilterableMultiSelect';
import Modal from '../boxes/Modal';
import PreCheckNewConsultation from './PreCheckNewConsultationForm';
import LoadingScreen from '../LoadingScreen';

class PreConsultationForm extends Component {
  constructor(props){
    super(props);

    this.service = new ApiService(props.user.credentials.accessToken);
    
    this.state = {
      dependants: [],
      patientId: "",
      mediumId: "1",
      content: "",
      allComplaints: [],
      selectedPrimaryComplaintIDs: null,
      showPreCheckModal: false,
      error: null
    };
  }

  componentDidMount() {
    this.getProfileDetails();
    this.getAllComplaints();
  }

  getProfileDetails = () => {
    this.service.get(ENDPOINTS.profile.profileDetails).then((response) => {
      this.setState({
        dependants: response.data.dependants,
        phoneNumber: response.data.phone_number,
        address: response.data.address
      })
    })
    .catch((error) => console.log(error))
  }

  getAllComplaints = () => {
    this.service.get(ENDPOINTS.consultations.complaints)
      .then(response => {
        this.setState({
          allComplaints: response.data
        })
      })
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  handleMultiSelectChange = (selectedComplaints) => {
    const complaintIds = selectedComplaints.reduce((a, c) => {
      a.push(c.id);
      return a;
    }, []);

    const reason = selectedComplaints.reduce((a, c, index) => {
      if (index + 1 === selectedComplaints.length) {
        return a + c.name
      }
      return a + c.name + ', ';
    }, '');

    this.setState({
      selectedPrimaryComplaintIDs: complaintIds,
      content: reason
    })
  }

  createNewConsultation = () => {
    const { patientId, mediumId, content, selectedPrimaryComplaintIDs } = this.state;

    const data = { 
      medium_id: mediumId,
      patient_id: patientId, 
      reason: content,
      primary_complaints: selectedPrimaryComplaintIDs
    };

    this.setState({
      isLoading: true,
      showPreCheckModal: false
    });

    this.service.post(ENDPOINTS.consultations.consultation, data)
      .then((response) => {
        this.setState({isLoading: false});
        this.props.history.push(`/consultations/${response.data.consultation_id}/messages`)
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          error: err.message
        })
      })
  }

  updateContactAndProceed = (phoneNumber, address) => {
    this.setState({
      isLoading: true,
      showPreCheckModal: false
    })
    const data = {
      home_phone_number: phoneNumber,
      home_address: address
    }
    this.service.patch(ENDPOINTS.profile.profileDetails, data)
      .then(() => {
        this.createNewConsultation()
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          error: err.message
        })
      })
  }

  preCheckNewConsultation = (event) => {
    event.preventDefault();

    if (this.state.mediumId === '2') {
      this.setState({showPreCheckModal: true})
    } else {
      this.createNewConsultation();
    }
  }

  renderPreCheckModal = () => {
    const { phoneNumber, address } = this.state;
    return (
      <Modal
        title="Please confirm that your phone number and address are up to date"
        onClose={() => this.setState({showPreCheckModal: false})}>
          <PreCheckNewConsultation
            phoneNumber={phoneNumber}
            address={address}
            onContinueConsultation={this.createNewConsultation}
            onUpdateContact={this.updateContactAndProceed}/>
      </Modal>
    )
  }

  render() {
    const { dependants, allComplaints, showPreCheckModal, error } = this.state;
    const { user } = this.props;

    const accountOwner = (
      <option key={user.id} value={user.id}>Myself</option>
    );

    const displayDependants = dependants.map((dependant) => (
      <option key={dependant.user_id} value={dependant.user_id}>{`${dependant.first_name} ${dependant.last_name}`}</option>
    ))

    displayDependants.unshift(accountOwner);

    return (
      <>
        {showPreCheckModal && this.renderPreCheckModal()}
        <MainContent>
          <PageHeader title="Consult a Doctor"/>
          {this.state.isLoading ? (
              <LoadingScreen /> 
            ) : (
              <PageBody>
                { error &&
                  <div className={'page-info-banner error'}>
                    <h3>{error}</h3>
                  </div>
                }
                <div className="dashboard-card">
                  <form className="dashboard-form" 
                    onSubmit={this.preCheckNewConsultation}
                  >
                    <div className="dashboard-form-item-wrapper">
                      <div className="dashboard-input-wrap profile-input-wrap">
                        <span className="dashboard-form-item">
                          <label 
                            className="profile-item__label" 
                            htmlFor="">
                            How would you like to Consult a Doctor?
                          </label>

                          <select 
                            name="mediumId"
                            className="dashboard-form__input dashboard-form__select" 
                            onChange={this.handleInputChange}
                            value={this.state.mediumId}
                          >
                            <option value={1}>Chat with Doctor</option>
                            <option value={2}>Phone Call</option>
                          </select>
                        </span>

                        <span className="dashboard-form-item">
                          <label 
                            className="profile-item__label" 
                            htmlFor="">
                            Who are you consulting the doctor for?
                          </label>
                          <select 
                            name="patientId"
                            className="dashboard-form__input dashboard-form__select"
                            onChange={this.handleInputChange}
                            value={this.state.patientId}
                          >
                            {
                              displayDependants
                            }
                          </select>
                        </span>

                        <span className="dashboard-form-item">
                          <label className="profile-item__label" htmlFor="">
                            Why are you consulting the doctor?
                          </label>

                          <FilterableMultiSelect 
                            items={allComplaints}
                            filterBy={['name']}
                            input={{
                              value: [],
                              onChange: this.handleMultiSelectChange
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <button className="dashboard__primary-btn dashboard-form__btn" style={{marginTop: "2rem"}}>Submit</button>
                  </form>
                </div>
              </PageBody>
            )
          }
        </MainContent>
      </>
    );
  }
}

PreConsultationForm = reduxForm({
  form:"preConsultationForm"
})(PreConsultationForm);

export default PreConsultationForm;