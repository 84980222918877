import React from 'react';

export default function StatsDataCard({name, data, iconClassName}) {
	return (
    <div className="dashboard-card stats-data-card">
			<div className="dashboard-card__text--left-align">
				<p className="dashboard-card__title-text">{name}</p>
				<p className="dashboard-card__data-text">{data}</p>
			</div>
			<i className={`fas ${iconClassName} fa-3x stats-data-icon`}></i>
		</div>
	)
}