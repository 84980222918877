import React from 'react';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';

class CopyToClipboardButton extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      copying:false
    }
  }

  onCopyToClipboard = () => {
    copy(this.props.value);
    this.setState({
      copying:true
    });
    setTimeout(()=>{
      this.setState({
        copying:false
      });
    },500);
  };

  render(){
    const {children, className} = this.props;
    const {copying} = this.state;
    return (
      <button
        className={`${className} ${copying ? 'green' : ''} `}
        onClick={this.onCopyToClipboard}>
        {copying ? "Copied!" : children}
      </button>
    )
  }
}

CopyToClipboardButton.propTypes = {
  value: PropTypes.string.isRequired,
};



export default CopyToClipboardButton;