import {DISPLAY_ACTION_TYPES} from '../actions/actionTypes';

export default function displayReducer(state={showSideBar:false},action){
    switch (action.type){
        case DISPLAY_ACTION_TYPES.TOGGLE_SIDEBAR:
            return {showSideBar:!state.showSideBar};
        case DISPLAY_ACTION_TYPES.HIDE_SIDEBAR:
            return {showSideBar:false};
        default:
            return state;
    }

}