import React from 'react';
import {fetchTipIfNeeded} from '../actions/tip';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import nl2br from 'react-nl2br';
import MainContent from '../components/boxes/MainContent';
import PageBody from '../components/boxes/PageBody';
import PageHeader from '../components/boxes/PageHeader';
import LoadingScreen from '../components/LoadingScreen';

class TipPage extends React.Component {


  componentDidMount() {
    const {dispatch, match, user} = this.props;
    dispatch(fetchTipIfNeeded(match.params.id, user.credentials.accessToken));
  }

  render() {
    const {selectedTip, history} = this.props;
    return (
      (
        (selectedTip ? (
          <MainContent>
            <PageHeader title="Health Tips" isSearchable={false}/>
            <PageBody>
              <div className="dashboard-card">
                <div className="article-wrapper">
                  <h2 className="article__title">{selectedTip.title}</h2>
                  <div className="article__text">
                    <img src={selectedTip.imageUrl || "https://placehold.it/600x400"} alt={selectedTip.title}/>
                    <p>
                      {nl2br(selectedTip.content)}
                    </p>
                  </div>
                  <button className="dashboard__primary-btn article__btn" onClick={history.goBack}>
                    <p>Back</p>
                  </button>
                </div>
              </div>
            </PageBody>
          </MainContent>) : (
          <MainContent>
            <LoadingScreen/>
          </MainContent>
        ) )

      )
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedTip: state.tips.selectedTip
  };
};

export default withRouter(connect(mapStateToProps)(TipPage));