import React from 'react';
import { CONSULTATION_STATUS } from '../../services/utilityService';

export default function DoctorInfoBanner({doctor, nextModal, onClick, consultationStatus, toggleFollowUpConsultationModal}) {
  if(doctor){
    return (
      <div className="chat-doctor-wrapper" >
        <div className="chat-doctor-info">
            <img className="chat-doctor__img" src={doctor.profile_picture || 'https://via.placeholder.com/150'} alt={`Dr. ${doctor.firstName} ${doctor.lastName}\``} />
            <div style={{marginLeft: "2rem"}}>
                <p className="chat-doctor-name">{`Dr. ${doctor.first_name} ${doctor.last_name}`}</p>
                <p className="chat-doctor-prompt" onClick={() => onClick(nextModal, {selectedDoctor: doctor})}>
                  <u>Click to View Doctor's Profile</u>
                </p>
            </div>
        </div>
        {
          consultationStatus === CONSULTATION_STATUS.closed &&
          <button className="dashboard__primary-btn" onClick={toggleFollowUpConsultationModal}>Request Follow-up Consultation</button>
        }
      </div>
    )
  }else{
    return null;
  }
};