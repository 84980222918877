import React, { Component } from 'react';
import MainContent from './boxes/MainContent';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';

class DoctorProfile extends Component {
  render () {
    return (
      <MainContent>
        <PageHeader/>
        <PageBody>
          <div className="dashboard-btn-section">
            <button className="dashboard__primary-btn">
              Change Doctor
            </button>

            <button className="dashboard__secondary-btn" style={{marginLeft: "2rem"}}>
              <a href="/chat">Back to chat</a>
            </button>
          </div>
          <div className="dashboard-card profile-section">
            <img alt="doctor" className="profile__img" src="img/sample.png"/>

            <div className="profile-detail-wrap">
              <div className="profile-item">
                <div className="profile-item-wrapper">
                  <p className="profile-item__label">Name</p>
                  <p className="profile-item__text">Dr. Akachi O
                  <span style={{fontSize: "1.1rem", color: "#4f4f4f"}}>MBBS</span> </p>
                </div>
                <div className="profile-item-wrapper">
                  <p className="profile-item__label">Speciality</p>
                  <p className="profile-item__text">Obstetrics and Gynaecology</p>
                </div>
              </div>
            </div>
          </div>
        </PageBody>
      </MainContent>
    );
  }
}

export default DoctorProfile;