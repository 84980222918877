import React from 'react';
import * as _ from 'lodash';
import NewPasswordForm from '../components/forms/NewPasswordForm';
import {withRouter} from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import {connect} from 'react-redux';

class ResetPasswordContainer extends React.Component{

    renderLoading(){
        return (
            <LoadingScreen/>
        )
    };


    onFormSubmit = (values,dispatch,props) => {
        //send password stuff
    };

    renderSetNewPassword(){
        const {error, isLoading} = this.props;
        return (
            <FormHolder message={error.message} isLoading={isLoading}>
                <NewPasswordForm onSubmit={this.onFormSubmit}/>
            </FormHolder>
        )
    }

    renderPasswordResetSuccessfulMessage = () => {
        return (
            <FormHolder>
                <MessageHolder>
                    <h3>Your password has been changed.</h3>
                    <p>
                        You have successfully changed your password. Please remember to keep your passwords safe and secure.
                        <br/>
                        Thanks for choosing RelianceHMO.
                        <br/>
                        <button className="primary_button">
                            <a href="/login">Click here to login</a>
                        </button>
                    </p>
                </MessageHolder>
            </FormHolder>
        )
    };

    render() {
        const {isLoading, message} = this.props;

        if (isLoading) return this.renderLoading();

        if(!_.isEmpty(message)) return this.renderPasswordResetSuccessfulMessage();

        return this.renderSetNewPassword();
    }
}



const FormHolder = (props) => {
    return (
        <div className="container">
            <div className="login_form">
                <div style={{padding:"10px",backgroundColor:"red",color:"white",visibility:(!props.isLoading && props.message)?"visible":"hidden"}}>
                    {props.message}
                </div>
                <div className="form_wrapper">

                    {props.children}

                </div>
            </div>
        </div>
    )
};


const MessageHolder = (props) => {
    return (
        <div style={{padding:"10px",margin:"10px",backgroundColor:"white",color:"#111"}}>
            <div>
                {props.children}
            </div>
        </div>
    )
};


function mapStateToProps(state){
    const { isLoading, error, message } = state.user||{};
    return {isLoading, error, message};
}

export default withRouter(connect(mapStateToProps)(ResetPasswordContainer));