import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, change} from 'redux-form';
import DateTime from 'react-datetime';
import moment from 'moment';
import './datepicker.css';
import PropTypes from 'prop-types';
import providerService from '../../services/providerService';
import FilterableSelect from '../inputs/FilterableSelect';
import LoadingScreen from '../LoadingScreen';

class SecondaryCareRequestForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading:true,
      providers:null
    }
  }

  componentDidMount(){
    const {accessToken,typeId} = this.props;
    providerService.getProviders(accessToken,typeId)
      .then(providers=>{
        this.setState({
          providers,
          isLoading:false
        })
      })
  }

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('secondaryCareRequestForm', 'date_time', moment(date).format("YYYY-MM-DD HH:MM")));
  };

  render(){

    const {handleSubmit, pristine, submitting, careName} = this.props;
    const {isLoading, providers} = this.state;

    if(isLoading) return (
      <div style={{ position:"relative",width:"100%",height:"100px", minWidth:"200px"}}>
        <LoadingScreen/>
      </div>
    );

    return (
      <form  className="form" onSubmit={handleSubmit} style={{display:"flex", alignItems:"center",flexDirection:"column"}}>
        <span style={{fontSize:"1.5rem"}}>
          What {careName} would you like to go to?
          <Field
            name="provider_id"
            className="form-input"
            component={FilterableSelect}
            props={
              {
                items:providers,
                placeholder:"Search by name, address or state",
                filterBy:["name","address","state.name"],
                extraKey:"address"
              }
            }/>
          <Field component="input" type="hidden" name="date_time" placeholder="Date Time" required/>
          <DateTime
            closeOnSelect
            inputProps={{required: true, placeholder: "When would you like to go?", readOnly: true}}
            onChange={this.onDateSet}
            isValidDate={(curr) => curr.dayOfYear() >= DateTime.moment().dayOfYear()}
          />
        </span>
        <button className="form-button primary_button" disabled={pristine||submitting} style={{marginTop:"3rem"}}>
          {careName.toLowerCase() === "gym" ? "GENERATE PACODE" : "MAKE REQUEST"}
        </button>
      </form>
    )
  }

}


SecondaryCareRequestForm.propTypes = {
  careName: PropTypes.string,
  accessToken: PropTypes.string,
  typeId: PropTypes.number,
  onSubmit: PropTypes.func,
};


SecondaryCareRequestForm = reduxForm({
  form: "secondaryCareRequestForm"
})(SecondaryCareRequestForm);

const selector = formValueSelector('secondaryCareRequestForm');
SecondaryCareRequestForm = connect(state => {
  // can select values individually
  const dateTime = selector(state, 'date_time');
  return {
    dateTime
  }
})(SecondaryCareRequestForm);


export default SecondaryCareRequestForm;