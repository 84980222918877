import React from 'react';
import {Redirect} from 'react-router';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

class TipItem extends React.Component{

    constructor(props){
        super(props);
        this.state = {redirect:false}
    }

    handleClick = () =>{
        this.setState({redirect:true});
    };

    render() {
        if(this.state.redirect)
            return <Redirect push to={`/tips/${this.props.id}/${this.props.title.trim().replace(/\s/g,'-')}`} />;

        return (
            <div className="dashboard-card tips" onClick={this.handleClick} style={{pointer:"cursor"}}>
                <img className="tips__image" src={this.props.imageUrl} alt={this.props.title}/>
                <h2 className="tips__title">{this.props.title}</h2>
                <p className="tips__preview">{this.props.summary}</p>
                <p className="tips__time-date-stamp">{moment(this.props.createdAt).format('Do MMM YYYY')}</p>
            </div>
        )
    }
}


export default withRouter(TipItem)