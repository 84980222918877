import * as React from 'react';
import RightChevron from '../../img/chevron_left.svg';
import LeftChevron from '../../img/chevron_right.svg';

export default function Paginate({current, last, onChange}) {
  return (
    <div className='pagination__container'>
      {<button className='pagination__btn' disabled={current === 1} onClick={()=>onChange(current-1)}><img src={RightChevron} alt='previous' /></button>}
      <React.Fragment>
        {current !== 1 && <button onClick={()=>onChange(1)}>1</button>}

        {current-3 > 1 && <p>...</p>}

        {current-2 > 1 && <button onClick={()=>onChange(current-2)}>{current-2}</button>}
        {current-1 > 1 && <button onClick={()=>onChange(current-1)}>{current-1}</button>}

        <button className="pagination__btn_current current">{current}</button>

        {current+1 < last && <button onClick={()=>onChange(current+1)}>{current+1}</button>}
        {current+2 < last && <button onClick={()=>onChange(current+2)}>{current+2}</button>}

        {current+3 < last && <p disabled>...</p>}

        {current !== last && <button onClick={()=>onChange(last)}>{last}</button>}
      </React.Fragment>
      <button className='pagination__btn' disabled={current === last} onClick={()=>onChange(current+1)}><img src={LeftChevron} alt='next' /></button>
    </div>
  );
}