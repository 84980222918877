import React from 'react';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from 'react-router-dom';
import * as userActions from '../actions/user';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import Tab from '../components/boxes/Tab';
import MainContent from '../components/boxes/MainContent';
import LoadingScreen from '../components/LoadingScreen';
import CenteredContentBlock from '../components/boxes/CenteredContentBlock';
import ButtonBar from "../components/boxes/ButtonBar";

class BenefitsContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedTab:0,
            searchInput: ""
        }
    }

    componentDidMount(){
        const {dispatch, user} = this.props;
        dispatch(userActions.fetchBenefits(user.credentials.accessToken));
    }

    selectTab = (index) => {
        this.setState({
            selectedTab:index
        })
    };

    renderLoading(){
        return (
            <MainContent>
                <LoadingScreen/>
            </MainContent>
        )
    };

    handleSearch = (event) => {
        this.setState({
            searchInput: event.target.value.substr(0, 10)
        });
    }

    renderContent(){
        const { benefits, user } = this.props;
        const { selectedTab, searchInput } = this.state;

        let filteredBenefits = benefits;
        
        if (searchInput) {
            filteredBenefits = filteredBenefits.filter((benefit) => {
                return benefit.benefit_name.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
            });
        }

        const availableNowBenefits = filteredBenefits.filter(benefit=>benefit.is_available)

        const availableLaterBenefits = filteredBenefits.filter(benefit=>!benefit.is_available)

        return (
            <MainContent>
                <PageHeader
                  title="My Benefits"
                  searchBarPlaceholder="Search Benefits..."
                  showSearchBar
                  handleSearch={this.handleSearch}
                />
                <PageBody>
                    <ButtonBar main={{
                        name: 'Access Secondary Care', 
                        func: () => ( this.props.history.push('/benefits/secondary-care')) 
                        }}/>
                    <div className="dashboard-card dashboard-table-wrap">
                        <div className="dashboard-table-overflow">
                            {user.package.toLowerCase() === "retail" ? (
                                    searchInput.length === 0 ?
                                        <React.Fragment>
                                            <div className="dashboard-btn-section dashboard-tab"  style={{flexWrap: "wrap"}}>
                                                <Tab
                                                    title={`AVAILABLE NOW(${availableNowBenefits.length})`}
                                                    index={0}
                                                    isActive={selectedTab === 0}
                                                    onClick={this.selectTab}
                                                />
                                                <Tab
                                                    title={`AVAILABLE LATER(${availableLaterBenefits.length})`}
                                                    index={1}
                                                    isActive={selectedTab === 1}
                                                    onClick={this.selectTab}
                                                />
                                            </div>
                                            <React.Fragment>
                                                {selectedTab === 0 && <BenefitsTable benefits={availableNowBenefits}/>}
                                                {selectedTab === 1 && <BenefitsTable benefits={availableLaterBenefits}/>}
                                            </React.Fragment>
                                        </React.Fragment>                                        
                                        :
                                        <BenefitsTable 
                                            benefits={filteredBenefits} 
                                        />
                                ) : (
                                    <BenefitsTable benefits={filteredBenefits}/>
                            )}
                        </div>
                    </div>
                </PageBody>
            </MainContent>
        )
    }

    render(){
        const { benefits, isFetching } = this.props;

        if(!isFetching && !_.isEmpty(benefits)) return this.renderContent();

        return this.renderLoading(); 
    }
}

const BenefitsTable = (props) => {
    const { benefits, style } = props;

    return (
        benefits.length === 0 ?
            <CenteredContentBlock>
                    No result found here.
            </CenteredContentBlock>
            :
            <table 
                className="dashboard-table"
                style={style}
            >
                <thead>
                <tr>
                    <th className="dashboard-table-item dashboard-table-item__header">BENEFIT</th>
                    <th className="dashboard-table-item dashboard-table-item__header">STATUS</th>
                </tr>
                </thead>
                <tbody>
                {benefits.map((benefit,index)=>(
                    <tr key={index}>
                        <td className="dashboard-table-item dashboard-table-item__body">{benefit.benefit_name}</td>
                        <td className="dashboard-table-item dashboard-table-item__body">{benefit.benefit_value}</td>
                    </tr>
                ))}
                </tbody>

            </table>
    )
};

function mapStateToProps(state) {
    let {isFetching, error} = state.user || {};
    let {benefits} = state.user.meta || {};
    return { benefits, isFetching, error };
}

export default withRouter(connect(mapStateToProps)(BenefitsContainer));