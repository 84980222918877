import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import initFirebase from './firebaseInit';
import SignUpContainer from './containers/SignUpContainer';
import DoctorSignUpContainer from './containers/DoctorSignUpContainer';
import LoginContainer from './containers/LoginContainer';
import Dashboard from './containers/Dashboard';
import TestComponent from './components/boxes/TestComponent';
import './App.css';
import './index.css';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import PlanExpiredPage from './components/PlanExpiredPage';
import PaymentSuccessfulPage from './components/PaymentSuccessfulPage';
import LogoutContainer from './containers/LogoutContainer';
import { withUser } from './components/utils/AuthHOC';
import ReferContainer from './containers/ReferContainer';

const App = () => {
    if (!process.env.DISABLE_FIREBASE) {
        initFirebase();
    }
    return (
        <Router history="">
            <div className="content dash">
                <Switch>
                    <Route component={LoginContainer} path="/login" exact />
                    <Route component={SignUpContainer} path="/register" exact />
                    <Route component={DoctorSignUpContainer} path="/doctor-signup" exact />
                    <Route component={ResetPasswordContainer} path="/reset-password" exact />
                    <Route component={TestComponent} path="/test" exact />
                    <Route component={PlanExpiredPage} path="/plan-expired" exact />
                    <Route component={PaymentSuccessfulPage} path="/payment-successful" exact />
                    <Route component={LogoutContainer} path="/logout" exact />
                    <Route component={withUser(ReferContainer)} path="/refer" />
                    <Route component={withUser(Dashboard)} path="/" />
                </Switch>
            </div>
        </Router>
    );
};

export default App;
