import React from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import USER_STUB from '../../img/user_stub.jpg';
import Modal from "../boxes/Modal";

const defaultButtonStyle={
  width:"100%",
  margin:"20px 0px",
  border:"1px solid #C0E5E5",
  borderRadius:"4px",
  boxSizing:"border-box",
  padding:"5px",
  backgroundColor:"transparent",
  cursor:"pointer",
  color:"#004066"
};


class ImageInput extends React.Component{

  constructor(props){
    super(props);
    let placeHolder = USER_STUB;
    if(props.placeholder){
      if(props.placeholder instanceof File || props.placeholder instanceof Blob){
        this.setPlaceholderFromFile(props.placeholder);
      }
    }
    this.state= {
      scale:1,
      rawImage:null,
      rotate: 0,
      croppedImage:placeHolder,
      cropperOpen:false
    };
    this.croppedStuff = null;
  }

  setPlaceholderFromFile = (img) => {
    try{
      const reader  = new FileReader();

      reader.addEventListener("load", () =>  {
        this.setState({
          croppedImage:reader.result
        })
      }, false);

      if (img) {
        reader.readAsDataURL(img);
      }
    }catch (err){
      console.log(err);
    }
  };

  onCropComplete=(cb)=>{
    cb(this.state.croppedImage);
  };

  cancelCrop = () => {
    this.setState({
      cropperOpen:false
    });
    this.imageInput.value = null;
  };

  handleCrop = () => {

    if(this.croppedStuff){
      const output = this.croppedStuff.getImage();

      const imageUri =(output.toDataURL('image/jpeg'));

      const blobUri =  this.dataURItoBlob(imageUri);

      this.setState({
        rawImage:null,
        croppedImage:imageUri,
        cropperOpen:false
      });
      //set cropped file in preview
      //dispatch redux form set on fileInput
      this.props.onCropComplete(blobUri);
    }else{
      console.log("Ko le crop");
    }
  };

  onImageAdded = (imageUri) => {
    this.setState({
      cropperOpen:true,
      croppedImage:this.state.croppedImage,
      rawImage:imageUri.target.files[0]
    });
  };


  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale })
  };

  rotateLeft = e => {
    e.preventDefault();

    this.setState({
      rotate: this.state.rotate - 90
    })
  };

  rotateRight = e => {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90
    })
  };

  addPhoto = (e) => {
    e.preventDefault();
    this.imageInput.click();
  };


  dataURItoBlob = (dataURI) => {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  };

  render(){

    const {cropperOpen, croppedImage, rawImage} = this.state;
    const {asButton, buttonCaption, customClassName} = this.props;

    return (

      <div className={customClassName}>
          <input
            ref={(el)=>this.imageInput=el}
            type="file"
            accept="image/*"
            onChange={this.onImageAdded}
            style={{display:"none"}}
            required={this.props.required||false} />

        {cropperOpen &&
        <Modal title="Add Picture" onClose={this.cancelCrop}>
            <div>
                <ReactAvatarEditor
                  ref={(el)=>this.croppedStuff=el}
                  image={rawImage}
                  width={this.props.width || 150}
                  height={this.props.height || 150}
                  border={50}
                  style={{display:"block", margin:"0px auto"}}
                  rotate={parseFloat(this.state.rotate)}
                  color={[0, 0, 0, 0.6]} // RGBA
                  scale={parseFloat(this.state.scale)}
                />
                <br/>
                <div style={{textAlign:"center", padding:"1rem 2rem"}} >
                    <p>Zoom:</p>
                    <input
                      name='scale'
                      type='range'
                      onChange={this.handleScale}
                      min={this.state.allowZoomOut ? '0.1' : '1'}
                      max='2'
                      step='0.01'
                      style={{width:"100%", margin:"5px 0px"}}
                      defaultValue='1'
                    />
                    <br/>
                    <div style={{display:"flex",justifyContent:"space-around"}}>

                        <button
                          style={{float:"left",
                            border:"1px solid #C0E5E5",
                            borderRadius:"4px",
                            boxSizing:"border-box",
                            padding:"5px",
                            backgroundColor:"transparent",
                            width:"40%",
                            margin:"0 1rem",
                            color:"#004066"
                          }}

                          onClick={this.rotateLeft}>
                            <i className="fa fa-rotate-left"/>
                            &nbsp;&nbsp;Rotate Left
                        </button>
                        <button
                          style={{float:"right",
                            border:"1px solid #C0E5E5",
                            borderRadius:"4px",
                            boxSizing:"border-box",
                            padding:"5px",
                            backgroundColor:"transparent",
                            width:"40%",
                            margin:"0 1rem",
                            color:"#004066"
                          }}
                          onClick={this.rotateRight}>
                            <i className="fa fa-rotate-right"/>
                            &nbsp;&nbsp;Rotate Right
                        </button>
                    </div>

                    <br/>
                </div>
                <div>
                    <button
                      style={defaultButtonStyle}
                      onClick={this.handleCrop} type="button"> DONE </button>
                </div>
            </div>
        </Modal>
        }

        {!asButton && croppedImage && !cropperOpen &&
        <div style={{margin:"10px 0px"}}>
            <img
              style={{display:"block", margin:"0px auto"}}
              src={croppedImage}
              alt="Crop"
              width={this.props.width || 150}
              height={this.props.height || 150}
            />
        </div>
        }
          <button
            onClick={this.addPhoto}
            type="button"
            data-tooltip="Click here to upload an image">
              <i className="fa fa-camera" style={{color:"inherit"}}/>
              {(` ${buttonCaption || "Add Photo"}`)}
          </button>
      </div>
    );
  };
}

ImageInput.propTypes = {
  width:PropTypes.number,
  height:PropTypes.number,
  placeholder:PropTypes.any,
  onImageSet:PropTypes.func,
  asButton:PropTypes.bool,
  hideButtonCaption:PropTypes.bool,
  buttonCaption:PropTypes.string,
  buttonStyle: PropTypes.object,
  customClassName: PropTypes.string
};

export default ImageInput;


