import * as _ from 'lodash';
import moment from 'moment';
import querystring from 'querystring';
import axios from 'axios';
import {
    BASE_URL_RELIANCE,
    DOMAIN_COOKIE_NAME,
    getCookie,
    DAARA_TOKEN_NAME,
    DAARA_REFRESH_TOKEN,
    DAARA_ENROLLEE_SERVICE_URL,
    DAARA_ACCOUNT_SERVICE_URL,
    DAARA_FILE_SERVICE_URL
} from './utilityService';
import { features } from './daara-config.json';
import { sendHttpGetRequest, sendHttpPostRequest, sendHttpPutRequest } from './axiosInterceptor';

const BASE_URL = BASE_URL_RELIANCE;

class UserService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                DAARA_TOKEN: getCookie(DAARA_TOKEN_NAME)
            }
        });
        this.data = {};
        this.accessToken = getCookie(DOMAIN_COOKIE_NAME);
        this.daaraToken = getCookie(DAARA_TOKEN_NAME);
    }

    login(values) {
        return this.ax.post(`/enrollees/login`, values).then((response) => {
            return mapUser(response.data);
        });
    }

    auth() {
        let accessToken = getCookie(DOMAIN_COOKIE_NAME);
        let daaraToken = getCookie(DAARA_TOKEN_NAME);
        let refreshToken = getCookie(DAARA_REFRESH_TOKEN);
        if (features.enrolleeService.auth) {
            return sendHttpPostRequest(`${DAARA_ENROLLEE_SERVICE_URL}/auth`).then((response) => {
                let user = daaraMapUser(response.data);
                user.credentials.accessToken = accessToken;
                user.credentials.daaraToken = daaraToken;
                user.credentials.refreshToken = refreshToken;
                return user;
            });
        }
        return this.ax
            .post(`${BASE_URL_RELIANCE}/auth/enrollees`, null, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                let user = mapUser(response.data);
                user.credentials.accessToken = accessToken;
                user.credentials.daaraToken = daaraToken;
                user.credentials.refreshToken = refreshToken;
                return user;
            });
    }

    resetPassword(values) {
        this.data = querystring.stringify(values);
        return this.ax.post('/accounts/recover-password', this.data).then(() => {
            return true;
        });
    }

    changePassword(values) {
        if (features.accountService.changePassword) {
            const { confirm_new_password, new_password, old_password } = values;
            return sendHttpPostRequest(`${DAARA_ACCOUNT_SERVICE_URL}/change-password`, {
                oldPassword: old_password,
                newPassword: new_password,
                confirmPassword: confirm_new_password
            }).then(() => {
                return true;
            });
        }
        return this.ax
            .post(`/accounts/change-password`, values, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then(() => {
                return true;
            });
    }

    getProfile(accessToken, clientId) {
        if (features.enrolleeService.profile) {
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/profile/${clientId}`
            ).then((response) => daaraMapPrincipal(response.data));
        }
        return this.ax
            .get(`/enrollees/profile`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getBenefits() {
        if (features.enrolleeService.benefits) {
            return sendHttpGetRequest(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/benefits`).then(
                (response) => {
                    return response.data;
                }
            );
        }
        return this.ax
            .get(`enrollees/benefits`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getSecondaryCareBenefits() {
        return this.ax
            .get(`enrollees/secondary-care/all`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    requestSecondaryCare(benefitId, providerTypeId, dateTime) {
        this.data = {
            benefit_id: benefitId,
            provider_id: providerTypeId,
            dateTime: dateTime
        };
        return this.ax
            .post(`enrollees/secondary-care/request`, this.data, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return {
                    paCode: response.data.data ? response.data.data.pacode : null,
                    message: response.data.message
                };
            });
    }

    getPlanDetails() {
        return this.ax
            .get(`enrollees/subscription`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    renewPlan(invoiceRefCode) {
        return this.ax.get(`payment/manual/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }
    getUsageData() {
        return this.ax
            .get(`enrollees/usage`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                const summary = response.data.data.summary;
                return {
                    tipsRead: summary.total_tips_read,
                    questionsAsked: summary.total_questions_asked,
                    hospitalVisits: summary.total_hospital_visits
                };
            })
            .catch((error) => console.log(error));
    }

    resetPlan(invoiceRefCode) {
        return this.ax.post(`/retail/renew/${invoiceRefCode}`).then((response) => {
            return response.data.data;
        });
    }

    getUsageData(enrolleeId) {
        if (features.enrolleeService.usage) {
            return sendHttpGetRequest(
                `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${enrolleeId}/usage`
            ).then((response) => {
                const { totalQuestionAsked, totalTipsRead, totalHospitalVisits } = response.data;
                return {
                    tipsRead: totalTipsRead,
                    questionsAsked: totalQuestionAsked,
                    hospitalVisits: totalHospitalVisits
                };
            });
        }

        return this.ax
            .get(`enrollees/usage`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                const summary = response.data.data.summary;
                return {
                    tipsRead: summary.total_tips_read,
                    questionsAsked: summary.total_questions_asked,
                    hospitalVisits: summary.total_hospital_visits
                };
            });
    }

    sendReferralCodeToPhoneNumbers(phone_numbers) {
        return this.ax
            .post(
                '/enrollees/referral/send-sms',
                { phone_numbers },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getReferrals({ page, status, name }) {
        return this.ax
            .get(`/enrollees/referral?${querystring.stringify({ page, status, name })}`, {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    getReferralOverview() {
        return this.ax
            .get('/enrollees/referral/overview', {
                headers: {
                    Authorization: `Bearer ${this.accessToken}`
                }
            })
            .then((response) => {
                return response.data.data;
            });
    }

    withdrawCash(amount, bank_id, account_name, account_number) {
        return this.ax
            .post(
                '/enrollees/referral/cash-out',
                {
                    amount,
                    bank_id,
                    account_name,
                    account_number
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    getBanks() {
        return this.ax.get('/utility/banks').then((response) => {
            return response.data.data;
        });
    }

    verifyAccountNumber(bank_id, account_number) {
        return this.ax
            .post(
                '/verify/account-number',
                {
                    bank_id,
                    account_number
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                }
            )
            .then((response) => {
                return response.data.data;
            });
    }

    async unhashParams(hash) {
        if (hash) {
            this.data = {
                hash: hash
            };
            if (features.enrolleeService.unhash) {
                return axios
                    .get(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/unhash/${hash}`)
                    .then((response) => {
                        const { person } = response.data;
                        return {
                            email_address: person.email,
                            phone_number: person.phoneNumber,
                            first_name: person.firstName,
                            last_name: person.lastName,
                            id: response.data.id
                        };
                    });
            }
            return this.ax.post('/enrollees/unhash', this.data).then((response) => {
                return {
                    email_address: response.data.data.email_address,
                    phone_number: response.data.data.phone_number,
                    first_name: response.data.data.first_name,
                    last_name: response.data.data.last_name,
                    is_family_plan: response.data.data.is_family_plan,
                    company_name: response.data.data.company_name,
                    providers: response.data.data.providers
                };
            });
        } else {
            return null;
        }
    }

    async register(values, hash) {
        //delete access_token from post body
        delete values.access_token;
        if (features.enrolleeService.completeProfile) {
            let formValues = {
                id: values.id,
                firstName: values.first_name,
                lastName: values.last_name,
                email: values.email_address,
                phoneNumber: values.primary_phone_number,
                dateOfBirth: values.date_of_birth,
                hasSmartphone: values.has_smartphone === '1' ? true : false,
                address: values.home_address,
                stateId: values.home_address_state_id,
                gender: values.sex,
                maritalStatus: values.marital_status_id != 0 ? values.marital_status_id : null,
                preferredProviderId: values.preferred_provider_id
            };

            const formData = new FormData();
            formData.append(
                'enrolleeUpdate',
                new Blob([JSON.stringify(formValues)], { type: 'application/json' })
            );
            formData.append('profilePicture', values.profile_picture);

            return axios
                .put(`${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${hash}/profile`, formData)
                .then((response) => {
                    return response.data;
                });
        } else {
            //delete profile picture file
            delete values.profile_picture;
            return this.ax
                .post(`/enrollees/profile`, values, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                })
                .then((response) => {
                    return response.data;
                });
        }
    }

    async downloadImage(key, abortController) {
        try {
            const res = await sendHttpGetRequest(`${DAARA_FILE_SERVICE_URL}/image/${key}`, {
                responseType: 'blob',
                signal: abortController ? abortController.signal : null
            });
            let { data } = res;
            data.lastModifiedDate = new Date();
            data.name = key;
            const fileMimeType = `image/${key.substr(key.lastIndexOf('.') + 1)}`;
            data = data.slice(0, data.size, fileMimeType);
            return window.URL.createObjectURL(data);
        } catch (e) {
            console.error(e);
        }
    }
}

function daaraMapUser(json) {
    return {
        id: _.get(json, 'id'),
        hmoId: _.get(json, 'hmoId'),
        emailAddress: _.get(json, 'email'),
        firstName: _.get(json, 'firstName'),
        lastName: _.get(json, 'lastName'),
        sex: _.capitalize(_.get(json, 'gender')),
        otherNames: _.get(json, 'otherNames'),
        dob: _.get(json, 'dateOfBirth'),
        age: moment().diff(_.get(json, 'dateOfBirth'), 'years'),
        package: _.get(json, 'productSummaryDTO.type'),
        plan: _.get(json, 'productSummaryDTO.name'),
        planType: _.get(json, 'productSummaryDTO.planType'),
        userType: _.get(json, 'enrolleeType'),
        subscriptionEndDate: _.get(json, 'subscriptionEndDate'),
        imageUrl: _.get(json, 'profilePicture'),
        referralUrl: _.get(json, 'referral_url'),
        isLoggedIn: false,
        credentials: {
            accessToken: _.get(json, 'data.access_token'),
            daaraToken: _.get(json, 'data.daara_token'),
            fcmToken: '',
            kangpeToken: ''
        },
        preferences: {
            allowPushNotifications: true,
            showCyclePlanner: true
        }
    };
}

function mapUser(json) {
    return {
        id: _.get(json, 'data.user_id'),
        hmoId: _.get(json, 'data.hmo_id'),
        emailAddress: _.get(json, 'data.email_address'),
        firstName: _.get(json, 'data.first_name'),
        lastName: _.get(json, 'data.last_name'),
        sex: _.get(json, 'data.sex'),
        otherNames: _.get(json, 'data.other_names'),
        dob: _.get(json, 'data.date_of_birth'),
        age: moment().diff(_.get(json, 'data.date_of_birth'), 'years'),
        package: _.get(json, 'data.package'),
        plan: _.get(json, 'data.plan'),
        planType: _.get(json, 'data.plan_type'),
        userType: _.get(json, 'data.user_type'),
        imageUrl: _.get(json, 'data.profile_picture'),
        referralUrl: _.get(json, 'data.referral_url'),
        isLoggedIn: false,
        credentials: {
            accessToken: _.get(json, 'data.access_token'),
            daaraToken: _.get(json, 'data.daara_token'),
            fcmToken: '',
            kangpeToken: ''
        },
        preferences: {
            allowPushNotifications: true,
            showCyclePlanner: true
        }
    };
}

function daaraMapPrincipal(json) {
    return {
        id: _.get(json, 'enrollee.id'),
        profile_picture: _.get(json, 'enrollee.profilePicturePicture'),
        first_name: _.get(json, 'enrollee.person.firstName'),
        last_name: _.get(json, 'enrollee.person.lastName'),
        hmo_id: _.get(json, 'enrollee.hmoId'),
        email_address: _.get(json, 'enrollee.person.email'),
        phone_number: _.get(json, 'enrollee.person.phoneNumber'),
        date_of_birth: _.get(json, 'enrollee.person.dateOfBirth'),
        address:
            _.get(json, 'enrollee.addresses[0].city') +
            ', ' +
            _.get(json, 'enrollee.addresses[0].street1'),
        plan: _.get(json, 'product.name'),
        dependants: _.map(_.get(json, 'dependants'), daaraMapDependant),
        unassigned_products: [],
        active_status: _.get(json, 'enrollee.status') == 'ACTIVE' ? 1 : 0
    };
}

function daaraMapDependant(json) {
    return {
        id: _.get(json, 'id'),
        profile_picture: _.get(json, 'profilePicturePicture'),
        first_name: _.get(json, 'person.firstName'),
        last_name: _.get(json, 'person.lastName'),
        hmo_id: _.get(json, 'hmoId'),
        email_address: _.get(json, 'person.email'),
        phone_number: _.get(json, 'person.phoneNumber'),
        date_of_birth: _.get(json, 'person.dateOfBirth'),
        address:
            _.get(json, 'address') !== null
                ? _.get(json, 'address.city') + ', ' + _.get(json, 'address.street1')
                : 'N/A',
        plan: _.get(json, 'product.name'),
        active_status: _.get(json, 'status') == 'ACTIVE' ? 1 : 0
    };
}

export default new UserService();
