import React from 'react';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import { Link } from 'react-router-dom';

const RatingSuccessfulPage = (props) => {
  return(
    <MainContent>
      <PageHeader title="Rating Successful"/>
        <PageBody>
          <div>
            <div style={{alignItems: "center"}}>
              <p style={{fontSize:"1.6em"}}>You successfully rated the conversation</p>
              <br/>
              <br/>
              <div style={{fontSize:"1.4em"}}>
                <div>
                  <Link to="/">Back to Home</Link>
                </div>
              </div>
            </div>
          </div>
        </PageBody>
    </MainContent>
  )
};

export default RatingSuccessfulPage;
