import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConsultationList from './ConsultationList';
import ButtonBar from "./boxes/ButtonBar";
import InitialConsultDoctorCard from "./boxes/InitialConsultDoctorCard";

class ChatThreadList extends Component {
  constructor() {
    super()
    this.state = {
      currentTab: "active"
    }
  }

  handleChange = (chatThreadId) => {
    this.props.history.push(`/consultations/${chatThreadId}/messages`);
  };

  getMessageStats() {
    const { newMessages } = this.props;

    const msgStats = {};
    Object.keys(newMessages).forEach(msgKey => {
      if(msgStats[newMessages[msgKey].consultation_id]) {
        if (newMessages[msgKey].display) {
          msgStats[newMessages[msgKey].consultation_id].push(newMessages[msgKey])
        }
      } else {
        msgStats[newMessages[msgKey].consultation_id] = []
        if (newMessages[msgKey].display) {
          msgStats[newMessages[msgKey].consultation_id].push(newMessages[msgKey])
        }
      }
    })
    return msgStats;
  }

  switchTabs = (newTab) => {
    if (newTab !== this.state.currentTab) {
      this.setState({
        currentTab: newTab
      })
    }
  }

  render() {
    const { chatThreadList, endConsultationConfirmations } = this.props;
    const { currentTab } = this.state;

    const msgStats = this.getMessageStats()

    const activeConsultations = chatThreadList.filter(consultation => 
      consultation.consultation_status === 2 || consultation.consultation_status === 7)

    const inactiveConsultations = chatThreadList.filter(consultation => 
      consultation.consultation_status !== 2 && consultation.consultation_status !== 7)
    
    return (
      <React.Fragment>
        {
          chatThreadList.length === 0 ? 
            <InitialConsultDoctorCard 
              history={this.props.history}/>
            :
            <>
              <ButtonBar main={{
                name: 'New Consultation', 
                func: () => ( this.props.history.push('/consultations/new') )
              }}/>
              <div>
                <button
                  className={`consultation-tabs ${currentTab === 'active' ? 'activeTab': ''}`}
                  onClick={() => this.switchTabs('active')}>
                  Active Consultations
                </button>
                <button
                  className={`consultation-tabs ${currentTab === 'inactive' ? 'activeTab' : ''}`}
                  onClick={() => this.switchTabs('inactive')}>
                  Other Consultations
                </button>
              </div>
              <div className="dashboard-card">
                { currentTab === "active" ?
                  <ConsultationList
                    list={activeConsultations}
                    msgStats={msgStats}
                    endConsultationConfirmations={endConsultationConfirmations}
                    handleChange={this.handleChange}/> :
                  <ConsultationList
                    list={inactiveConsultations}
                    msgStats={msgStats}
                    endConsultationConfirmations={endConsultationConfirmations}
                    handleChange={this.handleChange}/>
                }
              </div>
            </>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({consultation}) => ({
  newMessages: consultation.newMessages,
  endConsultationConfirmations: {...consultation.endConsultationConfirmations}
})

export default withRouter(connect(mapStateToProps)(ChatThreadList));