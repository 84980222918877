import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { ApiService, ENDPOINTS } from '../services/ApiService';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import FeedbackMessages from '../components/FeedbackMessages';
import SendFeedbackForm from '../components/forms/SendFeedbackForm';

const REFRESH_INTERVAL = 10000;
const MESSAGE_COUNT = 10;

class FeedbackChatContainer extends Component {
  constructor(props) {
    super(props)
    this.service = new ApiService(props.user.credentials.accessToken);
    this.state = {
      messages: [],
      noOlderMessages: false,
      isSending: false,
      isLoading: false,
      shouldScrollToBottom: false,
      currentPage: 1,
      error: ""
    }
  }

  componentDidMount() {
    this.displayMessages(true);

    this.refreshInterval = setInterval(() => ( this.displayMessages() ), REFRESH_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  scrollToBottom = () => {
    this.setState({
      shouldScrollToBottom: true
    }, () => setTimeout(() => this.setState({ shouldScrollToBottom: false}), 2000))
  }

  postMessage = (message) => {
    const newMessage = { content: message };
    const { match: { params: { id } } } = this.props;

    this.setState({
      isSending: !this.state.isSending,
    })

    this.service.post(ENDPOINTS.feedbackThread.postFeedbackToThread(id), newMessage).then((response) => {
      let m = { id: response.data.feedback_id, content: message, owner: true, created_at: new Date() };
      let mThread = this.state.messages;
      mThread.push(m);

      this.setState({
        messages: mThread,
        isSending: !this.state.isSending,
      });

      this.scrollToBottom();
    }).catch((error) => {
      this.setState({
        error: error.message
      })
    });
  }

  displayMessages = (shouldScrollToBottom=false) => {
    const { match: { params: { id } } } = this.props;
    const { messages } = this.state;
    const limit = MESSAGE_COUNT;

    const queryData = { limit }

    this.service.get(ENDPOINTS.feedbackThread.getFeedbackThreadById(id), queryData).then((response) => {
      const reversedMessages = response.data.feedback_thread.reverse();
      if (messages.length < limit){
        this.setState({
          messages: reversedMessages
        })
      }
      else {
        const newMessages = messages;

        reversedMessages.forEach((message) => {
          if (message.id > messages[messages.length-1]["id"]) {
            newMessages.push(message)
          }
        })

        this.setState({
          messages: newMessages
        });
      }

      if (shouldScrollToBottom) {
        this.scrollToBottom();
      }
    })
    .catch((e) => {
      window.location.replace("/feedback")
    });
  }

  loadMoreFeedbackMessages = () => {
    const { match: { params: { id } } } = this.props;

    let currentPageNumber = this.state.currentPage

    const limit = MESSAGE_COUNT;
    const newPageNumber = ++currentPageNumber;
    const queryData = { limit, page: newPageNumber }

    this.setState({
      isLoading: !this.state.isLoading
    })

    this.service.get(ENDPOINTS.feedbackThread.getFeedbackThreadById(id), queryData).then((response) => {
      const olderMessages = response.data.feedback_thread.reverse()

      if (olderMessages.length === 0 ) {
        this.setState({
          noOlderMessages: !this.state.noOlderMessages
        })
      }

      this.setState({
        currentPage: newPageNumber,
        messages: [...olderMessages, ...this.state.messages],
        isLoading: !this.state.isLoading
      });
    })
    .catch((error) => {
      this.setState({
        error: error.message
      })
    });
  }

  render() {
    return (
      <MainContent>
        <PageHeader 
          title="Chat with support"
        />
        <PageBody>
          <div className="chat-wrapper dashboard-card">
            <div className="chat-section">
              <FeedbackMessages 
                messages={this.state.messages}
                loadMoreFeedbackMessages={this.loadMoreFeedbackMessages}
                isLoading={this.state.isLoading}
                noOlderMessages={this.state.noOlderMessages}
                shouldScrollToBottom={this.state.shouldScrollToBottom}
              />
            </div>
           <SendFeedbackForm 
              onSubmit={this.postMessage}
              isSending={this.state.isSending}
            />
          </div>
        </PageBody>
      </MainContent>
    )
  }
}

export default withRouter(FeedbackChatContainer);