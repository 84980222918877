/**
 * Created by the.last.mayaki on 23/10/2017.
 */
import React from 'react';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import {connect} from 'react-redux';
import * as _ from 'lodash';
import {withRouter} from 'react-router-dom';
import * as plannerActions from '../actions/planner';
import BigCalendar from 'react-big-calendar';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import LoadingScreen from '../components/LoadingScreen';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PlannerSetUpForm from '../components/forms/PlannerSetUpForm';
import NotificationBanner from "../components/boxes/NotificationBanner";
import Modal from "../components/boxes/Modal";
import DecisionModal from "../components/modals/DecisionModal";


BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);


class PlannerContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUpdatePlannerModal: false,
      showResetPlannerModal: false
    }
  }

  componentDidMount() {
    const {dispatch, user} = this.props;
    dispatch(plannerActions.getCyclePlanner(user.credentials.accessToken));
  }


  onSetUpFormSubmit = (values, dispatch) => {
    dispatch(plannerActions.setupPlanner(values));
  };


  clearError = () => {
    const {dispatch} = this.props;
    dispatch(plannerActions.clearError());
  };

  renderLoading = () => {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  };

  toggleUpdatePlannerModal = () => {
    this.setState({
      showUpdatePlannerModal: !this.state.showUpdatePlannerModal
    })
  };

  toggleResetPlannerModal = () => {
    this.setState({
      showResetPlannerModal: !this.state.showResetPlannerModal
    })
  };

  updatePlanner = (values, dispatch) => {
    dispatch(plannerActions.updatePlanner(values));
    this.toggleUpdatePlannerModal();
  };

  resetPlanner = () => {
    const {dispatch, user} = this.props;
    dispatch(plannerActions.resetPlanner(user.credentials.accessToken));
    this.toggleResetPlannerModal();
  };

  refreshPlanner = () => {
    const {dispatch, user} = this.props;
    setTimeout(()=>{
      dispatch(plannerActions.getCyclePlanner(user.credentials.accessToken));
    },2000);
  };

  renderContent = () => {
    const {user, periodData, message, error, meta} = this.props;
    const { showUpdatePlannerModal, showResetPlannerModal } = this.state;

    if(meta && meta.refresh){
      this.refreshPlanner();
    }

    return (
      <MainContent>
        <PageHeader title="Cycle Planner"/>
        <PageBody>
          <div style={{flex: "1", padding: "10px"}}>

            <React.Fragment>
              {showUpdatePlannerModal &&
                <Modal title="Update Planner" onClose={this.toggleUpdatePlannerModal}>
                  <div className="form">
                    <PlannerSetUpForm onSubmit={this.updatePlanner}/>
                  </div>
                </Modal>
              }
              {showResetPlannerModal &&
                <DecisionModal
                  title="Are you sure you want to reset Planner data?"
                  content="Please only do this if the planner data is not correct, and you wish to set up again."
                  onYes={this.resetPlanner}
                  onClose={this.toggleResetPlannerModal}
                />
              }
            </React.Fragment>

            {(!_.isEmpty(error) && error.code !== 404) && <NotificationBanner type="error" message={error.message} title="Error" onDismiss={this.clearError}/>}
            {message && <NotificationBanner type="success" message={message} title="Notification!" />}

            {periodData && periodData.length > 0 ? (
              <React.Fragment>
                <div className="dashboard-btn-section">
                  <div className="dashboard__primary-btn" onClick={this.toggleUpdatePlannerModal}>
                    Update
                  </div>
                  <div className="dashboard__primary-btn red" onClick={this.toggleResetPlannerModal}>
                    Reset
                  </div>
                </div>
                <BigCalendar
                  className="planner"
                  events={periodData}
                  views={['month']}
                  components={{
                    event: Event,
                    eventWrapper: EventWrapper
                  }}
                  min={moment().subtract(2, 'days').toDate()}
                  max={moment().add(2, 'days').toDate()}
                  defaultDate={new Date()}/>
              </React.Fragment>
            ) : (
              <PlannerFormContainer>
                <PlannerSetUpForm onSubmit={this.onSetUpFormSubmit} user={user}/>
              </PlannerFormContainer>
            )
            }
          </div>
        </PageBody>
      </MainContent>
    );
  };

  render() {
    const {isFetching, user} = this.props;

    if (isFetching || _.isEmpty(user)) return this.renderLoading();

    return this.renderContent();

  }
}


const PlannerFormContainer = (props) => {
  return (
    <div className="planner__form">
      <div style={{width: "90%", margin: "auto", padding: "10px"}}>
        <h1>Set Up your Cycle Planner</h1>
        <p className="intro">
          Welcome to your Cycle Planner.This will alert you when your period is around the corner and when you are most
          fertile so you can plan or prevent pregnancy. Answer these 5 simple questions to set it up.
        </p>

        <div className="form">
          {props.children}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return Immutable.asMutable(state.planner, {deep: true});
}

const EventWrapper = ({children}) => {
  return (
    <div className="calender-event-wrapper">
      {children}
    </div>
  )
};


const Event = ({event}) => {
  let eventBackground = "";
  switch (event.title) {
    case "Period Days":
      eventBackground = "red";
      break;
    case "Fertile Days":
      eventBackground = "green";
      break;
    case "Safe Days":
      eventBackground = "orange";
      break;
    case "Ovulation Day":
      eventBackground = "purple";
      break;
    default:
      eventBackground = "white";
  }
  return (
    <div className={`event-item ${eventBackground}`}>
      {event.title}
    </div>
  )
};

export default withRouter(connect(mapStateToProps)(PlannerContainer));