import React from 'react';
import { MESSAGE_TYPE, ATTACHMENT_TYPE } from '../../services/utilityService';
import CallData from './CallData';
import MediaData from './MediaData';
import TextData from './TextData';
import ReferralData from './ReferralData';
import PrescriptionData from './PrescriptionData';

const BubbleLeft = (props) => (
  <div className="doctor-chat">
    <div className="chat-item__message doctor-chat__bubble">
      {
        props.type ?
        displayMessageByType(props, props.type)
        :
        <TextData {...props}/>
      }
    </div>
  </div>
);

const displayMessageByType = (props, type) => {
  if (type === MESSAGE_TYPE.text){
    return (
      <TextData 
        {...props}
      />
    )
  }
  if(type === MESSAGE_TYPE.call){
    return (
      <CallData
        {...props}
      />
    )
  } 
  if (type === MESSAGE_TYPE.action){
    if(props.attachments[0].type === ATTACHMENT_TYPE.media) {
      return (
        <MediaData
          {...props}
        />
      )
    }
    if(props.attachments[0].type === ATTACHMENT_TYPE.prescription) {
      return (
        <PrescriptionData
          {...props}
        />
      )
    }
    if(props.attachments[0].type === ATTACHMENT_TYPE.referral) {
      return (
        <ReferralData
          {...props}
        />
      )
    }
  }
}

export default BubbleLeft;