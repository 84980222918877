import React, { useEffect, useState } from "react";
import MainContent from "./boxes/MainContent";
import PageBody from "./boxes/PageBody";
import PageHeader from "./boxes/PageHeader";
import { Link } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import Paginate from "./utils/Paginate";

export default function ReferSignupsPage({
  referrals,
  getReferrals,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [activeStatus, setActiveStatus] = useState("");

  let searchTermEntered;

  useEffect(() => {
    getReferrals({
      page: currentPage,
      status: activeStatus,
      name: search
    });
  }, [currentPage, search, activeStatus, getReferrals]);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const isFiltered = () => (
    search.length > 0 || activeStatus.length > 0
  );

  const updateSearchInput = (event) => {
    const query = event.target.value;
    clearTimeout(searchTermEntered);
    searchTermEntered = setTimeout(() => {
      setSearch(query);
    }, 1000);
  };

  const updateSelect = (event) => {
    setActiveStatus(event.target.value);
  };

  return (
    <MainContent>
      <PageHeader title="Signups overview" />
      <PageBody>
        {referrals ? (
          <>
            <div className="dashboard-card mb-2">
              <div className="refer-dashboard-section refer-dashboard-intro">
                <h2 className="dashboard-card__title-text">Your referrals</h2>
                <p className="dashboard-card__body-text">
                  You get your referral earning 30 days after your friend
                  becomes a paid subscriber
                  {referrals &&
                    referrals.length === 0 && !isFiltered() &&
                    ", but no one has signed up with your link yet. Invite your friends to start earning"}
                  .
                </p>
                {referrals && referrals.length === 0 && !isFiltered() && (
                  <Link to="/refer" className="dashboard-refer__primary-btn">
                    Invite your friends
                  </Link>
                )}
              </div>
            </div>
            {((referrals && referrals.length > 0) || isFiltered()) && (
              <div className="dashboard-card mb-2">
                <div className="refer-dashboard-filter-section">
                  <input
                    type="text"
                    name="search-bar"
                    onChange={updateSearchInput}
                    placeholder="Search..."
                    className="referral-search-bar"
                  />
                  <select
                    className="referral-dashboard-form__select"
                    onChange={updateSelect}
                  >
                    <option value=''>Status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Free Trial</option>
                  </select>
                </div>
                <div className="refer-dashboard-section">
                  <table className="dashboard-table">
                    <thead>
                      <tr>
                        <th className="dashboard-table-item dashboard-table-item__header">
                          Name
                        </th>
                        <th className="dashboard-table-item dashboard-table-item__header">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {referrals.map((referral, index) => (
                        <tr key={index}>
                          <td className="dashboard-table-item dashboard-table-item__body">
                            {referral.policy_buyer}
                          </td>
                          <td className="dashboard-table-item dashboard-table-item__body">
                            {referral.active_status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="refer-dashboard-section">
                  <Paginate
                    current={currentPage}
                    last={currentPage}
                    onChange={changePage}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingScreen />
        )}
      </PageBody>
    </MainContent>
  );
}
