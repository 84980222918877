import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';

let ChangePasswordForm = (props) => {

    const {handleSubmit,pristine,submitting} = props;

    return (
        <form onSubmit={handleSubmit} style={{maxWidth:"100%", width: "50rem"}}>
            <Field component={renderField} type="password" name="old_password" placeholder="Old Password" label="Old Password" required/>
            <Field component={renderField} type="password" name="new_password" placeholder="New password" label="New Password" required/>
            <Field component={renderField} type="password" name="confirm_new_password" placeholder="Confirm New password" label="Confirm New Password" required/>
            <Field component="input" type="hidden" name="access_token" style={{display:"none"}}/>
            <input type="submit" value="CHANGE PASSWORD" disabled={pristine||submitting}/>
        </form>
    )
};


const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div className="form-group">
        <div>
            <input {...input} placeholder={label} type={type}/>
            {touched && error && <span className="input-error">{error}</span>}
        </div>
    </div>
);


const validate = (values) => {
    const errors = {};
    if(!values.old_password){
        errors.old_password = "Please enter old password";
    }

    if(!values.new_password){
        errors.new_password = "Please enter new password";
    }else if(values.new_password.length < 6){
        errors.new_password = "Password must be more than six characters";
    }else if(values.new_password === values.old_password) {
        errors.new_password = " New password must be different from old password";
    }else if(values.new_password !== values.confirm_new_password){
        errors.confirm_new_password = "Password fields don't match";
        errors.new_password = "Password fields don't match"
    }

    return errors;
};


ChangePasswordForm = reduxForm({
    form:'changePasswordForm',
    validate
})(ChangePasswordForm);

ChangePasswordForm = connect(state=>({
    initialValues:{access_token: state.session.user.credentials ? state.session.user.credentials.accessToken : ''},
    enableReinitialize:true
}))(ChangePasswordForm);

export default ChangePasswordForm