import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import Modal from '../boxes/Modal';
import LoadingScreen from '../LoadingScreen';
import { ApiService, ENDPOINTS } from "../../services/ApiService";

class FollowUpConsultationForm extends Component {
  constructor(props){
    super(props);

    this.service = new ApiService(props.accessToken);
    
    this.state = {
      error: null
    };
  }

  createNewConsultation = (values) => {
    const { consultation, doctorOnlineStatus, onClose, toggleDoctorOptionModal} = this.props;

    const data = { 
      medium_id: consultation.medium_id,
      patient_id: consultation.patient.id, 
      doctor_id: consultation.doctor.id,
      previous_consultation_id: consultation.id,
      reason: values.content
    };

    if(doctorOnlineStatus === "online"){
      this.service.post(ENDPOINTS.consultations.consultation, data)
      .then((response) => {
          window.location.href = `/consultations/${response.data.consultation_id}/messages`;
        })
        .catch((error) => {
          this.setState({
            error
          })
          console.log(error)
        });
    }

    if(doctorOnlineStatus=== "offline"){
      onClose();
      toggleDoctorOptionModal();
    }
  }

  render() {
    const { handleSubmit, pristine, submitting,onClose } = this.props;
    const { consultation } = this.props;

    return (
      <Modal onClose={onClose} title="Referred Pharmacy Details">
        {
          !consultation ?
            <LoadingScreen/>
            :
            <div className="dashboard-card">
              <form className="dashboard-form" 
                onSubmit={handleSubmit(this.createNewConsultation)}
              >
                <div className="dashboard-form-item-wrapper">
                  <div className="dashboard-input-wrap profile-input-wrap">
                    <span className="dashboard-form-item">
                      <label className="profile-item__label" htmlFor="">
                        Why are you consulting the doctor?
                      </label>
                      <Field value="" component="textarea" name="content" className="dashboard-form__input" placeholder="Enter Complaint here" type="text"/>
                    </span>
                  </div>
                </div>
                <button disabled={pristine || submitting}className="dashboard__primary-btn dashboard-form__btn">Submit</button>
              </form>
            </div>
        }
      </Modal>
    );
  }
}

FollowUpConsultationForm = reduxForm({
  form:"followUpConsultationForm"
})(FollowUpConsultationForm);

export default withRouter(FollowUpConsultationForm);