import React from 'react';
import {reduxForm, Field, change} from 'redux-form';
import {Link} from 'react-router-dom';
import LOGO from '../../img/logo.svg';

import ImageInput from '../inputs/ImageInput';
import FilterableSelect from "../inputs/FilterableSelect";

class TestForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      status: null,
      scale: 1,
      rawImage: null,
      croppedImage: "https://placehold.it/100x100",
      cropperOpen: false
    };
    this.croppedStuff = null;
  }

  handleCrop = (image) => {
    const {dispatch} = this.props;

    if (image) {
      dispatch(change('testForm', 'profile_picture', image));
    } else {
      console.log("Ko le crop");
    }
  };

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="form_logo">
            <Link to={'/'}><img src={LOGO} alt="Reliance HMO's Logo"/></Link>
          </div>

          <Field
            name="providerId"
            component={FilterableSelect}
            props={
              {
                items:[
                  {
                    "id": 35,
                    "name": "Araba Medical Centre",
                    "location": "Ifako-IjayeÂ Â ",
                    "address": "122, Ekoro-Agbelekale Road, Big Joy B/stop, Abule Egba, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 98,
                    "name": "Bee Hess Hospital",
                    "location": "Akowonjo",
                    "address": "155, Akowonjo road, Akowonjo, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 104,
                    "name": "Broad Hospital",
                    "location": "Ikotun",
                    "address": "17, Fehintola St, Off Isijola St, NEPA B/Stop, Ikotun",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 108,
                    "name": "County Hospital Ltd",
                    "location": "Ogba",
                    "address": "No 56, Ogunlana Drive",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  },
                  {
                    "id": 111,
                    "name": "Deji Clinic - Ketu",
                    "location": "Ketu",
                    "address": "5, Doyin Omololu Street, Off Mr Biggs junction, Oba-Nle Aro Bus Stop, Ketu Alapere",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 40,
                    "name": "Divine Medical Centre",
                    "location": "Lagos-IslandÂ ",
                    "address": "53, Norman Williams Street, Off Ribadu Road, South West, Ikoyi, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 41,
                    "name": "Dolu Hospital",
                    "location": "Oshodi-IsoloÂ ",
                    "address": "7, Sunmola Abayomi Street, Mafoluku, Oshodi, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 115,
                    "name": "El-Dunamis Medical Centre",
                    "location": "Akesan",
                    "address": "17, KING SOLOMON STREET, ARAROMI BUS-STOP, OFF LASU- ISHERI, AKESAN, LAGOS",
                    "tier_id": 4,
                    "state": {
                      "id": 30,
                      "name": "Osun"
                    }
                  },
                  {
                    "id": 44,
                    "name": "Faith City Hospital, Ajao",
                    "location": "Ajao Estate",
                    "address": "16, Asa-Afariogun St., Off Osolo Way, Ajao Estate, Lagos",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 118,
                    "name": "Faramed  Clinics",
                    "location": "Sango-Ota",
                    "address": "37, Akinwunmi Street,  Joju Road, Sango Ota, Ogun State",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  },
                  {
                    "id": 52,
                    "name": "Germaine Health Centre",
                    "location": "Lekki",
                    "address": "Lekki Expressway , Lekki, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 6,
                    "name": "Gold Cross Hospital",
                    "location": "Lagos-IslandÂ ",
                    "address": "17B, Bourdillon Road, Ikoyi, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 53,
                    "name": "Happy Family Hospital",
                    "location": "Surulere",
                    "address": "77, Falolu Road, Off Akerele, Surulere, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 134,
                    "name": "Hetta Medical Centre",
                    "location": "Otta",
                    "address": "15, Martina Road, Sango Ota, Ogun State",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  },
                  {
                    "id": 57,
                    "name": "Ifpf Hospital",
                    "location": "Maryland",
                    "address": "5, Oki Lane, Maryland, Lagos.",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 150,
                    "name": "Lex Medical Centre",
                    "location": "Ijaiye-Ojokoro",
                    "address": "5, RALIAT ALABI STREET, IYANA MEIRAN, IJAIYE-OJOKORO, LAGOS",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 153,
                    "name": "Luth Initiatives Limited",
                    "location": "Idi-Araba",
                    "address": "ROOM 1 & 2 HEALTH, MANPOWER RESOURCES CENTRE, LUTH PREMISES, IDI-ARABA, LAGOS.",
                    "tier_id": 4,
                    "state": {
                      "id": 17,
                      "name": "Imo"
                    }
                  },
                  {
                    "id": 156,
                    "name": "Medic Angels Nigeria Ltd.",
                    "location": "OgbaruÂ ",
                    "address": "7, Akintan Street, Dideolu Estate, Ogba, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 157,
                    "name": "Medicare Clinic",
                    "location": "Otta",
                    "address": "KM 4, Idi-Iroko Road, Otta",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  },
                  {
                    "id": 159,
                    "name": "Mount Sinai Hospital",
                    "location": "Ebute-Metta",
                    "address": "177/179, Bornu Way, Ebute Metta",
                    "tier_id": 4,
                    "state": {
                      "id": 31,
                      "name": "Oyo"
                    }
                  },
                  {
                    "id": 163,
                    "name": "Ogongo Medical Centre",
                    "location": "BadagryÂ ",
                    "address": "10 Adele Ajosun Street, Badagry, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 20,
                      "name": "Kano"
                    }
                  },
                  {
                    "id": 165,
                    "name": "Omni Medical Centre",
                    "location": "Lagos-Island",
                    "address": "18, Boyle street, Onikan",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 166,
                    "name": "Oohbee Hospital",
                    "location": "AlimoshoÂ ",
                    "address": "1, Kudaki Road, Hostel B/stop, Egbe, Alimosho, Lagos.",
                    "tier_id": 4,
                    "state": {
                      "id": 15,
                      "name": "Abuja (FCT)"
                    }
                  },
                  {
                    "id": 356,
                    "name": "Patience Hospital",
                    "location": "Abuja",
                    "address": "No 56, Ogunlana Drive",
                    "tier_id": 3,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 170,
                    "name": "Primex Hospital",
                    "location": "Ikotun",
                    "address": "58, Igando Road, Unity Bus Stop, Ikotun, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 171,
                    "name": "Promise Medical Centre - Dopemu",
                    "location": "Dopemu",
                    "address": "132, Dopemu road, Dopemu Agege, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 172,
                    "name": "Rophe Medical Centre",
                    "location": "Agbara",
                    "address": "23, Anambra Crescent, Agbara Estate",
                    "tier_id": 4,
                    "state": {
                      "id": 12,
                      "name": "Edo"
                    }
                  },
                  {
                    "id": 173,
                    "name": "Rubee Medical Centre Ltd",
                    "location": "Otta",
                    "address": "Km 38, Abeokuta Motor Road, Sango-Otta",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  },
                  {
                    "id": 177,
                    "name": "Shirish Clinic",
                    "location": "Otta",
                    "address": "102, Joju Road, Sango-Otta",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 179,
                    "name": "St Raphael Hospital Ltd",
                    "location": "Okota",
                    "address": "114, Lateef Adegboyega Street, Off Ago Okota Palace Way, Okota, Lagos",
                    "tier_id": 4,
                    "state": {
                      "id": 25,
                      "name": "Lagos"
                    }
                  },
                  {
                    "id": 189,
                    "name": "Veta Hospitals Ltd",
                    "location": "Iyana-Ipaja",
                    "address": "1, Veta Close, Araromi B/Stop, Lagos-Abeokuta Exp., Iyana Ipaja",
                    "tier_id": 4,
                    "state": {
                      "id": 28,
                      "name": "Ogun"
                    }
                  }
                ],
                placeholder:"Search by name, address, location or state",
                filterBy:["name","address","location","state.name"]
              }
            }/>


          <ImageInput
            width={150}
            height={150}
            onCropComplete={this.handleCrop}/>


          <Field component="input" type="hidden" name="profile_picture"/>
          <button type="submit">Submit</button>
        </form>
      </div>
    )
  }

}


TestForm = reduxForm({
  form: 'testForm'
})(TestForm);

export default TestForm
