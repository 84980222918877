import { combineReducers } from 'redux';
import tipReducer from './tip';
import plannerReducer from './planner';
import userReducer from './user';
import providerReducer from './provider';
import consultationReducer from './consultation';
import {reducer as formReducer} from 'redux-form';
import displayReducer from './displayReducer';
import { sessionReducer } from 'redux-react-session';

const rootReducer = combineReducers({
    tips:tipReducer,
    form:formReducer,
    session:sessionReducer,
    user:userReducer,
    display:displayReducer,
    planner:plannerReducer,
    provider: providerReducer,
    consultation: consultationReducer
});

export default rootReducer;
