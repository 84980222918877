import React from 'react';
import PageHeader from './boxes/PageHeader';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import {getAccountsDashboardUrl} from "../services/utilityService";

const SettingsPage = (props) => {

  return (
    <MainContent>
        <PageHeader title="Settings"/>
        <PageBody>
            <div className="dashboard-card">
                <ul>
                    <li className="dashboard-list-item">
                      <span>Cycle Planner: &nbsp;&nbsp;
                          <label className="switch ">
                              <input type="checkbox "/>
                              <span className="slider round "/>
                          </label>
                      </span>
                    </li>
                    <li className="dashboard-list-item">
                      <span>Notification: &nbsp;&nbsp;
                          <label className="switch">
                              <input type="checkbox "/>
                              <span className="slider round "/>
                          </label>
                      </span>
                    </li>
                    <li className="dashboard-list-item"><a href="/change-password">Change Password</a></li>
                    <li className="dashboard-list-item"><a href={`${getAccountsDashboardUrl()}/welcome?src=enrollee`}>Manage Accounts &amp; Billing</a></li>
                    <li className="dashboard-list-item"><a href="/logout">Logout</a></li>
                </ul>
            </div>
        </PageBody>
    </MainContent>
  )
};

export default SettingsPage;