import React, {useState, useRef, useEffect} from 'react';

export default function ReferPhoneNumbersForm({onSubmit, className, status, ...props}) {
  const [ message, setMessage ] = useState("");
  const [ error, setError ] = useState("");
  const phoneNumberForm = useRef(null);
  const phoneNumberRegEx = /^[0]?(?!0.)[0-9]{10}$/;

  useEffect(() => {
    if (status) {
      setMessage(status);
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let phoneNumbers = e.target.phoneNumbers.value;
    phoneNumbers = phoneNumbers.split(",").map( phoneNumber => phoneNumber.trim() );
    const [validPhoneNumbers, invalidPhoneNumbers] = phoneNumbers.reduce(([pass, fail], phoneNumber) => (
      phoneNumberRegEx.test(phoneNumber) ? [ [...pass, phoneNumber], fail ] : [ pass, [...fail, phoneNumber] ]
    ), [ [], [] ]);

    if (invalidPhoneNumbers.length > 0) {
      e.target.phoneNumbers.value = invalidPhoneNumbers.join(', ');
      setError("These phone numbers are invalid. Please correct them and try again.");
    }

    if (validPhoneNumbers.length > 0) {
      setMessage("Please wait. Sending referral SMS");
      onSubmit(validPhoneNumbers, (isSuccessful, message="") => {
        setMessage(message);
      });
    } else {
      setMessage("Please enter valid phone numbers to send out your referral code!");
    }
  }

  const handleInput = (e) => {
    setError("");
  }

  return (
    <form onSubmit={handleSubmit} ref={phoneNumberForm} className={`form ${className}`} {...props}>
      <div className="form-group">
        <div>
          <span className="input-error">{error}</span>
          <input 
            name="phoneNumbers" 
            className="dashboard-form__input--full-width" 
            placeholder="08012345678" 
            type="text" 
            onChange={handleInput}
            required
          />
          <span className="input-message">{message}</span>
        </div>
      </div>
      <button type="submit" className="dashboard-refer__primary-btn">Send invite</button>
    </form>
  );
}