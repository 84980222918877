/**
 * Created by the.last.mayaki on 31/10/2017.
 */
import axios from 'axios';
import { features } from './daara-config.json';
import { BASE_URL_RELIANCE, DAARA_ENROLLEE_SERVICE_URL } from './utilityService';

const BASE_URL = BASE_URL_RELIANCE;

class ProviderService {
    constructor() {
        this.ax = axios.create({
            baseURL: BASE_URL,
            'Content-Type': 'application/json'
        });
        this.data = {};
    }

    getProviders(accessToken, hash, providerTypeId) {
        if (features.providerService.load) {
            const url = hash
                ? `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/${hash}/providers`
                : `${DAARA_ENROLLEE_SERVICE_URL}/enrollees/providers`;

            return axios
                .get(`${url}${providerTypeId ? '?type_id=' + providerTypeId : ''}`)
                .then((response) => {
                    return response.data;
                });
        }
        if (accessToken) {
            return this.ax
                .get(`/enrollees/providers${providerTypeId ? '?type_id=' + providerTypeId : ''}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                .then((response) => {
                    return response.data.data;
                });
        } else {
            return [];
        }
    }
}

export default new ProviderService();
