/**
 * Created by the.last.mayaki on 23/10/2017.
 */
import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, change} from 'redux-form';
import DateTime from 'react-datetime';
import moment from 'moment';

class PlannerSetUpForm extends React.Component {

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('plannerSetUpForm', 'last_period_date', moment(date).format("YYYY-MM-DD")));
    dispatch(change('plannerSetUpForm', 'reason', 'np'));
  };


  render() {

    const {handleSubmit, pristine, submitting} = this.props;

    return (
      <form onSubmit={handleSubmit} style={{fontSize:"1.3rem"}}>
        <div className="form-group">
          <h3>1) Why are you using the Cycle Planner?</h3>
          <div className="radio">
            <Field name="reason" component="input" type="radio" value="pa"/>
            <label htmlFor="reason">I want to be alerted ONLY when my period is close</label>
          </div>
          <div className="radio">
            <Field name="reason" component="input" type="radio" value="gp"/>
            <label htmlFor="reason">I am trying to get pregnant, alert me about when I'm most fertile and when my period
              is close</label>
          </div>
          <div className="radio">
            <Field name="reason" component="input" type="radio" value="np"/>
            <label htmlFor="reason">I'm trying to NOT get pregnant, alert me about when I'm most likely to get pregnant
              from sex and when my period is close</label>
          </div>

        </div>

        <div className="form-group">
          <h3>2) When did your last period start?</h3>
          <p className="hint">
            *It's fine to give your best guess, but the more accurate you are the better
          </p>
          <Field component="input" type="hidden" name="last_period_date" placeholder="Last Period Date" required/>
          <DateTime
            closeOnSelect
            viewMode="days"
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            onChange={this.onDateSet}
            inputProps={{required: true, placeholder: "Last Period Date", readOnly: true}}
            isValidDate={(curr) => curr.valueOf() <= DateTime.moment().valueOf()}
          />
        </div>

        <div className="form-group">
          <h3>3) How many days does your period usually last?</h3>
          <p className="hint">
            *Give your best guess, but the more accurate you are, the better. If you can't remember, it's fine to leave
            it blank
          </p>
          <Field component="input" type="number" name="period_length" placeholder="Period Length"/>
        </div>

        <div className="form-group">
          <h3>4) How many days usually go by between the start of one period and the start of the next one?</h3>
          <p className="hint">
            *Give your best guess, but the more accurate you are, the better. If you can't remember, it's fine to leave
            it blank
          </p>
          <Field component="input" type="number" name="period_intervals" placeholder="Period Interval"/>
        </div>

        <Field className="hidden" type="hidden" name="access_token" component="input" style={{display: "none"}}/>
        <Field className="hidden" type="hidden" name="alert_time" component="input" style={{display: "none"}}/>

        <input type="submit" value="SUBMIT" disabled={pristine || submitting}/>
      </form>
    )
  }
}


PlannerSetUpForm = reduxForm({
  form: 'plannerSetUpForm',
  destroyOnUnmount: false
})(PlannerSetUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('plannerSetUpForm');
PlannerSetUpForm = connect(state => {
  // can select values individually
  const lastPeriodDate = selector(state, 'last_period_date');
  return {
    lastPeriodDate,
    initialValues: {
      access_token: state.session.user.credentials ? state.session.user.credentials.accessToken : '',
      alert_time: "7:00:00"
    },
    enableReinitialize: true
  }
})(PlannerSetUpForm);


export default PlannerSetUpForm