import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const renderMergedProps = (component, props, ...rest) => {
    const finalProps = Object.assign({}, props, ...rest);
    return (
        React.createElement(component, finalProps)
    );
};


const PrivateRoute = ({ component,authenticated, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            authenticated ? (
                    renderMergedProps(component, props, rest)
                ) : (
                    <Redirect to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}/>
                )
        )}
    />
);

const { object, bool, string, func } = PropTypes;

PrivateRoute.propTypes = {
    component: func.isRequired,
    exact: bool,
    path: string.isRequired,
    authenticated: bool.isRequired,
    location: object
};

export default withRouter(connect()(PrivateRoute));