import React, { Component } from 'react';
import moment from 'moment';
import CenteredContentBlock from './boxes/CenteredContentBlock';

class FeedbackThreadList extends Component {
  handleChange = (feedbackThreadId) => {
    window.location.href = `/feedback/thread/${feedbackThreadId}`;
  }

  render() {
    const { feedbackThreadList } = this.props;

    return (
      <div className="dashboard-card dashboard-table-wrap">
        <div className="dashboard-table-overflow">

          {
            feedbackThreadList.length === 0 
            ?
            <CenteredContentBlock>
              <div style={{fontSize: "40px", color: "grey"}}>
                No result found here.
              </div>
            </CenteredContentBlock>
            :
            <table className="dashboard-table" style={{marginTop: "30px"}}>
              <thead>
                <tr>
                  <th className="dashboard-table-item dashboard-table-item__header">Title</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Content</th>
                  <th className="dashboard-table-item dashboard-table-item__header">Date</th>
                </tr>
              </thead>
              <tbody>
              { 
                feedbackThreadList.map((feedbackThread)=>(
                  <tr onClick={() => this.handleChange(feedbackThread.feedback_thread_id)} key={feedbackThread.feedback_thread_id}>
                    <td className="dashboard-table-item dashboard-table-item__body">{feedbackThread.title}</td>
                    <td className="dashboard-table-item dashboard-table-item__body">{feedbackThread.latest_content.substring(0, 200) + '...'}</td>
                    <td className="dashboard-table-item dashboard-table-item__body">{moment(feedbackThread.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
                  </tr>
                ))
              }
              </tbody>
            </table>  
          }
        </div>
      </div>
    )
  }
}

export default FeedbackThreadList;