import axios from 'axios';
import moment from 'moment';
import querystring from 'querystring';


const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/middleman/v1`;


class KangpeService {

  constructor() {
    this.a = axios.create({
      baseURL: BASE_URL,
    });
  }

  loginKangpe(user) {

    this.data = {
      email: user.emailAddress,
      sex: user.sex,
      age: user.age
    };

    return this.a.post(`/users/init`, querystring.stringify(this.data))
      .then((response) => {
        user.credentials.kangpeToken = response.data.data.token;
        return user;
      });
  }


  getTips(accessToken, page, limit) {

    this.data = {
      access_token: accessToken,
      page: page,
      limit: limit,
      latest_first: 1
    };


    return this.a.get(`/tips/all?${querystring.stringify(this.data)}`)
      .then((response) => {
        return response.data.data.tips;
      })

  }

  getTipById(id, accessToken) {

    this.data = {
      access_token: accessToken
    };

    return this.a.get(`/tips/${id}?${querystring.stringify(this.data)}`)
      .then((response) => {
        return response.data.data.tip;
      })
  }


  getQuestionThread(accessToken, page, limit) {

    this.data = {
      access_token: accessToken,
      page: page,
      limit: limit
    };

    return this.a.get(`/questions/thread?${querystring.stringify(this.data)}`)
      .then((response) => {
        return response.data.data.questions;
      }, (e) => {
        throw Object.create({message: e.response.data.message, code: e.response.status});
      })

  }


  askQuestion(values) {
    const formData = new FormData();
    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        if(key === "fileArray" && values[key] instanceof Array){
          for(let i=0;i< values[key].length;i++){
            formData.append("file_"+(i+1),values[key][i]);
          }
          formData.append("file_count",values[key].length);
        }else{
          formData.append(key, values[key]);
        }
      }
    }
    formData.append("source","web");

    return this.a.post(`/questions/ask`, formData, {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
      .then((response) => {
        if (response.data.status === "success") {
          return response.data.data.question_id;
        } else {
          const message = response.data.message || "Oops...an error seems to have occurred";
          throw Object.create({message: message});
        }
      })

  }


  getActiveProviders(accessToken, interval) {
    this.data = querystring.stringify({
      access_token:accessToken,
      interval
    });
    return this.a.get(`/users/active-doctors?${this.data}`)
      .then(response=>{
        return response.data.data;
      })
  }

  changeDoctor(accessToken, doctorId) {
    this.data = querystring.stringify({
      access_token: accessToken,
      doctor_id: doctorId
    });
    return this.a.post(`/users/change-doctor`, this.data)
      .then(response=>{
        return response.data.message
      })
  }


  setUpPlanner(values) {
    this.data = querystring.stringify(values);
    return this.a.post(`/cycle-planner/update-settings`, this.data)
      .then((response) => {
        return response.data;
      })
  }


  getPlanner(month, year, lookAhead, accessToken) {
    this.data = querystring.stringify({
      access_token: accessToken,
      month: month,
      year: year,
      look_ahead: lookAhead
    });
    return this.a.get(`/cycle-planner?${this.data}`)
      .then((response) => {
        return response.data.data;
      })
  }

  updatePlanner(values){
    this.data = querystring.stringify(Object.assign({},values,{
      alert_time:"7:00:00",
      month: moment().month()+1,
      year: moment().year()
    }));
    return this.a.post(`/cycle-planner/update-period`, this.data)
      .then(response => {
        return response.data.message;
      })
  }

  resetPlanner(accessToken){
    this.data = {
      access_token: accessToken
    };
    return this.a.post(`/cycle-planner/reset`, this.data)
      .then(response=>{
        return response.data.message;
      })
  }


}

// eslint-disable-next-line
function JSONtoFormData(json) {
  const formData = new FormData();
  for (let key in json) {
    if (json.hasOwnProperty(key))
      formData.append(key, json[key]);
  }
  return formData;
}

export default new KangpeService();