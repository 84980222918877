import React from "react";
import ReferSharePage from "../components/ReferSharePage";
import { Switch, withRouter } from "react-router-dom";
import userService from "../services/userService";
import PropsRoute from "../components/utils/PropsRoute";
import ReferOverviewPage from "../components/ReferOverviewPage";
import ReferSignupsPage from "../components/ReferSignupsPage";
import ReferWithdrawCashPage from "../components/ReferWithdrawCashPage";
import ReferWithdrawCashSuccessPage from "../components/ReferWithdrawCashSuccessPage";
import Sidebar from "./Sidebar";

class ReferContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referrals: null,
      overview: null,
      error: null,
      textMessageStatus: "",
    };
  }

  sendReferralCodeToPhoneNumbers = (phoneNumbers) => {
    userService
      .sendReferralCodeToPhoneNumbers(phoneNumbers)
      .then((textMessageStatus) => {
        this.setState({ textMessageStatus });
      })
      .catch((e) => {
        this.setState({ textMessageStatus: e.response.data.message });
      });
  };

  getReferrals = ({ page, status, name }) => {
    userService.getReferrals({ page, status, name }).then((response) => {
      this.setState({ referrals: response.data });
    });
  };

  getOverview = () => {
    userService.getReferralOverview().then((overview) => {
      this.setState({ overview });
    });
  };

  withdrawCash = (amount, bank_id, account_name, account_number) => {
    userService
      .withdrawCash(amount, bank_id, account_name, account_number)
      .then((response) => {
        this.props.history.push("/refer/earnings/success");
      })
      .catch((e) => {
        this.setState({ error: e.response.data.message });
      });
  };

  dismissError = () => {
    this.setState({ error: null });
  };

  render() {
    const { user } = this.props;
    const { error, referrals, overview, textMessageStatus } = this.state;
    const authenticated = true;
    return (
      <>
        <Sidebar user={user} />
        <Switch>
          <PropsRoute
            component={ReferSharePage}
            path="/refer"
            exact
            authenticated={authenticated}
            shareUrl={user.referralUrl}
            viewEarnings={() => this.props.history.push("/refer/earnings")}
            viewReferrals={() => this.props.history.push("/refer/referrals")}
            sendToPhoneNumbers={this.sendReferralCodeToPhoneNumbers}
            textMessageStatus={textMessageStatus}
          />
          <PropsRoute
            component={ReferSignupsPage}
            path="/refer/referrals"
            exact
            authenticated={authenticated}
            user={user}
            referrals={referrals}
            getReferrals={this.getReferrals}
          />
          <PropsRoute
            component={ReferOverviewPage}
            path="/refer/earnings"
            exact
            authenticated={authenticated}
            user={user}
            overview={overview}
            getOverview={this.getOverview}
          />
          <PropsRoute
            component={ReferWithdrawCashPage}
            path="/refer/withdraw-cash"
            exact
            authenticated={authenticated}
            user={user}
            overview={overview}
            error={error}
            dismissError={this.dismissError}
            getOverview={this.getOverview}
            withdrawCash={this.withdrawCash}
          />
          <PropsRoute
            component={ReferWithdrawCashSuccessPage}
            path="/refer/earnings/success"
            exact
            authenticated={authenticated}
            user={user}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(ReferContainer);
