import React from 'react';
import * as _ from 'lodash';
import SidebarMenuItem from '../components/SidebarMenuItem';
import LOGO from '../img/logo-white.svg'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { getAccountsDashboardUrl } from '../services/utilityService';

const items = [
    {
        path:"/",
        icon:"fas fa-tachometer-alt",
        title:"Dashboard"
    },
    {
        path:"/tips",
        icon:"fas fa-heartbeat",
        title:"Health Tips"
    },
    {
        path:"/consultations",
        icon:"fas fa-comment-alt",
        title:"Consult a Doctor"
    },
    {
      path: "/refer",
      icon: "fas fa-dollar-sign",
      badge: "New",
      title: "Refer & earn"
    },
    {
        path:"/planner",
        icon:"fas fa-calendar-day",
        title:"Cycle Planner"
    },
    {
        path:"/profile",
        icon:"fas fa-user-circle",
        title:"My Profile"
    },
    {
        path:`${getAccountsDashboardUrl()}/dashboard/enrollees`,
        icon:"fas fa-users",
        title:"Manage Enrolles"
    },
    {
        path:`${getAccountsDashboardUrl()}/dashboard/invoices/edit/new-invoice`,
        icon:"fas fa-plus",
        title:"Add Accounts"
    },
    {
        path:"/hospitals",
        icon:"fas fa-hospital-alt",
        title:"My Hospitals"
    },
    {
        path:"/benefits",
        icon:"fas fa-star",
        title:"My Benefits"
    },
    {
        path:"/settings",
        icon:"fas fa-cog",
        title:"Settings"
    }

];

class Sidebar extends React.Component {
  
  getMessageStats(newMsgs, confirmations) {
    const newMsgConsultationIds = []
    const endConsultationIds = Object.keys(confirmations)

    Object.keys(newMsgs).forEach(msgKey => {
      if (!newMsgConsultationIds.includes(newMsgs[msgKey].consultation_id) && newMsgs[msgKey].display) {
        newMsgConsultationIds.push(newMsgs[msgKey].consultation_id)
      }
    })

    return _.union(newMsgConsultationIds, endConsultationIds);
  }

  render() {
    const { showSideBar, newMessages, endConsultationConfirmations, user } = this.props;
    const msgStats = this.getMessageStats(newMessages, endConsultationConfirmations);
    let filteredSideBarItems = items;

    const filteredItems = () => {
      if (user.sex && user.sex.toLowerCase() === 'm') {
        filteredSideBarItems = items.filter(item => item.title.toLowerCase() !== 'cycle planner');
      };
      
      if (user.package && user.package.toLowerCase() === 'corporate') {
        filteredSideBarItems = items.filter(item => item.title.toLowerCase() !== 'manage enrolles' && item.title.toLowerCase() !== 'add accounts')
      };

    };
    filteredItems()

    return (
      <div id="left" className={`column sidebar ${showSideBar ? '' : 'active'}`}>
        <div className="top-left">
          <div className="sidebar__top">
            <img src={LOGO} alt="RelianceHMO's Logo" />
          </div>
        </div>
        <div className="bottom sidebar--space-between sidebar--enrollee">
          <nav>
            <ul>
              {filteredSideBarItems.map((item, i) => {
                if (item.path === '/consultations') {
                  return <SidebarMenuItem
                    key={i}
                    to={item.path}
                    icon={item.icon}
                    newMessage={{
                      icon: 'fas fa-envelope',
                      count: msgStats.length
                    }}
                    title={item.title} />
                }
                return <SidebarMenuItem
                  key={i}
                  to={item.path}
                  icon={item.icon}
                  badge={item.badge}
                  title={item.title} />
              })}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ display, consultation }) {
  const { showSideBar } = display || {};
  return {
    showSideBar,
    newMessages: { ...consultation.newMessages },
    endConsultationConfirmations: { ...consultation.endConsultationConfirmations }
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar))