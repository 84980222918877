import React, { Component } from 'react';
import Modal from '../boxes/Modal';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import LoadingScreen from '../../components/LoadingScreen';
import { ApiService, ENDPOINTS } from '../../services/ApiService';

class SelectPharmacyModal extends Component {
  constructor(props){
    super(props);
    this.service = new ApiService(props.userToken);
    this.state = {
      prescription: {},
      isLoading: true
    }
  }

  componentDidMount () {
    this.getProvider();
  }

  getProvider = () => {
    const { 
      consultationId, 
      prescriptionId } = this.props;
    
    this.service.get(ENDPOINTS.consultations.prescriptions(consultationId, prescriptionId))
      .then((response) => {
        this.setState({
          isLoading: false,
          prescription: response.data
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  postPrescriptionPharamacy = (values) => {
    const { 
      pharmacies, 
      consultationId, 
      prescriptionId } = this.props;

    let selectedPharmacy = {};

    pharmacies.forEach((pharmacy) => {
      if(pharmacy.name === values.pharmacyName) { 
        selectedPharmacy = pharmacy
      }
    });

    const pharmacyMetadata = {
      provider_id: selectedPharmacy.provider_id,
      metadata: { 
        name: selectedPharmacy.name, 
        address: selectedPharmacy.address,
        pharmacy_code: selectedPharmacy.code || ""
      }
    }

    this.setState({
      isLoading: true
    });
    
    this.service.put(ENDPOINTS.consultations.prescriptions(consultationId, prescriptionId), pharmacyMetadata)
      .then((response) => {
        this.setState({
          isLoading: false,
          prescription: {...this.state.prescription, provider: selectedPharmacy}
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  filteredOptions = (pharmacies, filterValue) => {
    const optionValues = [];
    
    pharmacies.forEach((pharmacy, index) => {
      const currentfilterValue = pharmacy[filterValue];
  
      if(!optionValues.includes(currentfilterValue)) {
        optionValues.push(currentfilterValue);
      }
    });
  
    return optionValues;
  }

  renderOptionValues = (pharmacies, filterValue, selectValueId, selectValueName) => {
    const filteredOptions = pharmacies.filter((pharmacy) => ( 
      pharmacy[filterValue] === selectValueId
    ))
    
    return this.filteredOptions(filteredOptions, selectValueName)
    .map((pharmacy, index) => (
      <option 
        key={`${pharmacy}-${index}`} 
        value={pharmacy}
      >
        {pharmacy}
      </option>
    ));
  }
  
  providerCheck = () => {
    const { prescription } = this.state;
    const { pharmacies, stateName, lgaName, handleSubmit, pristine, submitting } = this.props;

    return (
      <React.Fragment>
        {
          prescription.provider ?
            <div className="dashboard-card">
              <div className="profile-detail-wrap">
                <div className="profile-item">
                  <div className="profile-item-wrapper">
                    <p className="profile-item__label">Prescription Code</p>
                    <p className="profile-item__text">{prescription.code}</p>
                  </div>   
                  {
                    prescription.dispatch_status === "pending" &&
                      <div className="profile-item-wrapper">
                        <span>
                          Please wait while we confirm the drug availability at the pharmacy. You would get a 
                          confirmatory sms/notification in less than 20 minutes.
                        </span>
                      </div> 
                  }              
                  {prescription.dispatch_status !== "pending" &&
                    <React.Fragment>
                      <div className="profile-item-wrapper">
                        <span>Present this code at your pickup pharmacy and the necessary drugs will be given to you</span>
                      </div>
                      <div className="profile-item-wrapper">
                        <p className="profile-item__label">Your pickup pharmacy is</p>
                        <p className="profile-item__text">{prescription.provider.name}</p>
                      </div>
                      <div className="profile-item-wrapper">
                        <p className="profile-item__label">Address</p>
                        <p className="profile-item__text">{prescription.provider.address}</p>
                      </div>
                    </React.Fragment>
                  }                                                       
                </div>
              </div>
            </div>
            :
            <div className="dashboard-card">
              <div className="profile-detail-wrap">
                <div className="profile-item">
                  <div className="profile-item-wrapper">
                    <span>
                      Pick a pharmacy closest to you and wait while we confirm the drug availability at the pharmacy
                      You would get a confirmation sms/notification in less than 20 minutes after selection.
                    </span>
                  </div>
                  <form 
                    className="form select_pharamacy_form"
                    style={{display: "flex", flexDirection: "column"}}
                    onSubmit={handleSubmit(this.postPrescriptionPharamacy)} 
                  >
                    <span className="dashboard-form-item pharmacy-modal">
                      <label 
                        className="profile-item__label"
                        htmlFor="">
                        What state are you in currently?
                      </label>
                      <Field name="stateName" component="select">
                        <option>Select your state of residence</option>
                        {this.filteredOptions(pharmacies, "state")
                          .map((pharmacy, index) => (
                          <option key={`${index}`} value={pharmacy}>{pharmacy}</option>
                        ))}
                      </Field>
                    </span>
                    {
                      stateName &&
                        <span className="dashboard-form-item pharmacy-modal">
                          <label 
                            className="profile-item__label"
                            htmlFor="">
                            Which of these areas is closest to you?
                          </label>
                          <Field name="lgaName" component="select">
                            <option>Select your location</option>                  
                            {
                              this.renderOptionValues(pharmacies, "state", stateName, "lga")
                            }
                          </Field>
                        </span>
                    }
                    {
                      lgaName &&
                        <span className="dashboard-form-item pharmacy-modal">
                          <label 
                            className="profile-item__label"
                            htmlFor="">
                            Select a Pharmacy to pick your drugs from?
                          </label>
                          <Field name="pharmacyName" component="select">
                            <option>Select a Pharmacy</option>
                            {this.renderOptionValues(pharmacies, "lga", lgaName, "name")}
                          </Field>
                        </span>
                    }
                    <button className="primary_button" disabled={pristine || submitting}>
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
        }
      </React.Fragment>
    )
  }

  render() {
    const { prescription, isLoading } = this.state;

    return (
      <Modal onClose={this.props.onClose} title={prescription ? 'Prescription Details' : 'Select a Pharmacy'}>
        {
          isLoading ?
            <LoadingScreen/>
            :
            this.providerCheck()
        }
      </Modal>
    )
  }
}

SelectPharmacyModal = reduxForm({
  form: 'selectPharmacyModal',
})(SelectPharmacyModal);

const selector = formValueSelector('selectPharmacyModal') 

SelectPharmacyModal = connect(state => {
  const stateName = selector(state, 'stateName')
  const lgaName = selector(state, 'lgaName')

  return {
    stateName,
    lgaName,
  }
})(SelectPharmacyModal)

export default SelectPharmacyModal;