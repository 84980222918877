import React from 'react';
import moment from 'moment';
import userService from '../services/userService';
import SecondaryCareRequestForm from "../components/forms/SecondaryCareRequestForm";
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';
import PageHeader from '../components/boxes/PageHeader';
import PageBody from '../components/boxes/PageBody';
import Modal from "../components/boxes/Modal";

class SecondaryCareContainer extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      isLoading:false,
      availableCare:[],
      selectedSecondaryCare:null,
      showRequestCareModal:false,
      showRequestResultModal: false,
      secondaryCareRequestResult:null
    }
  }

  componentDidMount(){
    this.setState({
      isLoading:true
    });
    userService.getSecondaryCareBenefits()
      .then(benefits=>{
        this.setState({
          isLoading:false,
          availableCare:benefits
        })
      })
  }

  toggleRequestModal = () => {
    this.setState({
      showRequestCareModal:!this.state.showRequestCareModal
    })
  };

  setSelectedSecondaryCare = (careId, careName,typeId) => {
    this.toggleRequestModal();
    this.setState({
      selectedSecondaryCare:{
        careId,
        careName,
        typeId
      }
    })
  };

  requestSecondaryCare = (values) => {
    if(values.provider_id && Number(values.provider_id) !== -1){
      this.setState({
        isLoading:true,
        showRequestCareModal:false
      });
      userService.requestSecondaryCare(this.state.selectedSecondaryCare.careId, values.provider_id, values.date_time)
        .then(result=>{
          this.setState({
            isLoading:false,
            secondaryCareRequestResult:result,
            showRequestResultModal:true
          })
        })
        .catch(err=>{
          this.setState({
            isLoading:false,
            secondaryCareRequestResult:{message: err.response.data.message,paCode:null},
            showRequestResultModal:true
          })
        })
    }
  };


  hideRequestResultModal = () => {
    this.setState({
      showRequestResultModal:false,
      secondaryCareRequestResult: null
    })
  };



  render(){

    const {user} = this.props;
    const {
      isLoading,
      availableCare,
      showRequestCareModal,
      selectedSecondaryCare,
      showRequestResultModal,
      secondaryCareRequestResult
    } = this.state;

    if(isLoading){
      return (
        <MainContent>
          <PageHeader title="Access Secondary Care"/>
          <PageBody>
            <LoadingScreen/>
          </PageBody>
        </MainContent>
      )
    }

    return(
      <MainContent>
        <PageHeader title="Access Secondary Care"/>
        <PageBody>
          <React.Fragment>

            {showRequestCareModal &&
              <SecondaryCareRequestModal
                accessToken={user.credentials.accessToken}
                careName={selectedSecondaryCare.careName}
                typeId={selectedSecondaryCare.typeId}
                onRequest={this.requestSecondaryCare}
                onClose={this.toggleRequestModal}/>
            }

            {showRequestResultModal &&
              <RequestResultModal
                careName={selectedSecondaryCare.careName}
                result={secondaryCareRequestResult}
                onClose={()=>window.location.reload(true)}/>
            }
            <div className="dashboard-card secondary-care-contact">
              <h2>For access to secondary care, other than the those on the list above, please contact our help desk via <a href="mailto:hello@reliancehmo.com">hello@reliancehmo.com</a>
                or <a href="tel:070073542623">0700-7354-2623</a></h2>
            </div>

            <div className="sc-card-wrap">
                {availableCare && availableCare.length > 0 ? (
                  availableCare
                    .map(care => (
                      <div className="dashboard-card sc-card"
                      key={care.id}
                       onClick={()=>{
                            if(care.is_accessible && care.limit > 0 && care.used < care.limit)
                              this.setSelectedSecondaryCare(care.id, care.name, care.provider_type_id)
                        }}>
                        <i 
                          className={`fas fa-5x ${care.name === "Spa" ? "fa-spa" : "fa-dumbbell"}${(care.used === care.limit) || (care.limit === 0) || (!care.is_accessible) ? " disabled" : "" }`}>
                        </i>
                        <h2 className="sc-card__title-text">
                          {care.name}
                        </h2>

                        {care.is_accessible && care.used < care.limit ? (
                          <React.Fragment>
                            <p className="sc-card__text">
                              You can use this {care.limit} times {care.frequency}
                            </p>
                            <br></br>
                            <p className="sc-card__text">
                              You've used {care.used} so far.
                            </p>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {care.used >= care.limit ? (
                              <p className="sc-card__text">You've used up your slots for now.</p>
                            ) : (
                              <p className="sc-card__text">Not available.</p>
                            )}
                            <p className="sc-card__text"> ... </p>
                          </React.Fragment>
                        )}
                      </div>
                    ))
                ) : (
                  <div style={{marginTop:"5rem"}}>
                    <p style={{fontSize:"2.5rem",color:"#ccc",fontWeight:"bolder"}}>No secondary care benefit available.</p>
                  </div>
                )}
            </div>

          </React.Fragment>
        </PageBody>
      </MainContent>
    )
  }
}


const SecondaryCareRequestModal = ({accessToken,typeId,careName,onRequest,onClose}) => {
  return (
    <Modal title={`Access ${careName}`} onClose={onClose}>
      <SecondaryCareRequestForm accessToken={accessToken} typeId={typeId} careName={careName} onSubmit={onRequest}/>
    </Modal>
  )
};

const RequestResultModal = ({careName, result, onClose}) => {
  return (
    <Modal title={`Access ${careName}`} onClose={onClose}>
      <div className="pa-code-modal">
        <p className="message">{result.message}</p>
        {result.paCode &&
          <div className="code_holder">
            <p>The code below has been generated for your request:</p>
            <h2 className="code">{result.paCode}</h2>
            <div className="date">
              <b>This code was generated on
                {result.created_at ?
                  moment(result.created_at).format("Do MMM YYYY HH:MM") : moment().format("Do MMM YYYY HH:MM")}</b>
                {result.expired_at && <span>
                and will code expire {moment(result.expired_at).format("Do MMM YYYY HH:MM")}
              </span>}
            </div>
            <p>Please note it down, as you will be required to provide it at the {careName}, in order to get access. </p>
          </div>
        }
        <hr/>
        <p>
          For help or enquires, <br/> please don't hesitate to contact our help desk via <br/> <a href="mailto:hello@reliancehmo.com">hello@reliancehmo.com</a> or <a href="tel:070073542623">0700-73542623</a>
        </p>
      </div>
    </Modal>
  )
};

export default SecondaryCareContainer;