import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, change} from 'redux-form';
import ImageInput from '../inputs/ImageInput';
import DateTime from 'react-datetime';
import './datepicker.css';
import moment from 'moment';
import '../../services/utilityService';
import UtilityService from "../../services/utilityService";
import LoadingScreen from "../LoadingScreen";


class DoctorSignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      lastPage: 3, //can vary depending on the enrollee type,
      isLoadingSection: {profilePicture: false, signaturePhoto: false}
    }
  }

  onNextPage = (e) => {
    e.preventDefault();
    let cPage = this.state.currentPage;
    let nextPage = ++cPage;

      this.props.onError();


    this.setState({
      currentPage: nextPage
    })
  };

  onPrevPage = () => {
    let cPage = this.state.currentPage;
    let prevPage = --cPage;
    //i.e skip company page, if dependant type is child

    this.setState({
      currentPage: prevPage
    })
  };

  componentDidMount() {

    const {dispatch, user} = this.props;

    dispatch(change('doctorSignUpForm', 'middle_name', user.middle_name));

  }

  onImageUploaded = (img, type) => {
    const {dispatch, user, onError} = this.props;
    //set isLoadingSection in state to true
    //upload img
    //get filename from server
    //change form value
    //set isLoadingSection to false
  
    const loadingStateTrue = type === 'profile_picture' ? {profilePicture: true} : {doctorSignature: true}

    this.setState({
      isLoadingSection: {...this.state.isLoadingSection, ...loadingStateTrue}
    });

    const loadingStateFalse = type === 'profile_picture' ? {profilePicture: false} : {doctorSignature: false}
    const uploadType = type === 'profile_picture' ? type : 'doctor_signature';

    UtilityService.uploadFile(img, uploadType, user.accessToken)
      .then(response => {
        dispatch(change('doctorSignUpForm', `${type}_filename`, response.filename));
        dispatch(change('doctorSignUpForm', type, img));
        this.setState({
          isLoadingSection: {...this.state.isLoadingSection, ...loadingStateFalse}
        });
      })
      .catch(e => {
        onError({
          message: "Unable to upload picture.",
          code: -1
        });
        this.setState({
          isLoadingSection: {...this.state.isLoadingSection, ...loadingStateFalse}
        });
        console.log("Error uploading image", e);
      })
  };

  onDateSet = (date) => {
    const {dispatch} = this.props;
    dispatch(change('doctorSignUpForm', 'date_of_birth', moment(date).format("YYYY-MM-DD")));
  };

  render() {
    const {
      error,
      dateOfBirth,
      profilePicture,
      doctorSignature,
      data,
      enrolleeType,
      handleSubmit,
      user,
      pristine,
      doctorSpecialties,
      submitting
    } = this.props;
    const {currentPage, lastPage, isLoadingSection} = this.state;

    return (
      <form onSubmit={currentPage !== lastPage ? this.onNextPage : handleSubmit} className="form">
        <div style={{display: "flex", flexDirection: "row", justifyContent: "evenly"}}>
          {
            [1, 2, 3].map((x) => {
              return <PageIndicator key={x} isActive={x === currentPage}/>
            })
          }
        </div>
        {currentPage === 1 &&
        <PersonalInfoPage
          user={user}
          data={data}
          onDateSet={this.onDateSet}
          dob={dateOfBirth}
          doctorSpecialties={doctorSpecialties}/>
        }
        {
          currentPage === 2 && 
          <ContactInfoPage 
          user={user} 
          data={data}/>
        }

        {currentPage === 3 &&
        <ExtraInfoPage
          user={user}
          data={data}
          profilePicture={profilePicture}
          doctorSignature={doctorSignature}
          onImageSet={this.onImageUploaded}
          isLoadingPhoto={isLoadingSection}
          enrolleeType={enrolleeType}/>
        }

        {error && (
          <div style={{backgroundColor: "red", color: "#FFF", padding: "10px"}}>
            {error}
          </div>
        )}
        <div style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
          <Field component="input" type="hidden" name="page" value={currentPage}/>
          {currentPage > 1 &&
          <input type="button" value="Previous" onClick={this.onPrevPage} style={{float: "left"}}/>}

          <div style={{width: "100%", textAlign: "center"}}>{`${currentPage} / ${lastPage}`}</div>

          {(currentPage >= 1 && currentPage < lastPage) &&
          <input type="submit" value="Next" style={{float: "right"}}/>}
          {currentPage === lastPage &&
          <input type="submit" value="Submit" disabled={pristine || submitting} style={{float: "right"}}/>}
        </div>
      </form>
    )
  }
}

const Required = () => (<span style={{color: "red", fontWeight: "bold"}}>*</span>);


const PersonalInfoPage = (props) => {
  const {user, dob, doctorSpecialties} = props;

  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Personal Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>
        <span>
          Middle Name <Required/>:
          <Field component="input" type="text" name="middle_name" placeholder="Middle Name"
            readOnly={!!user.middle_name} 
            required
          />
          <br/>
        </span>
        <span>
          Sex <Required/>:<br/>
          <Field name="sex" component="input" type="radio" value="m"/>
          {' '}Male {' '}
          <Field name="sex" component="input" type="radio" value="f"/>
          {' '} Female<br/>
        </span>
        <span>
            Date of Birth <Required/>:
            <Field component="input" type="hidden" name="date_of_birth" placeholder="Date of Birth" required/>
            <DateTime
              closeOnSelect
              viewMode="years"
              timeFormat={false}
              dateFormat="YYYY-MM-DD"
              inputProps={{required: true, placeholder: "YYYY-MM-DD", readOnly: true}}
              onChange={props.onDateSet}
              isValidDate={(curr) => curr.year() <= DateTime.moment().year()}
              value={dob}
            />
            <br/>
        </span>
        <span>
          Position <Required/>:
          <Field 
            component="input" 
            name="position" 
            type="text" 
            placeholder="Position" 
            required
            />
          <br/>
        </span>
        <span>
          Specialty <Required/>
          <Field name="specialty_id" component="select" required>
            <option>Please select a specialty</option>
            {
              doctorSpecialties.map((specialty) => {
                return <option key={specialty.id} value={specialty.id}>{specialty.name}</option>
              })
            }
            
          </Field>
        </span>
        <span>
          Medical Certificate Number <Required/>:
          <Field 
            component="input" 
            name="mdcn" 
            type="text" 
            placeholder="Medical Certificate Number" 
            required
            />
          <br/>
        </span>
      </div>
    </div>
  )
};


const ContactInfoPage = (props) => {
  const {user} = props;

  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Contact Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>
        <span>
          Workplace Name<Required/>:
          <Field
            component="input"
            name="workplace_name"
            type="phone"
            placeholder="Workplace Address"
            readOnly={!!user.workplace_name} 
            required
            />
          <br/>
        </span>
        <span>
          Workplace Address <Required/>:
          <Field 
            component="input" 
            name="workplace_address" 
            type="text" 
            placeholder="Workplace Address" 
            required
            />
          <br/>
        </span>
      </div>
    </div>
  )
};

const ExtraInfoPage =(props) => {

  const {profilePicture, doctorSignature, onImageSet, isLoadingPhoto} = props;

  return (
    <div style={{marginTop: "15px"}}>
      <h2 style={{fontSize: "24px", color: "#094063"}}>Extra Information</h2>
      <p style={{fontStyle: "italic", fontSize: "10px", color: "#aaa"}}><strong>NOTE:</strong>Fields marked (<Required/>)
        are required.</p>
      <div style={{marginTop: "10px"}}>

        <Field component="input" type="hidden" name="profile_picture"/>
        <Field component="input" type="hidden" name="profile_picture_filename"/>
        <Field component="input" type="hidden" name="signature"/>
        <Field component="input" type="hidden" name="signature_filename"/>

        {
          isLoadingPhoto.profilePicture ? (
            <div style={{width: "250px", height: "250px"}}>
              <LoadingScreen/>
            </div>
          ) : (
            <span>
                Please add a recent Passport Photograph <Required/>
                <ImageInput
                  width={250}
                  height={250}
                  onCropComplete={(img) => onImageSet(img, 'profile_picture')}
                  customClassName="sign__form__file-input"
                  placeholder={profilePicture}/>
            </span>
          )
        }

        {
          isLoadingPhoto.doctorSignature ? (
            <div style={{width: "250px", height: "250px"}}>
              <LoadingScreen/>
            </div>
          ) : (
            <span>
                Please add an image of your signature <Required/>
                <ImageInput
                  width={250}
                  height={250}
                  onCropComplete={(img) => onImageSet(img, 'signature')}
                  customClassName="sign__form__file-input"
                  placeholder={doctorSignature}/>
            </span>
          )
        }

        <Field component="input" type="hidden" name="hash"/>
        <Field component="input" type="hidden" name="access_token"/>
      </div>
    </div>
  )
};

const PageIndicator = (props) => {
  return (
    <div style={{
      backgroundColor: props.isActive ? "#094063" : "#e6e5e5",
      height: "5px",
      borderRadius: "3px",
      width: "100%",
      margin: "4px"
    }}>

    </div>
  )
};


DoctorSignUpForm = reduxForm({
  form: 'doctorSignUpForm',
  destroyOnUnmount: false,
})(DoctorSignUpForm);

// Decorate with connect to read form values
const selector = formValueSelector('doctorSignUpForm');
DoctorSignUpForm = connect(state => {
  const dateOfBirth = selector(state, 'date_of_birth');
  const profilePicture = selector(state, 'profile_picture');
  const doctorSignature = selector(state, 'signature');

  return {
    dateOfBirth,
    profilePicture,
    doctorSignature
  }
})(DoctorSignUpForm);

export default DoctorSignUpForm;