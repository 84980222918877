/**
 * Created by the.last.mayaki on 23/09/2017.
 */
import {TIP_ACTION_TYPES} from './actionTypes';
import kangpeService from '../services/kangpeService';
import * as _ from 'lodash';

//////////////////////// ACTIONS ///////////////////////////////////

export const getLatestTips = () => {
  return {
    type: TIP_ACTION_TYPES.GET_TIPS,
  }
};

export const getTip = (id, accessToken) => {
  return {
    type: TIP_ACTION_TYPES.GET_TIP,
    data: {id: id, accessToken: accessToken},
  }
};

export const invalidateStoredTips = (invalidate) => {
  return {
    type: TIP_ACTION_TYPES.INVALIDATE,
    data: invalidate
  }
};

export const likeTip = (id, accessToken) => {
  return {
    type: TIP_ACTION_TYPES.LIKE_TIP,
    data: {id: id, accessToken: accessToken},
  }
};

export const receiveTips = (tips) => {
  return {
    type: TIP_ACTION_TYPES.GET_TIPS_SUCCESS,
    data: tips,
    receivedAt: Date.now()
  }
};

export const getTipsFailed = (error) => {
  return {
    type: TIP_ACTION_TYPES.GET_TIPS_FAIL,
    data: {...error}
  }
};

export const receiveTip = (tip) => {
  return {
    type: TIP_ACTION_TYPES.GET_TIP_SUCCESS,
    data: tip
  }
};


//////////////////////// THUNKS //////////////////////////////////


export const fetchTipsIfNeeded = (accessToken, page = 1, limit = 15) => {
  return async (dispatch, getState) => {
    const state = getState();
    //if not currently fetching, and tips are invalid, pull
    if (!state.tips.isFetching && state.tips.didInvalidate) {
      try {
        dispatch(getLatestTips());
        const json = await kangpeService.getTips(accessToken, page, limit);
        const tips = json.map(tip => filterTipResponse(tip));
        dispatch(receiveTips(_.keyBy(tips, 'id')));
      } catch (err) {
        const {status, data, statusText} = err.response || {status: -1, statusText: "Unknown Error", data: {}};
        dispatch(getTipsFailed({code: status, message: data.message || statusText}));
      }
    } else {
      //else check if store currently has tips
      //if yes load, else, invalidate tip store
      //and fetch tips
      if (_.isEmpty(state.tips.itemsById)) {
        dispatch(invalidateStoredTips(true));
        dispatch(fetchTipsIfNeeded(accessToken, page, limit));
      } else {
        dispatch(getLatestTips());
        dispatch(receiveTips(state.tips.itemsById));
        dispatch(fetchTips(accessToken, page, limit));
      }
    }
  }
};


export const fetchTips = (accessToken, page = 1, limit = 15) => {
  return async (dispatch) => {
    try {
      dispatch(getLatestTips());
      const json = await kangpeService.getTips(accessToken, page, limit);
      const tips = json.map(tip => filterTipResponse(tip));
      dispatch(receiveTips(_.keyBy(tips, 'id')));
    } catch (err) {
      const {status, data, statusText} = err.response || {status: -1, statusText: "Unknown Error", data: {}};
      dispatch(getTipsFailed({code: status, message: data.message || statusText}));
    }
  }
};

export const fetchTipIfNeeded = (id, accessToken) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      dispatch(getTip(id, accessToken));
      if (state.tips.itemsById[id]) {
        dispatch(receiveTip(state.tips.itemsById[id]));
      } else {
        const json = await kangpeService.getTipById(id, accessToken);
        dispatch(receiveTip(filterTipResponse(json)));
      }
    } catch (err) {
      const {status, data, statusText} = err.response || {status: -1, statusText: "Unknown Error", data: {}};
      dispatch(getTipsFailed({code: status, message: data.message || statusText}));
    }
  }
};


//////////////////////// UTILS ///////////////////////////////////

function filterTipResponse(tip) {
  return {
    id: tip.tip_id,
    title: tip.tip_title,
    content: tip.tip_content,
    summary: tip.tip_content.substr(0, 150),
    imageUrl: tip.tip_image_url,
    noOfViews: tip.tip_no_of_views,
    createdAt: tip.created_at,
    author: tip.tip_author,
    altTitle: tip.tip_title.trim().replace(/\s/g, '-')
  }
}