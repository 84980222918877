import React, {useState, useRef, useEffect} from 'react';
import userService from '../../services/userService';

export default function ReferWithdrawCashForm({onSubmit, className, ...props}) {
  const [ banks, setBanks ] = useState([]);
  const [ message, setMessage ] = useState("");
  const [ isVerified, setIsVerified ] = useState(false);
  const [ statusColor, setStatusColor ] = useState("000");
  const withdrawCashForm = useRef(null);


  useEffect(() => {
    userService.getBanks()
      .then(banks => setBanks(banks));
  }, []);

  const verifyAccountNo = () => {
    const accountNo = withdrawCashForm.current.account_no.value;
    const bankId = parseInt(withdrawCashForm.current.bank.value);
    if (accountNo.length < 10 || isNaN(bankId)) {
      return;
    }
    setIsVerified(false)
    setStatusColor("000");
    setMessage("Verifying Account Number");
    userService.verifyAccountNumber(bankId, accountNo)
      .then(account => {
        setIsVerified(true);
        setStatusColor("107BC0");
        setMessage(account.account_name);
      })
      .catch(e => {
        setStatusColor("AB0404");
        setMessage("This account doesnt exist. Please check that the account number and bank name are a correct match and try again");
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      return;
    }
    const amount = parseInt(e.target.amount.value);
    const bank = parseInt(e.target.bank.value);
    const accountName = message;
    const accountNo = e.target.account_no.value;
    onSubmit(amount, bank, accountName, accountNo);
  }

  return (
    <form onSubmit={handleSubmit} ref={withdrawCashForm} className={`form ${className}`} {...props}>
    <div className="form-group">
      <div>
        <p className="dashboard-card__body-text">How much do want to withdraw?</p>
        <input 
          name="amount" 
          className="dashboard-form__input--full-width" 
          placeholder="10,000" 
          type="number" 
          min="0" 
          required 
        />
      </div>
    </div>
      <div className="form-group">
        <div>
          <p className="dashboard-card__body-text">Select a bank</p>
          <select
            name="bank"
            className="dashboard-form__input dashboard-form__select"
            onChange={verifyAccountNo}
            required
          >
            <option value="">Select a bank</option>
            {banks.length && banks.map(bank => (
              <option key={bank.id} value={bank.id}>{bank.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group">
        <div>
          <p className="dashboard-card__body-text">Account number</p>
          <input 
            pattern="^[0-9]{10}$" 
            name="account_no" 
            className="dashboard-form__input--full-width" 
            placeholder="0123456789" 
            type="text" 
            maxLength="10"
            onChange={verifyAccountNo}
            required
          />
          <span style={{"color": `#${statusColor}`}} className="input-message">{message}</span>
        </div> 
      </div>
      <button type="submit" className="dashboard-refer__primary-btn">Continue</button>
    </form>
  );
}