import React, { useEffect } from 'react';
import MainContent from './boxes/MainContent';
import PageBody from './boxes/PageBody';
import PageHeader from './boxes/PageHeader';  
import LoadingScreen from './LoadingScreen';
import numberFormatter from 'number-formatter';
import ReferWithdrawCashForm from './forms/ReferWithdrawCashForm';
import NotificationBanner from './boxes/NotificationBanner';

export default function ReferWithdrawCashPage({user, overview, getOverview, withdrawCash, error, dismissError}) {
  useEffect(() => {
    getOverview();
  }, [getOverview]);

  return (
    <MainContent>
      <PageHeader title="Withdraw cash" />
      {error && 
        <NotificationBanner
          type="error" message={error} title="Error!"
          onDismiss={dismissError}
        />
      }
      <PageBody>
        {
          overview ?
            <div className="dashboard-card">
              <div className="refer-dashboard-section--fixed-width">
                <div className="dashboard-card refer-dashboard-overview-card--full-width">
                  <h2 className="dashboard-card__title-text--light">Current balance</h2>
                  <h1 className="dashboard-card__focus-text">&#8358;{numberFormatter("#,###.00", overview.total_balance)}</h1>
                </div>
              </div>
              <div className="refer-dashboard-section--fixed-width">
                <ReferWithdrawCashForm onSubmit={withdrawCash} />
              </div>
            </div>
          :
            <LoadingScreen />
        }
      </PageBody>
    </MainContent>
  );
}