/**
 * Created by the.last.mayaki on 31/10/2017.
 */
import { PROVIDER_ACTION_TYPES } from './actionTypes';
import providerService from '../services/providerService';

//////////////////////// ACTIONS //////////////////////////////

export const getProviders = () => {
    return {
        type: PROVIDER_ACTION_TYPES.GET_PROVIDERS
    }
};

export const getProvidersSuccessful = (providers) => {
    return {
        type: PROVIDER_ACTION_TYPES.GET_PROVIDERS_SUCCESS,
        data: providers
    }
};

export const getProvidersFailed = (error) => {
    return {
        type: PROVIDER_ACTION_TYPES.GET_PROVIDERS_FAIL,
        data: error
    }
};


//////////////////////// THUNKS //////////////////////////////

export const fetchProviders = (accessToken) => {
    return (dispatch) => {
        dispatch(getProviders());
        providerService.getProviders(accessToken)
            .then((providers)=>{
                dispatch(getProvidersSuccessful(providers))
            })
            .catch((err)=>{
                dispatch(getProvidersFailed(err));
            })
    }
};
