import React from 'react';
import {reduxForm, Field} from 'redux-form';
import {Link} from 'react-router-dom';
import LOGO from '../../img/logo.svg';

let LoginForm = (props) => {

    const {handleSubmit,pristine,submitting} = props;

    return (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="form_logo">
                    <Link to={'/'}><img src={LOGO} alt="Reliance HMO's Logo"/></Link>
                </div>
                <Field component="input" type="text" name="hmo_id" placeholder="Your HMO ID" required/>
                <Field component="input" type="password" name="password" placeholder="Your password" required/>
                <button type="submit" disabled={pristine||submitting}>Log in</button>
            </form>
        </div>
    )
};


LoginForm = reduxForm({
    form:'loginForm',
    destroyOnUnmount:false
})(LoginForm);

export default LoginForm