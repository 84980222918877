import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as tipActions from '../actions/tip';
import {getTipSelectors} from '../selectors/tip';
import * as _ from 'lodash';
import TipItem from '../components/TipItem';
import TipList from '../components/TipList';
import LoadingScreen from '../components/LoadingScreen';
import MainContent from '../components/boxes/MainContent';

class TipContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      canLoadMore: true,
      isLoadingMore: false,
      currentPage: 0
    }
  }

  loadMoreTips = () => {
    const {dispatch, user} = this.props;
    let cPage = this.state.currentPage;
    ++cPage;
    this.setState({
      currentPage:cPage,
    });
    dispatch(tipActions.fetchTips(user.credentials.accessToken, cPage, 6));

  };


  renderLoading() {
    return (
      <MainContent>
        <LoadingScreen/>
      </MainContent>
    )
  }

  renderTipItem(tip) {
    return (
      <TipItem
        key={tip.id}
        id={tip.id}
        title={tip.title}
        summary={tip.summary}
        imageUrl={tip.imageUrl}
        createdAt={tip.createdAt}
      />
    );
  }


  render() {
    const {isFetching, itemsById} = this.props;
    if (!isFetching && !itemsById) return (<h1>No Tips</h1>);
    return (
      <TipList
        tipsById={itemsById}
        tipsIdArray={_.keys(itemsById)}
        renderTipItem={this.renderTipItem}
        onLoadMore={this.loadMoreTips}
        isLoadingMore={isFetching}
      />
    );
  };
}

function mapStateToProps(state) {
  return getTipSelectors(state);
}

export default withRouter(connect(mapStateToProps)(TipContainer));

