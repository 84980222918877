import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import App from './App';
import { configureStore } from './store/configureStore';
import emptyStore from './store/emptyStore';
import register from './registerServiceWorker';
import { sessionService } from 'redux-react-session';

const store = configureStore(emptyStore);

sessionService.initSessionService(store,{driver:"LOCALSTORAGE"});

ReactDOM.render(
    <Provider  store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

register();
