import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';
import moment from 'moment';
import BubbleLeft from './boxes/BubbleLeft';
import BubbleRight from './boxes/BubbleRight';

class FeedbackMessages extends Component {
  componentDidMount(){
    this.scrollToBottom();
  }

  componentDidUpdate(){
    const { shouldScrollToBottom } = this.props;

    if(shouldScrollToBottom) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behaviour:'smooth' });
  };

  renderChatView = (message, index, arr, cb) => {
    if(index > 0){
        let m1 = moment(arr[index-1].created_at);
        let m2 = moment(message.created_at);
        let diff = m2.diff(m1,'days');
        if(diff >= 1){
            return cb(message,true)
        }else{
            return cb(message,false)
        }
    }else{
        return cb(message,true)
    }
  };

  renderChat = (message, renderDate, index) => {
    if (renderDate) {
      return (<div key={message.id}>
        {this.renderMessageDate(message.created_at)}
        {this.renderMessage(message, index)}
      </div>)
    } else {
      return this.renderMessage(message, index);
    }
  };

  renderMessageDate = (date) => {
    return (
      <div className="chat-item__date">
        <p>{`    ${moment(date).format("dddd, MMMM Do YYYY")}   `}</p>
      </div>
    )
  };

  renderMessage = (message) => {
    return (
      message.owner ?
      <BubbleRight key={message.id} message={message.content} createdAt={message.created_at} />
      :
      <BubbleLeft key={message.id} message={message.content} createdAt={message.created_at}/>
    )
  }

  render() {
    const { messages, isLoading, noOlderMessages } = this.props;

    const displayMessages = messages.map((message, index, arr) =>  this.renderChatView(message, index, arr, this.renderChat) );
    
    return (
      <React.Fragment>
        <div className="feedback_scroll_btn hide-btn">
          <i 
            className="fa fa-chevron-down" 
            aria-hidden="true"
            onClick={this.scrollToBottom}>
          </i>
        </div>
        <div style={{textAlign: "center"}}>
          
        {
          !noOlderMessages 
          ? 
          <button className="infinite-scroll-btn" onClick={this.props.loadMoreFeedbackMessages}>
            {
              isLoading
              ? 
              <PulseLoader color="#094063" size={7}/> 
              : 
              "Load older messages"
            }
          </button>
          :
          <div 
            style={{ 
              fontSize: "15px",
              lineHeight: "2.2rem", 
              fontStyle: "normal", 
              fontWeight: "300" 
            }}>You are up to date!
          </div>
        }
        </div>
        <div id="messages">
          {displayMessages}
        </div>
        <div visibility="hidden" ref={(el)=> this.messagesEnd = el}></div>
      </React.Fragment>
    )
  }
}

export default FeedbackMessages;